import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_TEMPLATES, FETCH_TEAM_TEMPLATE_USERS, FETCH_TEAM_REPORTS } from './actionTypes';

const initialState = {
    teamTemplates: {},
    teamTemplateUsers: {},
    teamReports: {},
};

const teamReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLATES:
            return {
                ...state,
                teamTemplates: action.payload
            }
        case FETCH_TEAM_TEMPLATE_USERS:
            return {
                ...state,
                teamTemplateUsers: action.payload
            }
        case FETCH_TEAM_REPORTS:
            return {
                ...state,
                teamReports: action.payload
            }
        default:
            return state;
    }
}

export default teamReportsReducer;
