import React, { useState } from 'react'
import { full_field_details, getFields } from '../FieldTypes';

export default function CustomFormSidebar(props) {

  const [currentSidebarItem, setCurrentSidebarItem] = useState("");

  return (
    <div>
      <div style={{ padding: 11, backgroundColor: "rgb(5 123 138)", width: "100%", fontWeight: "600", paddingLeft: 25, color: "#fff" }}>
        <div>Form Elements</div>
      </div>
      <div
        style={{ backgroundColor: "rgb(5 123 138)", height: window.innerHeight - 100, paddingBottom: 5, borderTop: "0.2px solid #f2f2f2" }}>
        <nav style={{ display: "block", height: "100%", overflowY: "scroll", width: "100%" }} className='my-scrollbar'>
          {
            Object.keys(full_field_details).map((item, index) => {
              return (
                <div style={{ cursor: "grab", borderBottom: "0.1px solid #f2f2f2", display: "flex", flexDirection: "row", color: "#fff" }} key={index}
                  onDragStart={(event) => props.on_drag_start(event, item)}
                  onDragOver={(event) => props.on_allow_drop(event)}
                  draggable
                >
                  <span onMouseEnter={() => setCurrentSidebarItem(item)} onMouseOut={() => setCurrentSidebarItem('')}
                    style={{ width: "20%", padding: 11, backgroundColor: currentSidebarItem == item ? '#fff' : "rgb(5 123 138)", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "0.2px solid #f2f2f2", color: currentSidebarItem == item ? 'rgb(5 123 138)' : "#fff", fontSize: currentSidebarItem == item ? "20px" : "16px" }}>
                    {getFields[item].icon}
                  </span>

                  <span onMouseEnter={() => setCurrentSidebarItem(item)} onMouseOut={() => setCurrentSidebarItem('')}
                    style={{ width: "80%", padding: 11, backgroundColor: currentSidebarItem == item ? '#fff' : "rgb(5 123 138)", color: currentSidebarItem == item ? 'rgb(5 123 138)' : "#fff", fontWeight: currentSidebarItem == item ? "bold" : "normal" }}>
                    {getFields[item].name}
                  </span>
                </div>
                // <div key={index}>
                //   {getFields[item].name}
                // </div>
              )
            })
          }

        </nav>
      </div>
    </div>
  )
}
