import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'

function Embeded_Code_Modal(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Embeded Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={props.handleClose}>
                    Copy
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default  Embeded_Code_Modal;