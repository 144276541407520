import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import FieldHandler from '../customfields/FieldHandler';
import UpdateCustom_Card_Modal from './UpdateCustom_Card_Modal';
import DeleteModal from '../../common/components/DeleteModal';
import { checkCardFieldStatus, sortPositionWise } from '../../../lib/utils';
import { connect } from 'react-redux';
import { setNotification } from '../../common/action';
import FromsUpdateModal from '../../forms/components/FromsUpdateModal';
import UpdateCustomFormTemplate from './UpdateCustomFormTemplate';


const styles = {
    form_details: { paddingTop: "3%", paddingBottom: "2%", height: "100%", width: "100%", backgroundSize: "cover", fontFamily: "serif" },
    next_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "center", padding: 10, cursor: "pointer" },
    card_details: { paddingTop: "3%", height: "100%", width: "100%", backgroundSize: "cover", fontFamily: "serif", borderBottomStyle: "dashed", borderBottomWidth: 1, display: "flex", justifyContent: "space-evenly" },
    card_btn: { position: "relative", float: "right", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" },
    submit_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "center", padding: 10, paddingInline: 20, cursor: "pointer" },
    previous_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "space-between", padding: 10, paddingInline: 20, cursor: "pointer" },
    drag_field: { display: "flex", alignItems: "center", justifyContent: "center", borderStyle: "dashed", borderRadius: 15, marginInline: "5%", paddingBlock: 30 }
}

function CardCustomForm(props) {

    const [currentCardEditIndex, setCurrentCardEditIndex] = useState(null)
    const [allFieldDetails, setAllFieldDetails] = useState(props.allFieldDetails)
    const [addCardStatus, setAddCardStatus] = useState(false)
    const [current_card_details, setCurrent_card_details] = useState({})
    const [current_card_index, setCurrent_card_index] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [formEditStatus, setFormEditStatus] = useState(false)
    const [showFormEditModal, setShowFormEditModal] = useState(false)

    useEffect(() => {
        setAllFieldDetails(props.allFieldDetails)
    }, [props.allFieldDetails])


    const updateCardDetails = (data, index) => {
        var details = { ...props.formDetails }
        details.cards[index] = data
        setAddCardStatus(false)
        props.updateFormDetails(details)
    }

    const deleteFields = (ID) => {
        allFieldDetails.map((item, index) => {
            if (item.card_no == ID) {
                props.removeElement(item.id)
            }
        })
    }

    const deleteAction = (index) => {
        var details = { ...props.formDetails }
        deleteFields(details.cards[index].id)
        details.cards.splice(index, 1)
        props.updateFormDetails(details)
        props.setNotification(true, "Card Deleted Successfully", 'danger')
    }

    return (
        <Row style={{ height: "100%", paddingTop: 20 }} >
            <Col md="2" onClick={() => { props.setCurrentEditField(null); setCurrentCardEditIndex(null) }}></Col>
            {props.showForm && props.formDetails.cards &&
                <Col className='my-scrollbar' md="8" style={{ height: "100%", overflowY: "scroll" }}
                    onDragOver={(event) => props.allowDrop(event)}
                >
                    <center>
                        <Card className='current-card' onMouseEnter={() => setFormEditStatus(true)} onMouseLeave={() => setFormEditStatus(false)} style={{ backgroundColor: "#fff", marginTop: "5%", borderRadius: 10, marginBottom: "3%" }}>

                            <div style={{ ...styles.card_details, backgroundImage: `url(${props.formDetails.image_header})`, }} title='Form Details'>
                                <div style={{ width: "85%" }}>
                                    <h4 style={{ marginInline: "5%", textAlign: "center", fontWeight: "bolder" }}>
                                        {props.formDetails.label && props.formDetails.label.toUpperCase()}
                                    </h4>
                                    <p style={{ textAlign: "center" }}>{props.formDetails.description && props.formDetails.description}</p>
                                </div>
                                {
                                    formEditStatus ?
                                        <div style={{ display: "flex", width: "10%", marginTop: -7, position: "absolute", right: -10 }}>
                                            <div className='card-custom-icons' onClick={() => { setShowFormEditModal(true); }}
                                                style={{ backgroundColor: "blue", marginRight: 7 }}  >
                                                <i className="fa fa-pencil" style={{ fontSize: 17, color: "#fff" }} aria-hidden="true">
                                                </i>
                                            </div>
                                        </div> : <></>
                                }
                            </div>
                            <div style={styles.next_btn}>
                                <div className='card-button' style={{ textAlign: "enter" }}> NEXT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i></div>
                            </div>
                        </Card>
                        {
                            sortPositionWise(props.formDetails.cards).length > 0 && sortPositionWise(props.formDetails.cards).map((element, i) => {
                                return (
                                    <Card key={i} onMouseEnter={() => setCurrentCardEditIndex(i)} onMouseLeave={() => setCurrentCardEditIndex(null)}
                                        className='current-card' style={{ backgroundColor: "#fff", borderRadius: 10, marginBottom: "3%", marginTop: "5%" }}>

                                        <div style={{ ...styles.card_details }} title='Card Details'>
                                            <div style={{ width: "85%" }}>
                                                <h5 style={{ marginInline: "5%", textAlign: "center" }}>{element.card_name && element.card_name.toUpperCase()}</h5>
                                                <p style={{ textAlign: "center" }}>{element.card_desc && element.card_desc}</p>
                                            </div>
                                            {
                                                currentCardEditIndex == i ?
                                                    <div style={{ display: "flex", width: "10%", marginTop: -7, position: "absolute", right: 10 }}>
                                                        <div className='card-custom-icons' onClick={() => { setCurrent_card_details(element); setAddCardStatus(true); setCurrent_card_index(i) }}
                                                            style={{ backgroundColor: "blue", marginRight: 7 }}  >
                                                            <i className="fa fa-pencil" style={{ fontSize: 17, color: "#fff" }} aria-hidden="true">
                                                            </i>
                                                        </div>
                                                        <div className='card-custom-icons' onClick={() => { setCurrent_card_index(i); setShowDeleteModal(true) }}>
                                                            <i className="fa fa-trash" style={{ fontSize: 17, color: "#fff" }} aria-hidden="true"></i>
                                                        </div>
                                                    </div> : <></>
                                            }
                                        </div>
                                        {(allFieldDetails.length == 0 || checkCardFieldStatus(allFieldDetails, element.id)) &&

                                            <div onDrop={(e) => { props.drop(e, 0, element.id); }} style={{ paddingBlock: "5%" }}>
                                                <div style={styles.drag_field}>
                                                    Drag Your Form Fields Here
                                                </div>
                                            </div>}
                                        {
                                            allFieldDetails.length > 0 &&
                                            <Row style={{ display: "flex", width: "100%", flexWrap: "wrap", paddingInline: "5%", paddingBlock: "2%", }}>
                                                {
                                                    allFieldDetails.map((item, index) => {
                                                        if (item.type !== 'OCR_VIEW' && item.card_no == element.id) {
                                                            return (
                                                                <Col md={item.style == '' ? 12 : parseInt(item.style)}
                                                                    onDoubleClick={() => props.editElement(item.type)}
                                                                    onDrop={(e) => props.drop(e, item.sequence, element.id)}
                                                                    style={{ cursor: "grab" }}
                                                                    key={index}>
                                                                    {
                                                                        props.currentEditField == index ?
                                                                            <div style={styles.card_btn}>

                                                                                <div onClick={() => props.editElement(item.type)} style={{ padding: 5, backgroundColor: "blue", marginLeft: 5, marginBottom: 10, borderRadius: 5 }}> <i className="fa fa-pencil" style={{ fontSize: 20, color: "#fff" }} aria-hidden="true"></i></div>

                                                                                <div onClick={() => props.removeElement(item.id)} style={{ padding: 5, backgroundColor: "red", marginLeft: 5, borderRadius: 5 }}><i className="fa fa-trash" style={{ fontSize: 20, color: "#fff" }} aria-hidden="true"></i></div>
                                                                            </div> : <></>
                                                                    }

                                                                    <div
                                                                        onDragStart={(e) => { props.dragStart(e, '', index); props.setCurrentDraggingElement(item) }}
                                                                        onDragEnter={(e) => props.dragEnter(e, index)}
                                                                        onDragOver={(event) => props.allowDrop(event)}
                                                                        // onDrop={(e) => props.drop(e, index)}
                                                                        draggable
                                                                        style={{
                                                                            textAlign: 'center', display: "flex",
                                                                            justifyContent: 'center',
                                                                            borderWidth: props.currentEditField == index ? 1 : 0, borderStyle: "solid", borderColor: "black", padding: 10, paddingLeft: 20
                                                                        }}
                                                                        onClick={() => { props.setCurrentEditField(index); }}
                                                                    >

                                                                        <FieldHandler data={item} type={item.type} editable={true} />

                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                    })}
                                            </Row>
                                        }
                                        {
                                            sortPositionWise(props.formDetails.cards).length - 1 == i ?
                                                <div style={styles.submit_btn}>
                                                    <div className='card-button' style={{ textAlign: "center" }}> SUBMIT </div>
                                                </div> :
                                                <div style={styles.previous_btn}>
                                                    <div className='card-button'><i className="fa fa-long-arrow-left" aria-hidden="true" style={{ marginRight: 7, fontSize: 17 }}></i> PREVIOUS</div>
                                                    <div style={{ width: 0.1, backgroundColor: "#fff" }}></div>
                                                    <div className='card-button' style={{ textAlign: "end" }}> NEXT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i></div>
                                                </div>
                                        }

                                    </Card>
                                )
                            })
                        }
                    </center>
                    <Card style={{ backgroundColor: "#40cbb4", color: "#fff", marginBottom: "5%", cursor: "pointer" }} onClick={() => { props.setAddCardStatus(true); }}>
                        <Card.Header><center>Add Card <i className="fa fa-plus" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i></center></Card.Header>
                    </Card>
                </Col>
            }
            <Col onClick={() => { props.setCurrentEditField(null); setCurrentCardEditIndex(null) }} >
            </Col>


            <DeleteModal modalText={"Delete Card ?"} showModal={showDeleteModal} handleClose={() => setShowDeleteModal(false)}
                deleteAction={(status) => { if (status !== undefined && status == true) { deleteAction(current_card_index); } }}
            />


            {
                addCardStatus &&
                <UpdateCustom_Card_Modal
                    addCardStatus={addCardStatus}
                    current_card_details={current_card_details}
                    setAddCardStatus={(data) => setAddCardStatus(data)}
                    addCard={(data) => { updateCardDetails(data, current_card_index); props.setNotification(true, "Card Updated Successfully", 'success') }} />
            }

            <UpdateCustomFormTemplate showModal={showFormEditModal} handleClose={() => { setShowFormEditModal(false) }} selectedData={props.formDetails} />


        </Row>
    )
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCustomForm);
