import React, { Component } from 'react'
import { Col, Form, Row } from 'react-bootstrap';

export default class Switch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            placeholder: "",
            required: false,
            borderShow: false
        }
    }
    render() {
        return (
            <Row style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <Col md="5" className='d-flex'>

                    <div className="d-flex flex-row" >
                        {
                            this.props.required &&
                            <span style={{ color: "red", fontSize: 17 }}>*</span>
                        }
                        <h6 style={{ fontWeight: "600", fontSize: 17 }}> {this.props.data.label} </h6>
                    </div>
                </Col>
                <Col md="1">
                    <Form.Check type="switch" disabled={this.props.editable} />
                </Col>
            </Row>
        )
    }
}
