import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { createWorkflowTransition, fetchWorkflowStateList, fetchWorkflowTransitionList, deleteWorkFlowTransition, fetchWorkflowDetails } from '../action'
import { idToWorkflowStateLabel } from '../../../lib/utils';
import WorkflowTransitionUpdateModal from './WorkflowTransitionUpdateModal';
import DeleteModal from '../../common/components/DeleteModal';
import { BUTTONS } from '../../../lib/constants';
import { setNotification } from '../../common/action';

class WorkflowTransition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpdateModal: false,
            selectedData: {},
            origin_state: "",
            destination_state: "",
            label: '',
            description: "",
            showDeleteModal: false,
            buttonType: BUTTONS[0].value,

        }
    }

    componentDidMount() {
        this.props.fetchWorkflowStateList(this.props.token.access, this.props.params.id);
        this.props.fetchWorkflowTransitionList(this.props.token.access, this.props.params.id);
        this.props.getWorkflowData(this.props.token.access, this.props.params.id);
    }

    deleteAction(event) {
        this.props.deleteTransition(this.props.token.access, event.id);
    }

    handleCreateAction = (event) => {
        event.preventDefault()
        let requestBody = {
            "label": this.state.label,
            "description": this.state.description,
            "origin_state": this.state.origin_state,
            "destination_state": this.state.destination_state,
            "properties": this.state.buttonType,
            "workflow": this.props.params.id
        }
        this.setState({
            label: '',
            description: '',
            origin_state: "",
            destination_state: ""
        })
        if (requestBody["label"].replace(/\s/g, '').length && requestBody["origin_state"] !== "" && requestBody["destination_state"] !== "") {
            this.props.createWorkflowTransition(this.props.token.access, requestBody, this.props.params.id);
        }
        else {
            this.props.setNotification(true,"Enter valid Label and select origin state and destination state", "danger")
        }

    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Row>
                            <Col md="8">
                                <h6 style={{ fontSize: 21 }}>Workflow: {this.props.workflowdetails.label}</h6>
                                <p>Dec: {this.props.workflowdetails.description}</p>
                            </Col>

                        </Row>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Origin State<span className='text-danger'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" required={true} size="sm" value={this.state.origin_state}
                                                onChange={event => { this.setState({ origin_state: event.target.value }); }}>
                                                <option>Choose</option>
                                                {this.props.workflowStateList.results.map((row, index) => {
                                                    return (
                                                        <option value={row.id} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Transition Label <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" value={this.state.label} placeholder="Enter Transition Label" required
                                                onChange={event => { this.setState({ label: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control size="sm" type="text" value={this.state.description} placeholder="Enter Description"
                                                onChange={event => { this.setState({ description: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Destination State<span className='text-danger'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" required={true} size="sm" value={this.state.destination_state}
                                                onChange={event => { this.setState({ destination_state: event.target.value }); }}>
                                                <option>Choose</option>
                                                {this.props.workflowStateList.results.map((row, index) => {
                                                    return (
                                                        <option value={row.id} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Button Color</Form.Label>
                                            <Form.Select aria-label="Default select example" required={true} size="sm" value={this.state.buttonType}
                                                onChange={event => { this.setState({ buttonType: event.target.value }); }}>
                                                {BUTTONS.map((ele, index) => {
                                                    return (
                                                        <option value={ele.value} key={index}>{ele.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col className='d-flex align-items-end'>
                                            <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>

                                <div className='mt-5 font-small'  >
                                    {
                                        this.props.workflowTransitionList && this.props.workflowTransitionList.results && this.props.workflowTransitionList.results.length >= 0 &&

                                        <DataTable
                                            totalItemsCount={this.props.workflowTransitionList.count}
                                            onPageChange={(pageNumber) => this.props.fetchWorkflowTransitionList(this.props.token.access, pageNumber)}
                                            headers={[`Origin State`, `Transition Name`, `Description`, `Destination State`, `Action`]}
                                            body={this.props.workflowTransitionList.results.map((row, index) => {
                                                return [
                                                    idToWorkflowStateLabel(this.props.workflowStateList, row.origin_state),
                                                    row.label,
                                                    row.description,
                                                    idToWorkflowStateLabel(this.props.workflowStateList, row.destination_state),
                                                    <Row>
                                                        <Col sm='6'>
                                                            <Button className='full-width' variant="outline-warning" onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} size='sm'>EDIT</Button>
                                                        </Col>
                                                        <Col sm='6'>
                                                            <Button onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} variant="outline-danger" size='sm'>DELETE</Button>
                                                        </Col>
                                                    </Row>
                                                ]
                                            })
                                            } />
                                    }
                                </div>
                                <DeleteModal modalText={"Delete Transition ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <WorkflowTransitionUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} workflowStateList={this.props.workflowStateList} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        workflowStateList: state.workflowReducer.workflowStateList,
        workflowTransitionList: state.workflowReducer.workflowTransitionList,
        token: state.usersReducer.token,
        workflowdetails: state.workflowReducer.workflowTransitionDetailList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteTransition: (accessToken, transitionId) => dispatch(deleteWorkFlowTransition(accessToken, transitionId)),
        fetchWorkflowTransitionList: (accessToken, workflowId, pageNumber) => dispatch(fetchWorkflowTransitionList(accessToken, workflowId, pageNumber)),
        fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
        createWorkflowTransition: (accessToken, requestBody, workflowId) => dispatch(createWorkflowTransition(accessToken, requestBody, workflowId)),
        getWorkflowData: (accessToken, Id) => dispatch(fetchWorkflowDetails(accessToken, Id)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTransition);
