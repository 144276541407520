import React, { Component } from 'react'
import "./StyleSheets/Navbar.css";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeAccesstoken, getUserProfile } from '../views/users/action';
import profileImage from '../Images/demoProfile.png';
import NameInitialPic from '../views/users/components/NameInitialPic';
import { fetchBillingUser } from '../views/Billing/action';

class ProfileImageDropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            target: null,
            ref: null,



        }
    }
    componentDidMount() {
        this.props.billingUser(this.props.token.access)
    }
    handleClick = (event) => {
        // setShow(!show);
        // setTarget(event.target);
        this.setState({ show: !this.state.show });
        this.setState({ target: event.target })
    };

    handleClose = () => {
        this.setState({ show: !this.state.show })
    }

    logout = () => {
        var body = {
            "refresh": this.props.token.refresh
        }
        this.props.removeAccesstoken(this.props.token.access, body);
        this.setState({ show: !this.state.show });
    };
    render() {
        return (
            <div ref={this.state.ref}>
                <div style={{ height: 40, width: 40 }}>
                    {
                        this.props.profileData.profile === null || this.props.profileData.profile === undefined ?
                            <div style={{ height: 40, width: 40 }} onClick={this.handleClick}><NameInitialPic name={this.props.profileData.name} fontSize={22} /></div>
                            :
                            <img src={this.props.profileData.profile === null || this.props.profileData.profile === undefined ? profileImage : this.props.profileData.profile} className="rounded-circle" style={{ height: 40, width: 40 }} onClick={this.handleClick} />
                    }
                    {/* <img src={props.profileData.profile === null || props.profileData.profile === undefined ? profileImage : props.profileData.profile} className="rounded-circle" style={{ height: 40, width: 40 }} onClick={handleClick} /> */}
                </div>
                <Overlay
                    show={this.state.show}
                    target={this.state.target}
                    placement="bottom"
                    container={this.state.ref}
                    containerPadding={0}
                >
                    <Popover id="popover-contained" style={{ cursor: "pointer" }}>
                        <Link className='text-none' to={'/profile'} onClick={this.handleClose}>
                            <div className='dropdownMenu' >Profile</div>
                        </Link>
                        <div className='divider'></div>
                        {this.props.billinguser &&



                            this.props.billinguser.active === "YES" ?
                            <Link to="/CompanyProfile" style={{ textDecoration: "none" }} onClick={this.handleClose}>
                                <div className='dropdownMenu' >Company Details</div>
                            </Link> :
                            //     <Link className='text-none' to={'/CompanyProfile'} onClick={this.handleClose}>
                            //     <div className='dropdownMenu' >Company Details</div>
                            // </Link>:
                            <Link to="/companyRegister" style={{ textDecoration: "none" }} onClick={this.handleClose}>
                                <div className='dropdownMenu' >Company Register</div>
                            </Link>



                        }
                        <div className='divider'></div>
                        <div className='dropdownMenu' onClick={this.logout} >
                            Logout
                        </div>
                    </Popover>
                </Overlay>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        profileData: state.usersReducer.profileData,
        billinguser: state.billingReducer.billinguser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserProfile: (token) => dispatch(getUserProfile(token)),
        removeAccesstoken: (token, body) => dispatch(removeAccesstoken(token, body)),
        billingUser: (accessToken) => dispatch(fetchBillingUser(accessToken))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImageDropdown);


