import React, { Component } from 'react'
import { Button, Form, Row } from 'react-bootstrap';

export default class My_Button extends Component {
    constructor(props) {
        super(props)

        this.state = {
            placeholder: "",
            label: "Button",
            required: false,
            borderShow: false
        }
    }
    render() {
        return (
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <span>
                    <Button style={{ fontWeight: "600", fontSize: 17, backgroundColor: "#337ab7", padding: 10, width: this.state.label.length * 15, textAlign: "center", borderRadius: 10, cursor: "pointer", minWidth: 100, color: "#fff" }}>{this.props.data.label}</Button>
                </span>
            </Row>
        )
    }
}
