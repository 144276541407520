export const BASE_URL = `https://gigaforms-backend.epsumlabs.com`;
// export const BASE_URL = `http://192.168.29.177:8003`;
// export const BASE_URL = `http://192.168.29.177:8000`
export const googleMapsApiKey = 'AIzaSyDjCX0hCACCUVwfOsI5uWVouJv7rJtGgn0';

export const REQUEST_METHOD = {
    GET: "GET",
    PUT: "PUT",
    POST: "POST",
    PATCH: "PATCH",
    DELETE: "DELETE"
}

export const RESPONSE_TYPE = {
    JSON: 'json',
    NULL: null,
    BLOB: 'blob'
}

export const RESPONSE_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500
}

export const DEFAULT_DATA = {
    count: 0,
    next: null,
    previous: null,
    results: []
}

export const LAYOUT_TYPE = {
    NULL: null,
    BASELAYOUT: `BASELAYOUT`
}

export const FIELD_VALUE_MAPPING = {
    TEXT: `text`,
    CHECKBOX: `checkbox`,
    RADIO: `radio`,
    NUMBER: `number`,
    TEXTAREA: `textarea`,
    DATE: `date`,
    DATETIME: `datetime`,
    FILE: `file`,
    IMAGE: `image`,
    DROPDOWN: `dropdown`,
    HIDDEN_FIELD: `hiddenField`,
    PASSWORD: `password`,
    EMAIL: `email`,
    SIGNATURE: `signature`,
    TABLE: `table`,
}

export const FIELDS = [
    { label: 'Text', value: FIELD_VALUE_MAPPING.TEXT },
    { label: 'Checkbox', value: FIELD_VALUE_MAPPING.CHECKBOX },
    { label: 'Radio Button', value: FIELD_VALUE_MAPPING.RADIO },
    { label: 'Number', value: FIELD_VALUE_MAPPING.NUMBER },
    { label: 'Textarea', value: FIELD_VALUE_MAPPING.TEXTAREA },
    { label: 'Date', value: FIELD_VALUE_MAPPING.DATE },
    { label: 'Date Time', value: FIELD_VALUE_MAPPING.DATETIME },
    { label: 'File', value: FIELD_VALUE_MAPPING.FILE },
    { label: 'Image', value: FIELD_VALUE_MAPPING.IMAGE },
    { label: 'Dropdown', value: FIELD_VALUE_MAPPING.DROPDOWN },
    { label: 'Hidden Field', value: FIELD_VALUE_MAPPING.HIDDEN_FIELD },
    { label: 'Password', value: FIELD_VALUE_MAPPING.PASSWORD },
    { label: 'Email', value: FIELD_VALUE_MAPPING.EMAIL },
    { label: 'Signature', value: FIELD_VALUE_MAPPING.SIGNATURE },
    { label: 'Table', value: FIELD_VALUE_MAPPING.TABLE },
]

export const UNOIQUE_TYPE = [
    { label: 'All', value: 1 },
    { label: 'Daily', value: 2 },
    { label: 'User', value: 3 },
    { label: 'User Daily', value: 4 }
]

export const VALIDATOR_VALUE_MAPPING = {
    ALPHABET: `alphabet`,
    NUMBER: `number`,
    DECIMAL_NUMBER: `decimal_number`,
    ALPHANUMERIC: `alphanumeric`,
    EMAIL: `email`,
    PAST_DATE: `past_date`,
    TODAY: `today`,
    FUTURE_DATE: `future_date`,
    PERCENTAGE: `percentage`,
}

export const VALIDATORS = [
    { label: 'Alphabet', value: VALIDATOR_VALUE_MAPPING.ALPHABET },
    { label: 'Number', value: VALIDATOR_VALUE_MAPPING.NUMBER },
    { label: 'Decimal Number', value: VALIDATOR_VALUE_MAPPING.DECIMAL_NUMBER },
    { label: 'Alphanumeric', value: VALIDATOR_VALUE_MAPPING.ALPHANUMERIC },
    { label: 'Percentage', value: VALIDATOR_VALUE_MAPPING.PERCENTAGE },
    { label: 'Email', value: VALIDATOR_VALUE_MAPPING.EMAIL },
    { label: 'Past Date', value: VALIDATOR_VALUE_MAPPING.PAST_DATE },
    { label: 'Today', value: VALIDATOR_VALUE_MAPPING.TODAY },
    { label: 'Future Date', value: VALIDATOR_VALUE_MAPPING.FUTURE_DATE },
]

export const BUTTONS = [
    { label: 'Blue', value: "btn btn-primary" },
    { label: 'Gray', value: "btn btn-secondary" },
    { label: 'Green', value: "btn btn-success" },
    { label: 'Red', value: "btn btn-danger" },
    { label: 'Yellow', value: "btn btn-warning" },
    { label: 'Cyan', value: "btn btn-info" },
    { label: 'White', value: "btn btn-light" },
    { label: 'Black', value: " btn btn-dark" },
    { label: 'Link', value: "btn btn-link" },
]
