import React, { Component } from 'react';
import { Button, Card, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

export default class Signature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SignatureModal: false,
      sigCanvas: React.createRef(),
      signatureData: this.props.value,
      signatureImage: ""
    };
  }

  handleSave = () => {
    const dataUrl = this.state.sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    // Save the dataUrl as needed
    this.setState({ signatureData: dataUrl, SignatureModal: false });
    this.props.onChange(dataUrl);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.value !== this.props.value) {
      this.setState({ signatureData: this.props.value })
    }
  }

  render() {
    return (
      <>
        <Modal show={this.state.SignatureModal} onHide={() => this.setState({ SignatureModal: false })} backdrop="static" keyboard={false} centered={true}  >
          <Modal.Header closeButton>
            <Modal.Title >{this.props.modalText}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "280px" }} >
            <div style={{ height: "40px", backgroundColor: "transparent", border: "1px solid grey" }}>
              <Tabs
                defaultActiveKey="home"
                id="justify-tab-example"
                className="mb-3"
                justify
                color="black"
                variant="pills"
              >
                <Tab eventKey="home" title="Draw" style={{ color: "white" }}>
                  <>
                    <div style={{ border: "1px black solid", float: "left", height: 150 }} >
                      <SignatureCanvas ref={this.state.sigCanvas} penColor='black' canvasProps={{ width: 400, height: 150 }} />
                    </div>

                    <br />
                    <Button variant='primary' size='md' style={{ margin: "8px", marginLeft: 0 }} onClick={this.handleSave}>Save </Button>
                  </>
                </Tab>
                <Tab eventKey="profile" title="Default ">
                  <p>{this.props.data.user.name}</p>
                  <Button variant="primary" style={{ marginTop: "10px" }} onClick={() => { this.setState({ signatureData: this.props.data.user.name, SignatureModal: false }); this.props.onChange(this.props.data.user.name) }}>Save</Button>
                </Tab>
                <Tab eventKey="contact" title="Upload" >
                  <div style={{ marginTop: "10px" }}>
                    <Form.Control type="file" onChange={event => { this.setState({ signatureImage: URL.createObjectURL(event.target.files[0]) }) }} />
                  </div>
                  <Button variant="primary" style={{ marginTop: "10px" }} onClick={() => { this.setState({ SignatureModal: false }); this.props.onChange(this.state.signatureImage) }}>Save</Button>
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
        {(this.state.signatureData == ""||this.state.signatureData == undefined )?
          <Card>
            <Card.Header onClick={() => { !this.props.disabled && this.setState({ SignatureModal: true }); }} style={{ textAlign: "center" }}>Set Your Signature</Card.Header>
          </Card>
          :
          (this.state.signatureData.includes('base64') || this.state.signatureData.includes('http')) ?
            <div>
               <h6 style={{ fontWeight: "600", fontSize: 17 }}>Signature </h6>
              <img src={this.state.signatureData} style={{ height: 117, width: "42%", backgroundSize: "cover" }} />
              {
                this.props.disabled == false &&
                <Button onClick={() => this.setState({ SignatureModal: true })} type='default' variant="primary" size='md' className='ms-2' >Edit</Button>
              }
            </div>
            :
            <div style={{ border: "1px dashed black", padding: 10 }}>
               <h6 style={{ fontWeight: "600", fontSize: 17 }}>Signature </h6>
              <h5>{this.props.data.user.name}</h5>
              {
                this.props.disabled == false &&
                <Button onClick={() => this.setState({ SignatureModal: true })} type='default' variant="primary" size='md' className='ms-2' >Edit</Button>
              }
            </div>
        }

      </>
    );
  }
}
