import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { updateWorkflowState } from '../action';
import { setNotification } from '../../common/action';

class WorkflowStateUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            description: '',
            completion: '',
            initial: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState({
                label: this.props.selectedData.label,
                description: this.props.selectedData.description,
                completion: this.props.selectedData.completion,
                initial: this.props.selectedData.is_initial
            })
        }
    }

    handleUpdateAction(event) {
        event.preventDefault()
        let requestBody = {
            "label": this.state.label,
            "description": this.state.description,
            "completion": this.state.completion,
            "is_initial": this.state.initial
        }
        this.props.handleClose();
        if (requestBody["label"].replace(/\s/g, '').length) {
            this.props.editWorkflowState(this.props.token.access, this.props.selectedData.id, requestBody);
        }
        else {
            this.props.setNotification(true, "Please Provide Proper Name And Description", "danger")
        }
       
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update Workflow State</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form onSubmit={(e) => { this.handleUpdateAction(e) }} className='font-medium'>
                        <Form.Group className="mb-3">
                            <Form.Label>State Name <span className='text-danger'>*</span></Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter State Name" required
                                onChange={event => { this.setState({ label: event.target.value }); }}
                                value={this.state.label} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Description"
                                onChange={event => { this.setState({ description: event.target.value }); }}
                                value={this.state.description} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Completion</Form.Label>
                            <Form.Control size='sm' type="number" placeholder="Enter Completion"
                                onChange={event => { this.setState({ completion: event.target.value }); }}
                                value={this.state.completion} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Is Initial</Form.Label>
                            <Form.Check checked={this.state.initial} type="switch" label="Is Initial State?"
                                onChange={() => this.setState({ initial: !this.state.initial })} />
                        </Form.Group>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm' >UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editWorkflowState: (accessToken, pk, requestBody) => dispatch(updateWorkflowState(accessToken, pk, requestBody)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowStateUpdateModal);
