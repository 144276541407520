import { REQUEST_METHOD } from "../../lib/constants"
import { fetchApi } from "../../lib/utils"
import { LOADING_START, LOADING_END, SET_NOTIFICATION, FETCH_DASHBOARD, USER_LIST } from "./actionTypes"

export function updateLoader(status) {
    return function (dispatch) {
        if (status) {
            dispatch({ type: LOADING_START })
        } else {
            dispatch({ type: LOADING_END })
        }
    }
}

export function setNotification(show, text, color) {
    return function (dispatch) {
        dispatch({ type: SET_NOTIFICATION, payload: { show: show, text: text, color: color } })
    }
}

export function copyTextToClipboard(copyText, message = 'Coppied') {
    return function (dispatch) {
        navigator.clipboard.writeText(copyText);
        dispatch(setNotification(true, message, 'success'));
    }
}

export function fetchDashboard(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/dashboard/`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_DASHBOARD, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function fetchUserList(accessToken, pageNo = 1, search = '') {
    var my_search = ''
    if (search !== '') {
        my_search = `search=${search}&`
    }
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/all/?${my_search}page=${pageNo}`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: USER_LIST, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}
export function fetchContact(token, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/feature/share/${token}/store/`,
            null,
            REQUEST_METHOD.POST,
            (successResponse) => {
                alert("Your Enquiry was submitted Susessfully")
                dispatch({ type: LOADING_END })
            },

            (failureResponse) => {
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}