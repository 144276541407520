import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import SearchableDropdown from '../../common/components/SearchableDropdown';
import { AddWorkflow, fetchWorkFlowTemplate, removeWorkFlowTemplate, fetchForms } from '../action';
import { fetchWorkflowList, fetchWorkflowStateList, assignWorkflow, fetchAssignedState } from '../../workflows/action';
import { checkForUndoWorkflowTemplate, validateWorkflowList } from '../../../lib/utils';
import { fetchFormMembers } from '../action';
import { fetchUserList } from '../../common/action';

class AssignWorkflowModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            workflowId: this.props.workflowId,
            user: {},
            addStates: [],
            markedState: [],
        }
    }
    componentDidMount() {
        this.props.getUserList(this.props.token.access, 1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.workflowId !== this.props.workflowId && this.props.workflowId !== undefined) {
            this.props.getWorkflowStateList(this.props.token.access, this.props.workflowId);
        }
        if (prevState.user !== this.state.user) {
            this.props.getAssignedState(this.props.token.access, this.props.formId, this.state.user.id)
        }
        this.state.markedState = (this.selectedState(this.props.workflowStateList.results, this.props.assignedState.results));
    }

    handleAssignWorkflow = () => {
        let requestBody = {
            "user": this.state.user.id,
            "template": this.props.formId,
            "state_name": this.state.addStates
        }
        this.props.assignWorkflow(this.props.token.access, requestBody);
        this.state.addStates = [];
        this.state.markedState = [];
        this.props.handleClose();
    }

    selectedState = (stateList, assignedState) => {
        let res = [];
        for (let i = 0; i < stateList.length; i++) {
            for (let j = 0; j < assignedState.length; j++) {
                if (stateList[i].id === assignedState[j].state)
                    res.push(stateList[i].id);
            }
        }
        return res;
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Assign Workflow</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <SearchableDropdown
                        list={this.props.userList}
                        keyName='name'
                        onGetList={() => this.componentDidMount()}
                        defaultValue={this.state.user.name !== undefined ? this.state.user.name : ''}
                        onChange={(data) => { this.setState({ user: data }); this.props.getAssignedState(this.props.token.access, this.props.formId, data.id); }}
                        onSearchData={(data) => {
                            this.props.getUserList(this.props.token.access, 1, data);
                        }}
                    />
                    <div className='mt-2 pt-3s'>
                        <h6 className='mb-2 mt-3'>Select States</h6>
                        {
                            this.props.workflowStateList && this.props.workflowStateList.results && this.props.workflowStateList.results.length > 0 &&
                            this.props.workflowStateList.results.map((ele, idx) =>
                                <Form.Check
                                    defaultChecked={false}
                                    label={ele.label}
                                    onChange={(data) => {
                                        if (data.target.checked) {
                                            if (!this.state.addStates.includes(ele.id)) {
                                                this.state.addStates.push(ele.id)
                                            }
                                        }
                                        else if(!data.target.checked) {
                                            if (this.state.addStates.includes(ele.id)) {
                                                this.state.addStates.splice(this.state.addStates.indexOf(ele.id), 1)
                                            }
                                        }
                                    }}
                                />
                            )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleClose()} variant="warning" size='sm' >CANCEL</Button>
                    <Button variant='success' onClick={() => { this.handleAssignWorkflow() }} size='sm'>{'ADD'}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        formMembers: state.formsReducer.formMembers,
        userList: state.commonReducer.userList,
        workflowStateList: state.workflowReducer.workflowStateList,
        assignedState: state.workflowReducer.assignedState,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
        getUserList: (accessToken, pageNo, search) => dispatch((fetchUserList(accessToken, pageNo, search))),
        assignWorkflow: (accessToken, requestBody) => dispatch((assignWorkflow(accessToken, requestBody))),
        getAssignedState: (accessToken, templateId, userId) => dispatch((fetchAssignedState(accessToken, templateId, userId)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignWorkflowModal);
