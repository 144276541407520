import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { createWorkflow, fetchWorkflowList, deleteWorkFlow } from '../action'
import { formateDate } from '../../../lib/utils';
import WorkflowUpdateModal from './WorkflowUpdateModal';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';

class Workflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpdateModal: false,
            selectedData: {},
            label: '',
            description: '',
            search: ''
        }
    }

    componentDidMount() {
        this.props.fetchWorkflowList(this.props.token.access);
    }

    handleSearchAction() {
        this.props.fetchWorkflowList(this.props.token.access, `&search=${this.state.search}`);
    }

    handleCreateAction = (event) => {
        event.preventDefault()
        let requestBody = {
            "label": this.state.label,
            "description": this.state.description
        }
        this.setState({
            label: '',
            description: ''
        })
        if (requestBody["label"].replace(/\s/g, '').length) {
            this.props.addWorkflow(this.props.token.access, requestBody);
        }
        else {
            this.props.setNotification(true,"Please Provide Proper Name And Description", "danger")
        }

    }

    deleteAction(event) {
        this.props.removeWorkflow(this.props.token.access, event.id);
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <p>Workflow</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Workflow Label <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" value={this.state.label} placeholder="Enter Workflow Label" required
                                                onChange={event => { this.setState({ label: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Description" value={this.state.description}
                                                onChange={event => { this.setState({ description: event.target.value }); }} />
                                        </Col>
                                        <Col className='d-flex align-items-end'>
                                            <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className='mt-5 d-flex justify-content-end'>
                                    <Col md='3' >
                                        <Form.Control size="sm" type="text" placeholder="Seach" required value={this.state.search}
                                            onChange={(event) => { this.setState({ search: event.target.value }); }} />
                                    </Col>
                                    <Col md='1' className='d-flex align-items-end'>
                                        <Button onClick={() => this.handleSearchAction()} variant="success" size='sm'>Search</Button>
                                    </Col>
                                </Row>

                                <div className='mt-3 font-small'  >
                                    <DataTable
                                        totalItemsCount={this.props.workflowList.count}
                                        onPageChange={(pageNumber) => this.props.fetchWorkflowList(this.props.token.access, `&search=${this.state.search}`, pageNumber)}
                                        headers={[`Workflow Name`, `Description`, `Created On`, `Last Modified`, `Action`]}
                                        body={this.props.workflowList.results.map((row, index) => {
                                            return [
                                                row.label,
                                                row.description,
                                                formateDate(row.date_added),
                                                formateDate(row.date_updated),
                                                <Row>
                                                    <Col sm='3'>
                                                        <Button className='full-width' variant="outline-warning" onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} size='sm'>EDIT</Button>
                                                    </Col>
                                                    <Col sm='3'>
                                                        <a href={`/workflow/${row.id}/state`} >
                                                            <Button className='full-width' variant="outline-secondary" size='sm'>State</Button>
                                                        </a>
                                                    </Col>
                                                    <Col sm='3' >
                                                        <a href={`/workflow/${row.id}/transition`} >
                                                            <Button className='full-width' variant="outline-primary" size='sm'>Transition</Button>
                                                        </a>
                                                    </Col>  
                                                    <Col sm='3' >
                                                        <a href={`/workflow/${row.id}/view_data`} >
                                                            <Button className='full-width' variant="outline-success" size='sm'>VIEW</Button>
                                                        </a>
                                                    </Col>
                                                    <Col sm='3'>
                                                        <Button onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} variant="outline-danger" size='sm'>DELETE</Button>
                                                    </Col>
                                                </Row>
                                            ]
                                        })
                                        } />
                                </div>
                                <DeleteModal showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <WorkflowUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        workflowList: state.workflowReducer.workflowList,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeWorkflow: (accessToken, workflowId) => dispatch(deleteWorkFlow(accessToken, workflowId)),
        fetchWorkflowList: (accessToken, searchQuery, pageNumber) => dispatch(fetchWorkflowList(accessToken, searchQuery, pageNumber)),
        addWorkflow: (accessToken, requestBody) => dispatch(createWorkflow(accessToken, requestBody)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
