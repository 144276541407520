import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { updateProject, updateTeam } from '../action';
import { setNotification } from '../../common/action';
import { fetchAllFormsList } from '../../forms/action';


class TeamUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            template: ''
        }
    }

    componentDidMount(){
        this.props.getAllForms(this.props.token.access);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState({ name: this.props.selectedData.name })
            this.setState({template: this.props.selectedData.template})
        }
        
    }

    handleUpdateAction(event) {
        event.preventDefault()
        let requestBody = {
            "name": this.state.name,
            "template": this.state.template,
        }
        this.props.handleClose();
        if (requestBody["name"].replace(/\s/g, '').length) {
            this.props.editTeam(this.props.token.access, this.props.selectedData.id, requestBody);
        }
        else {
            this.props.setNotification(true, "Please enter a valid Team Name", "danger")
        }
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update Team</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form onSubmit={(e) => { this.handleUpdateAction(e) }} className='font-medium'>
                        <Form.Group className="mb-3">
                            <Form.Label>Team Name <span className='text-danger'>*</span></Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Team Name" required
                                onChange={event => { this.setState({ name: event.target.value }); }}
                                value={this.state.name} />
                                <br/>
                            <Form.Label>Select template </Form.Label>
                            <Form.Select size='sm' value={this.state.template} onChange={(event) => { this.setState({ template: event.target.value }) }} required>
                                <option>-Select  Template-</option>
                                {this.props.allFormList.data && this.props.allFormList.data.length > 0 && this.props.allFormList.data.map((row, index) => {
                                    return (
                                        <option value={row.id} key={index}>{row.label}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm' >UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        allFormList: state.formsReducer.allFormList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editTeam: (accessToken, id, requestBody) => dispatch(updateTeam(accessToken, id, requestBody)),
        getAllForms: (accesstoken) => dispatch(fetchAllFormsList(accesstoken)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamUpdateModal);
