import React, { Component } from 'react';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { connect } from 'react-redux';
import { fetchFilelist } from '../action';
import { formateDate, ShortenText } from '../../../lib/utils';
import FileTypeFieldHandler from '../../forms/components/fields/FileTypeFieldHandler';

class FileExplore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ''
        }
    }

    handleSearchAction() {
        this.props.getFileList(this.props.token.access, `&search=${this.state.search}`);
    }

    componentDidMount() {
        this.props.getFileList(this.props.token.access);
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <p>File Explore</p>
                        <Card>
                            <Row className='mt-5 d-flex justify-content-end'>
                                <Col md='3' >
                                    <Form.Control size="sm" type="text" placeholder="Search" required onChange={(event) => { this.setState({ search: event.target.value }); }} />
                                </Col>
                                <Col md='1' className='d-flex align-items-end'>
                                    <Button onClick={() => this.handleSearchAction()} variant="success" size='sm'>Search</Button>
                                </Col>
                            </Row>
                            <Card.Body className='font-medium'>
                                <div className='mt-3 font-medium'  >
                                    <div className='row container-fluid'>
                                        {this.props.fileList.results.map((item, index) => {
                                            return (
                                                <div className='col-md-2'>
                                                    <div style={{height:"50%",width:"50%"}}>
                                                    <FileTypeFieldHandler id={item.id} sharedToken={null} />
                                                    </div>
                                                    <p style={{marginTop:"10px"}}>{ShortenText(item.name)}</p>
                                                </div>
                                            )


                                        })}


                                    </div>
                                    {/* <DataTable
                                        totalItemsCount={this.props.fileList.count}
                                        headers={[`Photo`, `Type`, `Name`, `Added Date`]}
                                        onPageChange={(pageNumber) => this.props.getFileList(this.props.token.access,`&search=${this.state.search}`, pageNumber)}
                                        body={this.props.fileList.results.map((row, index) => {
                                            return [
                                                <FileTypeFieldHandler id={row.id} sharedToken={null} />,
                                                row.type,
                                                row.name,
                                                formateDate(row.date_added),
                                            ]
                                        })} /> */}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        fileList: state.fileExplorerReducer.filelist,
        token: state.usersReducer.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFileList: (accessToken, searchQuery, pageNumber) => dispatch(fetchFilelist(accessToken, searchQuery, pageNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileExplore);
