import React, { Component } from "react";
import { Link } from 'react-router-dom';
// import Logo from '../images/things-logo.png';
import Logo from '../../../Images/green.png';


export default class Footer extends Component {
    render() {
        return (
                <footer>
                    <div className="landing_footer" id="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <a className="brand-navbar" href="/green"><img src={Logo} alt="Responsive image" style={{height:'150px'}} /></a>
                                    {/* <img src={green} ></img> */}
                                        {/* <div className="container-fluid" style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                            <div className="col-md-3">
                                                <img src="https://www.epsumlabs.com/images/headerimg1.png" style={{height:'20px'}}></img>

                                            </div>
                                            <div className="col-md-3">
                                            <img src="https://www.epsumlabs.com/images/headerimg2.png" style={{height:'20px'}}></img>


                                            </div>
                                            <div className="col-md-3">
                                            <img src="https://www.epsumlabs.com/images/headerimg3.png" style={{height:'20px'}}></img>


                                            </div>
                                            <div className="col-md-3">
                                            <img src="https://www.epsumlabs.com/images/headerimg5.png" style={{height:'20px'}}></img>


                                            </div>

                                        </div> */}
                                        {/* <img src={green} ></img> */}
                                    
                                </div>
                                <div className="col-md-3">
                                <div ClassName ="footer-contents">
                                <h3>Reach Us</h3>
                                <ul ClassName ="footer-contacts">
                                    <li><span><a href="">+91 7377073774</a></span></li>
                                    <li><span><a href="">support@epsumlabs.com</a></span></li>
                                    <li><span><a href="https://www.epsumlabs.com/">www.epsumlabs.com</a></span></li>
                                </ul>
                            </div>
                                </div>
                                <div className="col-md-3 text-muted">
                                    <h3>Quick Link</h3>
                                    <ul>
                                        <li><Link to="/"> Home </Link></li>
                                        {/* <li><Link to="#"> Community </Link></li> */}
                                        <li><Link to="/ContactUs"> Contact Us </Link></li>
                                        {/* <li><Link to="#"> Developers </Link></li> */}
                                        {/* <li> <Link to="/terms&conditions"> Terms & Conditions </Link> </li> */}
                                        <li> <Link to="/PrivacyPolicy"> Privacy Policy </Link> </li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <h3>Resources</h3>
                                    <ul>
                                        <li><Link to="/userguide"> User Guide </Link></li>
                                        {/* <li> <Link to="/terms&conditions"> Terms & Conditions </Link> </li> */}
                                        <li> <Link to="/refundpolicy">Refund policy</Link> </li>
                                    </ul>
                                    <div className="social">

                                        <ul className="social">
                                            <li className="facebooks"> <a href="https://www.facebook.com/epsumlabs"> <i className="fa fa-facebook-f">   </i> </a> </li>
                                            <li className="twitters"> <a href="https://twitter.com/epsumlabs"> <i className="fa fa-twitter">   </i> </a> </li>
                                            <li className="instas"> <a href="https://www.instagram.com/epsumlabs"> <i className="fa fa-instagram"></i> </a> </li>
                                            <li className="linkedins"> <a href="https://www.linkedin.com/company/epsumlabs"> <i className="fa fa-linkedin"></i> </a> </li>
                                        </ul>
                                    </div>
                                    
                                    
                                </div>
                            </div>                                                                                                                                                                                                                                                     
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <h5 className="footer-para text-center" href='epsumlabs.com'>© 2021 <span className="logo-col" >E</span>psum <span className="logo-col">L</span>abs Pvt. Ltd. All Rights Reserved</h5>
                        </div>
                    </div>
                </footer>
        );
    }
}