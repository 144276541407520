import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_TOKEN, USER_LOCATION, USER_PROFILE } from './actionTypes';

const initialState = {
    token: {},
    profileData: {},
    userLocation: DEFAULT_DATA
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case USER_PROFILE:
            return {
                ...state,
                profileData: action.payload
            };
        case USER_LOCATION:
            return {
                ...state,
                userLocation: action.payload
            };
        default:
            return state;
    }
};

export default usersReducer;
