import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import { connect } from 'react-redux';
import { setNotification } from '../action';

class Toaster extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: this.props.notification.show ? this.props.notification.show : false,
            color: this.props.notification.color ? this.props.notification.color : 'light',
            text: this.props.notification.text ? this.props.notification.text : '',
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.notification.show !== prevProps.notification.show) {
            this.setState({
                show: this.props.notification.show,
                color: this.props.notification.color,
                text: this.props.notification.text
            })
        }
    }
    render() {
        return (
            <Row>
                <Col>
                <div style={{position: "fixed",
  right: "0",
  top: "0"}}>
                    <Toast onClose={() => this.props.setNotification(false, '', 'white')} show={this.state.show}
                        delay={3000} autohide
                        bg={this.state.color}
                    >
                        <Toast.Body className={this.state.color} style={{ color: "white" }} >{this.state.text}</Toast.Body>
                    </Toast>
                    </div>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        notification: state.commonReducer.notification
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);