import React, { Component } from 'react';
import { Nav, Navbar, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import logo from '../../../Images/final.png';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
export default class Navbr extends Component {
  state = {
    expanded: false,
    show: false,
    name: '',
    email: '',
    company: '',
    contactNo: '',
    subject: '',
    message: '',
    designation: '',
    partnerStatus: false
  };
  render() {
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" variant="danger" className='navbar sticky-top'>
          <Container className='navtext'>
            <div className='navimg'>
              <Navbar.Brand href="/"><img src={logo} style={{ height: "60px", width: "200px" }}></img></Navbar.Brand></div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" style={{ marginLeft: "30%" }}>
              <Nav className="me-auto navcol">
                <Button onClick={() => { this.setState({ show: true, partnerStatus: true }) }} style={{ color: "white", backgroundColor: "#fe914b", border: "none", borderRadius: "15px", width: "120px", height: "35px", fontSize: "8px", marginTop: "2px" }}><b style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: "4px"
                }}>BECOME A PARTNER</b></Button>
                <Nav.Link href="/Feature" style={{ color: "black", paddingRight: "20px" }}>Features</Nav.Link>
                <Nav.Link href="/Pricing" style={{ color: "black", paddingRight: "20px" }}>Plans & Pricing</Nav.Link>
                <div className="dropdown" style={{ paddingTop: "9px", paddingRight: "10px" }}>
                  <span>Resources<i className="fa fa-caret-down" aria-hidden="true"></i></span>
                  <div className="dropdown-content">
                    <a href='/faq'>FAQ</a>
                    <a href='/userguide'>User Guide</a>
                    <a href='/video'>Videos</a>
                  </div>
                </div>
                <Nav.Link href='/ContactUs' style={{ color: "black", paddingTop: "10px" }}>Contact Us</Nav.Link>
              </Nav>
              <Nav>
                <Button href='/register' style={{ color: "white", backgroundColor: "#fe914b", border: "none", borderRadius: "15px", width: "100px" }}>Signup</Button>{' '}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Modal show={this.state.show} onHide={() => { this.setState({ show: false }) }}>
                <Modal.Header closeButton style={{ backgroundColor: "orangered" }}>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                  aspectRatio: " 6/4",
                 
                }}>
                  <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name" value={this.state.name} required name="name" onChange={this.handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email" value={this.state.email} required name="email" onChange={this.handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="company">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your company name" value={this.state.company} required name="company" onChange={this.handleInputChange} />
                    </Form.Group>

                    <PhoneInput
                                                containerStyle={{ width: "190px",marginTop: "30px" }}
                                                inputStyle={{ width: "470px", height: "35px" }}


                                                country={"in"}
                                                enableSearch={true}
                                                value={this.state.phone}
                                                onChange={event => { this.setState({ phone: event }); }} style={{color: "black", marginTop: "30px"}}
                                            />
                    <Form.Group controlId="designation">
                      <Form.Label>Designation</Form.Label>
                      <Form.Control type="text" placeholder="Enter your designation" value={this.state.designation} required name="designation" onChange={this.handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="subject">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control  defaultValue={this.state.partnerStatus == true ? "" : "General Inquiry"} as="select" required name="subject" onChange={this.handleInputChange}>
                        <option value="">Oppurtunity for partnership</option>
                        <option value="General Inquiry">General Inquiry</option>
                        <option value="Sales Inquiry">Sales Inquiry</option>
                        
                      </Form.Control>
                     
                   
                    </Form.Group>
                   

                    <Form.Group controlId="message">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Enter your message" value={this.state.message} required name="message" onChange={this.handleInputChange} />
                    </Form.Group>



                    <Button variant="primary" type="submit" style={{ marginTop: "5px", backgroundColor: "orangered", border: "none" }}>
                      Submit
                    </Button>
                  </Form>
                </Modal.Body>
               
              </Modal>
      </div>
    )
  }
}
