import React, { useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import { googleMapsApiKey } from '../../../lib/constants';
import { connect } from 'react-redux';
import { validatePosition } from '../../../lib/utils';
import { useEffect } from 'react';

const containerStyle = {
    width: '100%',
    height: '600px'
};

const google = window.google

function MyComponent(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleMapsApiKey
    })

    const [MyMarkers, setMyMarkers] = React.useState(validatePosition(props.userLocation))

    const [map, setMap] = React.useState(null)
    const [activeMarker, setActiveMarker] = useState(null);
    const [zoom, setZoom] = React.useState(10)

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect((map) => {
        setMyMarkers(validatePosition(props.userLocation))
        setZoom(3)
    }, [props.userLocation.results.length])



    return isLoaded ? (
        <GoogleMap
            center={validatePosition(props.userLocation)[0]}
            mapContainerStyle={containerStyle}
            zoom={zoom}
            onUnmount={onUnmount}
        >
            <Polyline
                path={MyMarkers}
                geodesic={true}
                options={{
                    strokeColor: "#ff2527",
                    strokeOpacity: 0.75,
                    strokeWeight: 2,
                    icons: [
                        {
                            icon: {
                                path: google !== undefined ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW : window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            },
                            offset: "100%",
                            repeat: "20px"
                        }
                    ]
                }}
            />

            {MyMarkers.map((position, index) => {
                return (
                    <Marker
                        key={index}
                        position={position}
                        onClick={() => handleActiveMarker(index)}
                    >
                    </Marker>
                )
            }
            )}

        </GoogleMap>
    ) : <></>
}
const mapStateToProps = state => {
    return {
        userLocation: state.usersReducer.userLocation
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);

