import { Form } from "react-bootstrap"

export const widthTypes = {
    heading: ["6", "12"],
    text: ["6", "12"],
    textarea: ['6', "12"],
    number: ["6", "12"],
    checkbox: ["6", "12"],
    radio: ["6", "12"],
    switch: ["6", "12"],
    date: ["6", "12"],
    datetime: ["6", "12"],
    email: ["6", "12"],
    dropdown: ["6", "12"],
    button: ["6", "12"],
    image: ["6", "12"],
    file: ["6", "12"],
    password: ["6", "12"],
    table: ['12']
}

export const full_field_details = {
    // 'heading': {label:"Heading"},
    'text': { label: 'TextBox Title', placeholder: "", type: "text", defaultValue: "" },
    'textarea': { label: 'Textarea Title', placeholder: "", type: "textarea", defaultValue: "" },
    'number': { label: 'Number Title', placeholder: "", type: "number", defaultValue: "" },
    'checkbox': { label: 'Checkbox Title', placeholder: "", type: "checkbox", defaultValue: "" },
    'radio': { label: 'Radio Title', placeholder: "", type: "radio", defaultValue: "" },
    'date': { label: 'Date Title', placeholder: "", type: "date", defaultValue: "" },
    'datetime': { label: 'Datetime Title', placeholder: "", type: "datetime", defaultValue: "" },
    'email': { label: 'Email Title', placeholder: "", type: "email", defaultValue: "" },
    'dropdown': { label: 'Dropdown Title', placeholder: "", type: "dropdown", defaultValue: "" },
    'image': { label: 'Image Title', placeholder: "", type: "image", defaultValue: "" },
    'file': { label: 'Files Title', placeholder: "", type: "file", defaultValue: "" },
    'password': { label: 'Password Title', placeholder: "", type: "password", defaultValue: "" },
    'signature': { label: 'signature', placeholder: "", type: "signature", defaultValue: "" },
    'table': { label: 'table', placeholder: "", type: "table", defaultValue: "" }

}

export const getFieldTypes = (data) => {
    return data.map((item) => {
        return item.type
    })
}

export const getFields = {
    'text': { name: "Text Field", icon: <i className="fa fa-text-width" aria-hidden="true"></i> },
    'textarea': { name: "Textarea", icon: <i className="fa fa-text-height" aria-hidden="true"></i> },
    'number': { name: "Number", icon: <i className="fa fa-sort-numeric-asc" aria-hidden="true"></i> },
    'checkbox': { name: "CheckBox", icon: <i className="fa fa-check-square-o" aria-hidden="true"></i> },
    'radio': { name: "Radio Button", icon: <i className="fa fa-check-circle-o" aria-hidden="true"></i> },
    'date': { name: "Date", icon: <i className="fa fa-calendar" aria-hidden="true"></i> },
    'datetime': { name: "Date Time", icon: <i className="fa fa-clock-o" aria-hidden="true"></i> },
    'email': { name: "Email", icon: <i className="fa fa-envelope" aria-hidden="true"></i> },
    'dropdown': { name: "Dropdown", icon: <i className="fa fa-caret-square-o-down" aria-hidden="true"></i> },
    'image': { name: "Image", icon: <i className="fa fa-picture-o" aria-hidden="true"></i> },
    'file': { name: "File", icon: <i className="fa fa-file" aria-hidden="true"></i> },
    'password': { name: "Password", icon: <i className="fa fa-key" aria-hidden="true"></i> },
    'signature': { name: "Signature", icon: <i className="fa fa-american-sign-language-interpreting" aria-hidden="true"></i> },
    'table': { name: "Table", icon: <i class="fa fa-table" aria-hidden="true"></i> },
}

export const table_field_types = [
    { type: "text", text: "Text" },
    { type: "number", text: "Number" },
    { type: "date", text: "Date" },
    { type: "datetime", text: "Date Time" },
    { type: "radio", text: "Radio" },
    { type: "checkbox", text: "Checkbox" }
]


export const setBodyDataType = (defaultValue, type, disabled, onChange, rowIndex) => {
    switch (type) {
        case 'text':
            return <Form.Control defaultValue={defaultValue} disabled={disabled} style={{ border: "none", width: "90%", textAlign: "center", boxShadow: "none" }} type="text" onChange={(event) => onChange(event.target.value)} />
        case 'number':
            return <Form.Control defaultValue={defaultValue} disabled={disabled} style={{ border: "none", width: "90%", textAlign: "center", boxShadow: "none" }} type="number" onChange={(event) => onChange(event.target.value)} />
        case 'date':
            return <Form.Control defaultValue={defaultValue} disabled={disabled} style={{ border: "none", width: "90%", textAlign: "center", boxShadow: "none" }} type="date" onChange={(event) => onChange(event.target.value)} />
        case 'datetime':
            return <Form.Control defaultValue={defaultValue} disabled={disabled} style={{ border: "none", width: "90%", textAlign: "center", boxShadow: "none" }} type="date-time" onChange={(event) => onChange(event.target.value)} />

        case 'radio':
            return <Form.Check defaultChecked={defaultValue == ' ' ? false : defaultValue == 'false' ? false : defaultValue} name={`radio-${rowIndex}`} type="radio" disabled={disabled} style={{ border: "none", width: "90%", textAlign: "center", boxShadow: "none" }} onChange={(e) => onChange('yes')} />
        case 'checkbox':
            return <Form.Check defaultChecked={defaultValue == ' ' ? false : defaultValue == 'false' ? false : defaultValue} type="checkbox" disabled={disabled} style={{ border: "none", width: "90%", textAlign: "center", boxShadow: "none" }} onChange={(event) => onChange(JSON.stringify(event.target.checked))} />

        default:
            break;
    }
}
