import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

class OCRModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleDeleteAction() {
        this.props.deleteAction(false);
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title ><p style={{fontSize:"20px"}}>Are you sure want to Remove OCR ?</p></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <p>{this.props.modalText}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleClose()} variant="warning" size='sm' >CANCEL</Button>
                    <Button onClick={() => this.handleDeleteAction()} type='submit' variant="danger" size='sm' >CONFIRM</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

export default connect(mapStateToProps)(OCRModal);
