import React, { Component } from 'react'
import FormImage from '../../../Images/form_image.jpg';
import Formcontactus from '../../../Images/contact-us.png';
import PhoneInput from "react-phone-input-2";
import { Form } from 'react-bootstrap';
import Navbar from "./Navbr";
import Footer from "./lfooter";
import { fetchContact } from '../../common/action';
import "react-phone-input-2/lib/bootstrap.css";

export default class ContactUs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            email: "",
            number: "",
            subject: "",
            message: "",
            Product_Name: "GIGA-FORM",



        }
    }
    handleSubmitAction(e) {
        const { name, email, number, message, subject, Product_Name } = this.state
        e.preventDefault()
        let requestBody = {
            "data": [
                {
                    value: name, field: 136
                },
                {
                    value: email, field: 137
                },
                {
                    value: number, field: 138
                },
                {
                    value: subject, field: 139
                },

                {
                    value: message, field: 140
                },
                {
                    value: Product_Name, field: 141
                },
            ]
        }
        if (name !== "" && email !== "" && number !== "" && message !== "") {
            this.props.storeData('52341acc-4b9c-4031-8f82-b7e1ae8f40de', requestBody);
            this.setState({ name: "", email: "", number: "", subject: "", message: "", Product_Name: '' })
        }
        else {
            alert("Please Enter The Valid Value")
        }
    }
    render() {
        return (
            <div>
                <Navbar />
                
               

                <section  id="contact" className="contact">
                    <div className='container'>
                    <div className="row gy-4">
                    <div className="col-lg-6">
                                <img title="contactus" src={Formcontactus} alt="" />
                            </div>
                            <div className="col-lg-6">
                            <form action="forms/contact.php" method="post" className="php-email-form">
                                    <div className="row gy-4">
                                        <div className="col-md-12">
                                            <input type="text" name="name" className="form-control" style={{ padding: "10px 15px" }} placeholder="Your Name" required value={this.state.name} onChange={event => { this.setState({ name: event.target.value }); }} />
                                        </div>
                                        

                                        <div className="col-md-6 ">
                                            <input type="email" className="form-control" name="email" placeholder="Your Email" style={{ padding: "10px 15px" }} required value={this.state.email} onChange={event => { this.setState({ email: event.target.value }); }} />
                                        </div>
                                        <div className="col-md-6">
                                           
                                            <PhoneInput
                                                containerStyle={{ width: "190px" }}
                                                inputStyle={{ width: "280px", height: "44px" }}


                                                country={"in"}
                                                enableSearch={true}
                                                value={this.state.number}
                                                onChange={event => { this.setState({ number: event }) }}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            
                                            <Form.Group controlId="subject" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                
                                                <Form.Control defaultValue={this.state.partnerStatus == true ? "" : "Subject"} as="select" required name="subject" onChange={this.handleInputChange} style={{ borderRadius: "0", fontSize: "14px" }}>
                                                    <option value="">Subject</option>
                                                    <option value="">Oppurtunity for partnership</option>
                                                    <option value="General Inquiry">General Inquiry</option>
                                                    <option value="Sales Inquiry">Sales Inquiry</option>
                                                    <option value="Sales Inquiry">Technical Support</option>

                                                </Form.Control>
                                               


                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <textarea className="form-control" name="message" rows="6" placeholder="Message" required value={this.state.message} onChange={event => { this.setState({ message: event.target.value }); }}></textarea>

                                        </div>
                                        <div className="col-md-12 text-center">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                            <button type="submit" onClick={(e) => { this.handleSubmitAction(e) }}>Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    </div>

                    </div>
                </section>
                
                <Footer />
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        storeData: (token, requestBody) => dispatch(fetchContact(token, requestBody)),
    };
};



