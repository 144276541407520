import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Image } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchAllTeams, createTeam, deleteTeam } from '../action';
import { copyTextToClipboard } from '../../common/action';
import { formateDate } from '../../../lib/utils';
import DeleteModal from '../../common/components/DeleteModal';
import TeamUpdateModal from './TeamUpdateModal';
import { setNotification } from '../../common/action';
import { fetchAllFormsList } from '../../forms/action';
import { Link } from 'react-router-dom';


class Teams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            template: '',
            selectedData: {},
            showDeleteModal: false,
            showUpdateModal: false

        }
    }

    componentDidMount() {
        this.props.getTeams(this.props.token.access);
        this.props.getAllForms(this.props.token.access);
    }

    deleteAction(event) {
        this.props.deleteAction(this.props.token.access, event.id);
        this.setState({
            selectedData: {}
        })
    }

    handleCreateAction(event) {
        event.preventDefault()
        let requestBody = {
            name: this.state.name,
            template: this.state.template
        }
        this.props.createTeam(this.props.token.access, requestBody)
        this.setState({
            name: '',
            template: '',
        })
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid" >
                <Row>
                    <Col>
                        <p>Team Management</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                                    <Row>
                                        <Col className='m-4'>
                                            <Form.Label>Team Name <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Team Name" required value={this.state.name} onChange={event => { this.setState({ name: event.target.value }); }} />
                                        </Col>
                                        <Col className='m-4'>
                                            <Form.Label>Select template </Form.Label>
                                            <Form.Select size='sm' value={this.state.template} onChange={(event) => { this.setState({ template: event.target.value }) }} required>
                                                <option>-Select  Template-</option>
                                                {this.props.allFormList.data && this.props.allFormList.data.length > 0 && this.props.allFormList.data.map((row, index) => {
                                                    return (
                                                        <option value={row.id} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col className='m-5'>
                                            <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>

                                <div className='mt-3 font-medium'  >
                                    {
                                        this.props.teams.results && this.props.teams.results.length > 0 &&
                                        <DataTable
                                            totalItemsCount={this.props.teams.count}
                                            headers={[`Team Name`, `template`, `Action`]}
                                            onPageChange={(pageNumber) => this.props.getTeams(this.props.token.access, pageNumber)}
                                            body={this.props.teams.results.map((row, index) => {
                                                return [
                                                    row.name,
                                                    row.template,
                                                    <Row>
                                                        <Col mr='6'>
                                                            <Button className="m-1" variant="outline-warning" onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} size='sm'>EDIT</Button>

                                                            <Link to={`/teams/${row.id}/view_data`} >
                                                                <Button className="m-1" variant="outline-success" size='sm'>VIEW</Button>
                                                            </Link>

                                                            <Button className="m-1" variant="outline-danger" size='sm' onClick={() => this.setState({ showDeleteModal: true, selectedData: row })}>DELETE</Button>
                                                        </Col>
                                                    </Row>
                                                ]
                                            })}
                                        />
                                    }
                                </div>
                                <DeleteModal modalText={"Delete Team ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }}
                                />
                                <TeamUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        teams: state.teamReducer.teams,
        token: state.usersReducer.token,
        allFormList: state.formsReducer.allFormList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTeams: (accessToken, pageNumber) => dispatch(fetchAllTeams(accessToken, pageNumber)),
        getAllForms: (accesstoken) => dispatch(fetchAllFormsList(accesstoken)),
        createTeam: (accessToken, requestBody) => dispatch(createTeam(accessToken, requestBody)),
        deleteAction: (accessToken, teamId) => dispatch(deleteTeam(accessToken, teamId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
