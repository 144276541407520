import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, Button } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchOrganizationData} from '../action';

class OrganizationsDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.getOrganizationData(this.props.token.access, this.props.params.id);
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Row>
                            <Col md="8">
                                <h3>{this.props.organizationData.name}</h3>
                            </Col>
                            <Col md='4' className='d-flex justify-content-end align-items-center'>
                                    <Button className="m-1" variant="outline-primary" size='md'>ADD MEMBER</Button>
                            </Col>
                        </Row>
                        {/* <Card>
                            <Card.Body>
                                <DataTable
                                    totalItemsCount={this.props.formData.count}
                                    onPageChange={(pageNumber) => this.props.getFormData(this.props.token.access, this.props.params.id, pageNumber)}
                                    headers={getFieldLabelArray(this.props.fields.results)}
                                    body={
                                        this.props.formData.results.map((row, index) => {
                                            let data = arrangeFormData(this.props.fields.results, row.data);
                                            data.push(formateDate(row.date_updated))
                                            data.push(row.user === null ? 'Anonymous' : row.user.name)
                                            data.push(
                                                <Row>
                                                    <Col sm='4'>
                                                        <a href={`/forms/${row.template}/DataDetails/${row.id}`}>
                                                            <Button variant="outline-primary" size='sm'>VIEW</Button>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            )
                                            return data;
                                        })
                                    }
                                />
                            </Card.Body>
                        </Card> */}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        organizationData: state.organizationReducer.organizationData,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationData: (accessToken, organizationId) => dispatch(fetchOrganizationData(accessToken, organizationId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsDataView);
