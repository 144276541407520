import React, { Component } from 'react'

export default class TankyouPage extends Component {
  render() {
    return (
        <div>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title />
        <link href="https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700" rel="stylesheet" type="text/css" />
        <style dangerouslySetInnerHTML={{__html: "\n\t\t@import url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);\n\t\t@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);\n\t" }} />
        <link rel="stylesheet" href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css" />
        <header className="site-header" id="header">
          <h1 className="site-header__title" data-lead-id="site-header-title" style={{fontSize:"40px"}}>THANK YOU!</h1>
        </header>
        <div className="main-content">
          <i className="fa fa-check main-content__checkmark" id="checkmark" />
          <p className="main-content__body" data-lead-id="main-content-body">Thanks a bunch for filling that out. Your submission has been received and we are excited to learn more about how our products can meet your needs. Our team will be in touch with you soon.</p>
        </div>
        {/* <footer className="site-footer" id="footer">
          <p className="site-footer__fineprint" id="fineprint">Copyright ©2023 |G All Rights Reserved</p>
        </footer> */}
      </div>
    )
  }
}
