import React, { Component } from 'react'
import Navbar from "./Navbr";
import Footer from "./lfooter";
import VideoModal from './videoModal';
import { Button, Toast } from 'react-bootstrap';
import Custom from "../../../Images/customize.png";
import Table from "../../../Images/formtable.png";
import Digitalization from "../../../Images/digitalization.png";
import Analytic from "../../../Images/analytic.png";
import Service from "../../../Images/24service.png";
import Aos from 'aos';
import { connect } from 'react-redux';
import { PlanDetails } from '../../Billing/action';
class Pricing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showVideoModal: false,
            showNotification: false,
            monthly: true,
            yearly: false
        }
    }
    onNotification = () => {
        this.setState({ showNotification: true });
        setTimeout(() => {
            this.setState({ showNotification: false })

        }, 5000);


    }
    componentDidMount() {
        Aos.init();
        this.props.PlanDetails();

    }
    render() {
        console.log(this.props.plandetails)
        return (
            <div>

                <Navbar />
                {this.state.showNotification == true}{
                    <div style={{
                        position: "fixed",
                        right: "0",
                        top: "0",
                        zIndex: "100",
                        color: "white"
                    }}>
                        <Toast show={this.state.showNotification} onClose={!this.state.showNotification}
                            bg='danger'>
                            <Toast.Body>Before choosing any plan, you must have registered.</Toast.Body>
                        </Toast>
                    </div>
                }
                <section id="hero" className="d-flex align-items-center">

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                                <h1>Pricing Plan</h1>
                                <h2>Get the most out of Giga-forms with these first steps and pro tips .</h2>
                                <div>
                                    <a href="http://173.212.215.65/EForm/E-Form.html

" className="btn-get-started scrollto">Help Center</a>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img">
                                <img src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/vXAencgmve.png" className="img-fluid animated" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section className="pricing-section" style={{ marginTop: "-8em" }}>
                    <div className="container">
                        <div className="sec-title text-center">
                            <span className="title">Get plan</span>
                        </div>
                        <div>
                            <center> <div style={{ border: "0.5px solid grey", width: "17%", height: "50px", borderRadius: "20px", display: "flex", flexDirection: "row", justifyContent: "space-evenly", }}>
                                <Button variant={this.state.monthly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ monthly: true, yearly: false }) }}>Monthly</Button>
                                <Button variant={this.state.yearly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ yearly: true, monthly: false }) }}>Yearly</Button>
                            </div></center>
                        </div>
                        <br />
                        <div className="outer-box">
                            {this.props.plandetails && this.props.plandetails.results &&
                             <div className="row">

                             <div className="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                                 <div className="inner-box">
                                     <div className="icon-box">
                                         <div className="icon-outer"><i className="fa fa-paper-plane"></i></div>
                                     </div>
                                     <div className="price-box">
                                         <div className="title">{this.props.plandetails.results[0].name}</div>
                                         <del className="price">₹100</del>


                                     </div>
                                     <ul className="features">
                                         <li className="true">Forms-<b>{this.props.plandetails.results[0].form_count}</b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b>{this.props.plandetails.results[0].team_count}</b></li>
                                         <li className="false">Workflow-<b>{this.props.plandetails.results[0].workflow_count}</b></li>
                                         <li className="false">Space-<b>{this.props.plandetails.results[0].space_assign+"MB"}</b></li>
                                         <li className="false">Email-<b>{this.props.plandetails.results[0].email_count}</b></li>
                                         <li className="false">Custom Form-<b>{this.props.plandetails.results[0].custome_form_count}</b></li>
                                         <li className="false">E-signature-<b>{this.props.plandetails.results[0].e_signature_count}</b></li>
                                         <li className="false"> Submission-<b>{this.props.plandetails.results[0].monthly_submission}</b></li>

                                     </ul>
                                     <div className="btn-box">
                                         <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer" }} onClick={this.onNotification}>BUY plan</a>
                                     </div>
                                 </div>
                             </div>


                             <div className="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                 <div className="inner-box">
                                     <div className="icon-box">
                                         <div className="icon-outer"><i className="fa fa-bandcamp"></i></div>
                                     </div>
                                     <div className="price-box">
                                         <div className="title">{this.props.plandetails.results[1].name}</div>
                                         {this.state.monthly == true ? <h4 className="price">₹{this.props.plandetails.results[1].monthly_amount}</h4>
                                             : <h4 className="price">₹{this.props.plandetails.results[1].yearly_amount}</h4>
                                         }
                                     </div>
                                     <ul className="features">
                                         <li className="true">Forms-<b>{this.props.plandetails.results[1].form_count}</b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b>{this.props.plandetails.results[1].team_count}</b></li>
                                         <li className="false">Workflow-<b>{this.props.plandetails.results[1].workflow_count}</b></li>
                                         <li className="false">Space-<b>{this.props.plandetails.results[1].space_assign+"MB"}</b></li>
                                         <li className="false">Email-<b>{this.props.plandetails.results[1].email_count}</b></li>
                                         <li className="false">Custom Form-<b>{this.props.plandetails.results[1].custome_form_count}</b></li>
                                         <li className="false">E-signature-<b>{this.props.plandetails.results[1].e_signature_count}</b></li>
                                         <li className="false"> Submission-<b>{this.props.plandetails.results[1].monthly_submission}</b></li>

                                     </ul>
                                     <div className="btn-box">
                                         <a className="theme-btn" style={{ textDecoration: "none", cursor: 'pointer' }} onClick={this.onNotification}>BUY plan</a>
                                     </div>
                                 </div>
                             </div>


                             <div className="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
                                 <div className="inner-box">
                                     <div className="icon-box">
                                         <div className="icon-outer"><i className="fa fa-diamond"></i></div>
                                     </div>
                                     <div className="price-box">
                                     <div className="title">{this.props.plandetails.results[2].name}</div>
                                         {this.state.monthly == true ? <h4 className="price">₹{this.props.plandetails.results[2].monthly_amount}</h4>
                                             : <h4 className="price">₹{this.props.plandetails.results[2].yearly_amount}</h4>
                                         }
                                     </div>
                                     <ul className="features">
                                         <li className="true">Forms-<b>{this.props.plandetails.results[2].form_count}</b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b>{this.props.plandetails.results[2].team_count}</b></li>
                                         <li className="false">Workflow-<b>{this.props.plandetails.results[2].workflow_count}</b></li>
                                         <li className="false">Space-<b>{this.props.plandetails.results[2].space_assign+"MB"}</b></li>
                                         <li className="false">Email-<b>{this.props.plandetails.results[2].email_count}</b></li>
                                         <li className="false">Custom Form-<b>{this.props.plandetails.results[2].custome_form_count}</b></li>
                                         <li className="false">E-signature-<b>{this.props.plandetails.results[2].e_signature_count}</b></li>
                                         <li className="false"> Submission-<b>{this.props.plandetails.results[2].monthly_submission}</b></li>

                                     </ul>
                                     <div className="btn-box">
                                         <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer" }} onClick={this.onNotification}>BUY plan</a>
                                     </div>
                                 </div>
                             </div>
                             <div className="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
                                 <div className="inner-box" style={{ borderBottom: "20px solid coral" }}>
                                     <div className="icon-box" style={{ backgroundColor: "coral" }}>
                                         <div className="icon-outer"><i className="fa fa-anchor" style={{
                                             color: "coral",
                                             border: " 5px solid coral",
                                             borderRadius: "50%"
                                         }}></i></div>
                                     </div>
                                     <div className="price-box">
                                     <div className="title">{this.props.plandetails.results[2].name}</div>
                                         {this.state.monthly == true ? <h4 className="price">₹{this.props.plandetails.results[2].monthly_amount}</h4>
                                             : <h4 className="price">₹{this.props.plandetails.results[2].yearly_amount}</h4>
                                         }
                                     </div>
                                     <ul className="features">
                                         <li className="true">Forms-<b>{this.props.plandetails.results[2].form_count}</b></li>
                                         {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
                                         <li className="true">Teams-<b>{this.props.plandetails.results[2].team_count}</b></li>
                                         <li className="false">Workflow-<b>{this.props.plandetails.results[2].workflow_count}</b></li>
                                         <li className="false">Space-<b>{this.props.plandetails.results[2].space_assign+"MB"}</b></li>
                                         <li className="false">Email-<b>{this.props.plandetails.results[2].email_count}</b></li>
                                         <li className="false">Custom Form-<b>{this.props.plandetails.results[2].custome_form_count}</b></li>
                                         <li className="false">E-signature-<b>{this.props.plandetails.results[2].e_signature_count}</b></li>
                                         <li className="false"> Submission-<b>{this.props.plandetails.results[2].monthly_submission}</b></li>

                                     </ul>
                                     <div className="btn-box">
                                         <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer", backgroundColor: "coral", border: "none" }} onClick={this.onNotification}>BUY plan</a>
                                     </div>
                                 </div>
                             </div>
                         </div>
                            }
                           
                        </div>
                    </div>
                </section>
                <div className="sec-title text-center">
                    <span className="title">Why Choose Gigaforms ?</span>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className='row' style={{ width: "65%" }}>
                        <div className='col-md-6' data-aos="zoom-in-right">
                            <div >
                                <p style={{ fontWeight: "600" }}>Form Customization :</p>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Giga Forms allows you to customize your forms to match your brand identity and specific requirements. You can easily add your company logo, colors, and fonts to your forms.</p>
                            </div>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-left">
                            <img src={Custom} style={{ marginTop: "-1em" }}></img>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-left">
                            <img src={Table} style={{ marginTop: "-1em", width: "400", height: "300" }}></img>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-right">
                            <div >
                                <p style={{ fontWeight: "600" }}>Table Form Builder :</p>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>With Giga Forms, you can create customized forms with ease, including table-based forms that allow you to organize data in a structured manner.</p>
                            </div>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-right">
                            <div >
                                <p style={{ fontWeight: "600" }}>Digitization Platform :</p>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center", fontsize: "10px" }}>With Giga Forms, you can digitize your entire form-building process, reducing paper waste and improving efficiency.</p>
                            </div>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-left">
                            <img src={Digitalization} style={{ marginTop: "-1em", width: "400", height: "300" }}></img>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-left">
                            <img src={Analytic} style={{ marginTop: "-1em", width: "400", height: "300" }}></img>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-right">
                            <div >
                                <p style={{ fontWeight: "600" }}>Form Analytics and Reporting :</p>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center", fontsize: "10px" }}>Giga Forms provides in-depth analytics and reporting features, enabling you to gain insights into form performance and make data-driven decisions.</p>
                            </div>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-right">
                            <div >
                                <p style={{ fontWeight: "600" }}>24/7 Customer Support :</p>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center", fontsize: "10px" }}>Our friendly support team is available to help you anytime you need them.</p>
                            </div>
                        </div>
                        <div className='col-md-6' data-aos="zoom-in-left">
                            <img src={Service} style={{ marginTop: "-1em", width: "400", height: "300" }}></img>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        plandetails: state.billingReducer.plandetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        PlanDetails: () => dispatch(PlanDetails())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
