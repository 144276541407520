import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, Button, Stack } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchProjectDetails, fetchProjectForms, deleteForm } from '../action';
import { formateDate } from '../../../lib/utils';
import FromsUpdateModal from '../../forms/components/FromsUpdateModal';
import DeleteModal from '../../common/components/DeleteModal';
import { Link } from 'react-router-dom';

class ProjectDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.getProjectData(this.props.token.access, this.props.params.id);
        this.props.getProjectForms(this.props.token.access, this.props.params.id);
    }

    deleteAction(event) {
        this.props.deleteForm(this.props.token.access, event.id);
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Row>
                            <Col md="8">
                                <h3>View</h3>
                                <h6 style={{ fontSize: 11 }}>Project: {this.props.projectDetails.name}</h6>
                                <p>{this.props.projectDetails.description}</p>
                            </Col>
                            <Col md='4' className='d-flex justify-content-end align-items-center'>
                                <Link to={`/forms/${this.props.projectDetails.id}/view/`}>
                                    <Button variant="outline-primary" size='md'>ADD FORM</Button>
                                </Link>
                            </Col>
                        </Row>
                        <h4>Templates</h4>
                        <Card>
                            <Card.Body>
                                <DataTable
                                    totalItemsCount={this.props.formList.count}
                                    headers={[`Template Name`, `Description`, `Created On`, `Last Modified`, `Action`]}
                                    onPageChange={(pageNumber) => this.props.getProjectForms(this.props.token.access, this.props.params.id, pageNumber)}
                                    body={this.props.formList.results.map((row, index) => {
                                        return [
                                            row.label,
                                            row.description,
                                            formateDate(row.date_added),
                                            formateDate(row.date_updated),
                                            <Row className='actionWidth'>
                                                <Stack direction="horizontal" gap={1}>
                                                    <Col sm='12'>
                                                        <Button variant="outline-warning" size='sm' className="m-1"
                                                            onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} >EDIT</Button>
                                                        <Link to={`/forms/${row.id}/fields`} >
                                                            <Button variant="outline-secondary" size='sm' className="m-1">FIELDS</Button>
                                                        </Link>
                                                        <Link to={`/forms/${row.id}/view_data`} >
                                                            <Button variant="outline-primary" size='sm' className="m-1">VIEW</Button>
                                                        </Link>
                                                        <Link to={`/forms/${row.id}/members`} >
                                                            <Button variant="outline-primary" size='sm' className="m-1">MEMBERS</Button>
                                                        </Link>
                                                        <Button onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} variant="outline-danger" size='sm' className="m-1">DELETE</Button>
                                                    </Col>
                                                </Stack>
                                            </Row>
                                        ]
                                    })}
                                />
                                <DeleteModal modalText={"Delete Project Data ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <FromsUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formList: state.projectReducer.formList,
        projectDetails: state.projectReducer.projectDetails,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteForm: (accessToken, formId) => dispatch(deleteForm(accessToken, formId)),
        getProjectData: (accessToken, Id) => dispatch(fetchProjectDetails(accessToken, Id)),
        getProjectForms: (accessToken, Id, pageNumber) => dispatch(fetchProjectForms(accessToken, Id, pageNumber))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDataView);
