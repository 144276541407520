import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";
import { FETCH_LOG_DATA } from "./actionTypes";

export function fetchLogData(accessToken, pageNumber = 1) {
    return function (dispatch) {
      dispatch({ type: LOADING_START });
      fetchApi(`/logs/?page=${pageNumber}`,
        accessToken,
        REQUEST_METHOD.GET,
        (successResponse) => {
          dispatch({ type: LOADING_END })
          dispatch({ type: FETCH_LOG_DATA, payload: successResponse })
        },
        (failureResponse) => {
          dispatch(setNotification(true, failureResponse.statusText, 'danger'))
          dispatch({ type: LOADING_END })
        })
    }
  }