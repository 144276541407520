import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import { createUser, removeUser } from '../action'
import DataTable from '../../common/components/DataTable';
import { fetchUserList } from '../../common/action';
import { formateDate } from '../../../lib/utils';
import UserUpdateModal from './UserUpdateModal';
import DeleteModal from '../../common/components/DeleteModal';
import { Link } from 'react-router-dom';

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpdateModal: false,
            showDeleteModal: false,
            selectedData: {},
            name: "",
            email: "",
            phone: "",
            show: false,
            is_superuser: false,
            search: ''
        }
    }

    componentDidMount = () => {
        this.props.fetchUserList(this.props.token.access, 1, '')
    }
    handleSearchAction() {
        this.props.fetchUserList(this.props.token.access, 1, this.state.search)
    }

    handleSignupAction(event) {
        event.preventDefault()
        let requestBody = {
            "name": this.state.name,
            "phone": this.state.phone,
            "email": this.state.email,
            "password": this.state.phone,
            "is_superuser": this.state.is_superuser,
        }
        this.props.addUser(requestBody, this.props.navigate)
        this.setState({
            name: "",
            email: "",
            phone: "",
            is_superuser: false
        })
    }

    deleteAction(event) {
        this.props.deleteUser(this.props.token.access, event.id);
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <p>Users</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleSignupAction(e) }}>
                                    <Row>
                                        <Col>
                                            <Form.Label>User Name <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Template Name" required value={this.state.name}
                                                onChange={event => { this.setState({ name: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>E-Mail<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="email" placeholder="Enter Email" value={this.state.email}
                                                required onChange={event => { this.setState({ email: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Phone<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Phone Number" required value={this.state.phone}
                                                onChange={event => { this.setState({ phone: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Super User</Form.Label>
                                            <Form.Check type="switch" label="Is the user a Super User ?" checked={this.state.is_superuser}
                                                onChange={() => this.setState({ is_superuser: !this.state.is_superuser })} />
                                        </Col>
                                        <Col className='d-flex align-items-end'>
                                            <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className='mt-5 d-flex justify-content-end'>
                                    <Col md='3' >
                                        <Form.Control size="sm" type="text" placeholder="Search Template" required value={this.state.search} onChange={(event) => { this.setState({ search: event.target.value }); }} />
                                    </Col>
                                    <Col md='1' className='d-flex align-items-end'>
                                        <Button onClick={() => this.handleSearchAction()} variant="success" size='sm'>Search</Button>
                                    </Col>
                                </Row>
                                <div className='mt-3 font-medium'>
                                    {
                                        this.props.userList.results && this.props.userList.results.length > 0 &&
                                        <DataTable
                                            totalItemsCount={this.props.userList.count}
                                            headers={[`SI No`, `User Name`, `Email`, `Phone`, `Action`]}
                                            onPageChange={(pageNumber) => this.props.fetchUserList(this.props.token.access, pageNumber, '')}
                                            body={this.props.userList.results.map((row, index) => {
                                                return [
                                                    index + 1,
                                                    row.name,
                                                    row.email,
                                                    row.phone,
                                                    <Row>
                                                        <Col sm='12'>
                                                            <Button variant="outline-warning" size='sm' className="m-1"
                                                                onClick={() => { this.setState({ showUpdateModal: true, selectedData: row }) }} >EDIT USER</Button>
                                                            {/* <Link to={`/forms/${row.id}/fields`} >
                                                                <Button variant="outline-secondary" size='sm' className="m-1">FIELDS</Button>
                                                            </a>
                                                            <Link to={`/forms/${row.id}/view_data`} >
                                                                <Button variant="outline-primary" size='sm' className="m-1">VIEW</Button>
                                                            </a> */}
                                                            <Link to={`/userlocation/${row.id}`} >
                                                                <Button variant="outline-primary" size='sm' className="m-1">TRACK</Button>
                                                            </Link>
                                                            <Button onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} variant="outline-danger" size='sm' className="m-1">REMOVE USER</Button>
                                                        </Col>
                                                    </Row>
                                                ]
                                            })} />
                                    }
                                </div>
                                <DeleteModal modalText={"Remove User ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <UserUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        userList: state.commonReducer.userList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: (requestBody, navigate) => dispatch(createUser(requestBody, navigate)),
        fetchUserList: (accessToken, pageNo, search) => dispatch(fetchUserList(accessToken, pageNo, search)),
        deleteUser: (accessToken, userId) => dispatch(removeUser(accessToken, userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
