import React, { Component } from 'react'
// import Container from 'react-bootstrap/Container';
// import { Nav, Navbar, Button } from 'react-bootstrap';
import Footer from "./lfooter";
import FormImage from '../../../Images/form_image.jpg';
import Formcontactus from '../../../Images/contact-us.png';
import { useEffect } from 'react';
import { checkLogin } from '../../../lib/utils';
import Accordion from 'react-bootstrap/Accordion';
import middleimage from "../../../Images/Accuracy.webp";
import { connect } from 'react-redux';
import { fetchContact } from '../../common/action';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Navbar from "./Navbr";
import LogoSilider from "./LogoSlider";
import { Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import Cookies from "js-cookie";
import Aos from 'aos';
import { Form } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
// import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';





class LandingPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      number: "",
      subject: "",
      message: "",
      Product_Name: "GIGA-FORM",
      cookies: false,
      accepted: false,
      Customise: false,
      card1: false,
      card2: false,
      card3: false,
      card4: false


    }
  }
  scrollToTop = () => {
    scroll.scrollToTop();
  };
  componentDidMount() {
    Aos.init();
    checkLogin("landingPage", this.props.navigate);
    if (Cookies.get("cookie_consent")) {
      // If the user has previously accepted the cookie policy, set the accepted state to true
      this.setState({ accepted: true });
    }
    this.showCookies()
  }
  showCookies = () => {
    setTimeout(() => {
      this.setState({ cookies: true })

    }, 5000);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }
  showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    localStorage.setItem("latitude", latitude);
    localStorage.setItem("longitude", longitude);
  };
  handleSubmitAction(e) {
    const { name, email, number, message, subject, Product_Name } = this.state
    e.preventDefault()
    let requestBody = {
      "data": [
        {
          value: name, field: 136
        },
        {
          value: email, field: 137
        },
        {
          value: number, field: 138
        },
        {
          value: subject, field: 139
        },

        {
          value: message, field: 140
        },
        {
          value: Product_Name, field: 141
        },
      ]
    }
    if (name !== "" && email !== "" && number !== "" && message !== "") {
      this.props.storeData('52341acc-4b9c-4031-8f82-b7e1ae8f40de', requestBody);
      this.setState({ name: "", email: "", number: "", subject: "", message: "", Product_Name: '' })
    }
    else {
      alert("Please Enter The Valid Value")
    }
  }
  handleAccept = () => {
    // When the user accepts the cookie policy, set a cookie with the name "cookie_consent" and the value "true"
    Cookies.set("cookie_consent", "true", { expires: 365 });
    this.setState({ cookies: false });
  };
  render() {
    console.log(Cookies.get("cookie_consent"))
    return (
      <div>
         <div className="scroll-to-top" onClick={this.scrollToTop}>
        <i className="fa fa-arrow-up"></i>
      </div>
        {Cookies.get("cookie_consent")== undefined ?
          <Card className='bottom-0 wow fadeInUp' style={{ width: '90%', botton: "0", border: "1px solid ", height: this.state.Customise == true ? "600px" : "fit-content", position: "fixed", zIndex: "10", backgroundColor: "#281432", marginLeft: "5%" }} data-aos="fade-up-right">
            {this.state.Customise == false ? <> <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}><h5 className='m-3 text-white' style={{ fontSize: '1vw' }}>COOKIE USAGE</h5>
              <p className='m-2' style={{ color: "white" }} onClick={() => { this.setState({ cookies: false }) }}><i class="fa fa-times" aria-hidden="true"></i></p>
            </div>
              <p className='text-white m-2' style={{ fontSize: "12px" }}>We use cookies (including third-party cookies), to provide you with the best possible online experience and to tailor content to your interests. By clicking on "Accept all" you agree to the further processing of the collected and read personal data.</p>
              <p className='text-white m-2' style={{ fontSize: "12px" }}>  For detailed information on the use and management of cookies, please click on "Customise". By clicking on "Reject", you reject the use of cookies that require consent.</p></> :

              <>
                <div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p className='text-white m-3'>MODIFICATIONS AND DETAILS</p>
                    <p className='m-2' style={{ color: "white" }} onClick={() => { this.setState({ cookies: false }) }}><i class="fa fa-times" aria-hidden="true"></i></p>
                  </div>
                  <p className='text-white m-3' style={{ fontsize: "9px" }}>In the following paragraph, you'll learn more about the purposes for which cookies are used.</p>
                  <div>
                    <p>ANALYTICS AND MARKETING COOKIES</p>
                  </div>
                </div>
              </>}

            <hr style={{ color: 'white' }}></hr>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
              {/* <Button className='mb-2' variant="outline-light" size='md' style={{ width: "16vw" }} onClick={() => { this.setState({ Customise: true }) }}>Customise</Button> */}
              <Button className='mb-2' variant='outline-light' size='md' style={{ width: "16vw" }} onClick={() => { this.setState({ cookies: false }) }}>Reject</Button>
              <Button className='mb-2' variant='light' size='md' style={{ width: "16vw" }} onClick={this.handleAccept} >Accept All</Button>

            </div>

          </Card>:
          <span></span>
        }

        <Navbar />
        <div className="d-sm-flex align-items-center justify-content-between w-100 container-fluid new" style={{ height: "90vh", backgroundColor: "white" }}>
          <div className="col-md-6 mx-auto mb-4 mb-sm-0 headline" style={{ paddingLeft: "5%" }}>
            <h1 className="display-4 my-4 font-weight-bold " data-aos="fade-up" style={{ color: "#fe914b" }} >Easily Build  <span style={{ color: "rgb(31 135 84 / 48%)", fontSize: "50px" }}>Powerful Forms</span></h1>
            <div className="text">From simple forms to complex registration forms. In just minutes, you can create the online forms you need. No code required.</div><br />
            <Link to="/login" className="btn px-5 py-3 text-white mt-3 mt-sm-0" style={{ borderRadius: "30px", backgroundColor: "#fe914b" }}>Sign in</Link>
            <Link to="#" className="btn px-5 py-3 text-white mt-3 mt-sm-0" style={{ borderRadius: "30px", backgroundColor: "#fe914b", marginLeft: "10px" }}>Request a demo</Link>
          </div>
          <div className="col-md-6 lEDT" id='div-desktop'>
            <img title="Giga Form" src={FormImage} alt="" data-aos="fade-left" />
          </div>
        </div>

        <section className="about-section" id='about'>
          <div className="container">
            <div className="row">
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column wow fadeInLeft">
                  <div className="author-desc">
                    <h2>GIGA-FORMS</h2>
                  </div>
                  <figure className="image-1"><Link to="#" className="lightbox-image" data-fancybox="images">
                    <img title="Rahul Kumar Yadav" src="https://www.influentialsoftware.com/wp-content/uploads/2020/11/etl-data-solutions-featured.png" alt="" data-aos="fade-up-right" /></Link></figure>
                </div>
              </div>
              <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div className="inner-column" style={{ paddingTop: "50px" }}>
                  <div className="sec-title">
                    <span className="title">About GIGA-FORM</span>
                    <h4>Data Collection, Survey Reports and Dynamic Content Creation Made Easy!</h4>
                  </div>
                  <div className="text">Giga-forms is a web and mobile app-based platform that provides easy to use solution for data collection, survey and dynamic content creation applications. Replacing paper forms with Giga-forms brings in many benefits such as real-time update, real-time collaboration, instant data processing and instant report building, proper allocation & storage of data for future accessibility. It has the potential to support the qualitative data & gives you an access to data control as well. It has the flexibility to be used by multiple users for various purposes yet maintaining a proper and up-to-date database. Also, it allows you to track your forms through each step of the process, resulting in an easy reporting and auditability. Cloud hosting allows the user to access it remotely.</div>

                  <div className="btn-box">
                    <Link to="#contact" className="theme-btn btn-style-one" style={{ backgroundColor: "#fe914b" }}>Contact Us</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section id="feature" className="services sections-bg" style={{ backgroundColor: "white" }}>
          <div className="container p-6" data-aos="fade-up">

            <div className="sec-title text-center">
              <h2>Features</h2>
              {/* <p >Aperiam dolorum et et wuia molestias qui eveniet numquam nihil porro incidunt dolores placeat sunt id nobis omnis tiledo stran delop</p> */}
            </div>

            <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img src="https://static.thenounproject.com/png/4309686-200.png" />
                  </div>
                  <h3 className="text" data-aos="fade-up">CMS</h3>
                  <p className="text">Provides you easy to use tools to create your cms software within minutes and without any coding.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://cdn.mapme.com/wp-content/uploads/2020/03/form-map.png" />
                  </div>
                  <h3 className="text">Form Builder</h3>
                  <p className="text"> Build any form by simply Drag and Drop without any coding. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://static.thenounproject.com/png/2017447-200.png" />

                  </div>
                  <h3 className="text">Data Management</h3>
                  <p>It provides an easy-to-use interface for managing the collected data. </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://static.thenounproject.com/png/1899280-200.png" style={{ height: "50px", width: "80px" }} />
                  </div>
                  <h3 className="text">Workflow Builder</h3>
                  <p className="text"> Can be used to build organisational workflow to suite business needs through approval and rejection system.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://cdn-icons-png.flaticon.com/512/66/66720.png" />
                  </div>
                  <h3 className="text">Document signing </h3>
                  <p className="text text-justify">Post creation of Document from form stage, documents can be signed using GPG keys to verify its authenticity with ease.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://cdn-icons-png.flaticon.com/512/3470/3470440.png" />
                  </div>
                  <h3 className="text">Cloud Hosted</h3>
                  <p className="text text-justify">It is available as a cloud-hosted solution as well. </p>
                </div>
              </div>
            </div>
            <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img src="https://static.thenounproject.com/png/1677715-200.png" />
                  </div>
                  <h3 className="text">Reports</h3>
                  <p className="text">It provides a powerful reporting engine for generating reports from the collected data.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://cdn-icons-png.flaticon.com/512/3234/3234207.png" />
                  </div>
                  <h3 className="text">REST APIs</h3>
                  <p className="text">It can be easily integrated with other business applications.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://static.thenounproject.com/png/727718-200.png" />
                  </div>
                  <h3 className="text">Form Sharing </h3>
                  <p className="text">Users can share forms through a self-expiring, sharable link for quick, secure and easy access.</p>
                </div>
              </div>


              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://cdn3.iconfinder.com/data/icons/auto-racing/423/Stopwatch_Timer-512.png" />
                  </div>
                  <h3 className="text">Timer</h3>
                  <p>Implementing a timer in your Giga form offers several benefits. It can create a sense of urgency and encourage users to complete the form promptly, ensuring timely submission of important data. This feature can be particularly useful in scenarios where time-sensitive information needs to be collected or when you want to enforce strict deadlines for form completion..</p>
                </div>
              </div>


              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    <img src="https://cdn-icons-png.flaticon.com/512/6332/6332079.png" />
                  </div>
                  <h3 className="text">DMS-Integration </h3>
                  <p className="text text-justify">It is directly integrated to FileGenix (Our DMS) which enables users to directly export forms as a document to run through DMS Workflow.</p>
                </div>
              </div>


              <div className="col-lg-4 col-md-6">
                <div className="service-item position-relative">
                  <div className="icon">
                    {/* <i className="fa fa-linode"></i> */}
                    <img src="https://www.enolsoft.com/Public/picture/article/2019-09-11/images/ocr-icon.png" />
                  </div>
                  <h3 className="text">OCR</h3>
                  <p className="text text-justify">By employing OCR in your Giga form, you can streamline the data entry process by eliminating the need for manual transcription. Instead of manually typing in the data from the documents, OCR automates the extraction, saving time and reducing errors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>




        <section style={{ backgroundColor: "white" }}>
          <div className="container">
            <div className="sec-title text-center">
              <h2>Application Areas</h2>

            </div>
            <div className="row">
              <div className="col-lg-3">


                <div class="blog-card spring-fever">
                  <div class="title-content">
                    <h3 className='case'><a href="#" >Quality Control</a></h3>

                  </div>
                  <div class="card-info">
                  GigaForms revolutionized the quality control practices in a steel manufacturing company. 
                    <Link to="/casestudy">Read More</Link>
                  </div>

                  <div class="gradient-overlay"></div>
                  <div class="color-overlay"></div>
                </div>



              </div>

              <div className="col-lg-3">


                <div class="blog-card-1 spring-fever-1">
                  <div class="title-content-1">
                    <h3 className='case'><a href="#">Data Collection</a></h3>

                  </div>
                  <div class="card-info-1">
                  Seeking a more efficient and accessible solution, a corporation turned to GigaForms to enhance their data collection efforts. 
                    <Link to="/casestudy1">Read More</Link>
                  </div>

                  <div class="gradient-overlay-1"></div>
                  <div class="color-overlay-1"></div>
                </div>


              </div>
              <div className="col-lg-3">


                <div class="blog-card-2 spring-fever-2">
                  <div class="title-content-2">
                    <h3 className='case'><a href="#">Simplifying Admission & Registration Process</a></h3>

                  </div>
                  <div class="card-info-2">
                  A renowned college implemented GigaForms to enhance their admission process. 
                    <Link to="/casestudy2">Read More</Link>
                  </div>

                  <div class="gradient-overlay-2"></div>
                  <div class="color-overlay-2"></div>
                </div>

              </div>
              <div className="col-lg-3">


                <div class="blog-card-3 spring-fever-3">
                  <div class="title-content-3">
                    <h3 className='case'><a href="#">Optimizing CRM process</a></h3>

                  </div>
                  <div class="card-info-3">
                  A leading E-commerce company leveraged GigaForms to optimize its CRM practices. 
                    <Link to="/casestudy3">Read More</Link>
                  </div>

                  <div class="gradient-overlay-3"></div>
                  <div class="color-overlay-3"></div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <div className='container'>
          <img src={middleimage} />

        </div>

        <LogoSilider />
        <section id="contact" className="contact" style={{ backgroundColor: "#fafbff" }}>
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6">
                <img title="contactus" src={Formcontactus} alt="" />
              </div>
              <div className="col-lg-6">
                <form action="forms/contact.php" method="post" className="php-email-form">
                  <div className="row gy-4">
                    <div className="col-md-12">
                      <input type="text" name="name" className="form-control" style={{ padding: "10px 15px" }} placeholder="Your Name" required value={this.state.name} onChange={event => { this.setState({ name: event.target.value }); }} />
                    </div>
                    {/* <div className="col-md-12">
                        <input type="text" name="name" className="form-control" style={{padding: "10px 15px"}} placeholder="Company Name" required value={this.state.Cname} onChange={event => { this.setState({ Cname: event.target.value }); }} />
                      </div> */}

                    <div className="col-md-6 ">
                      <input type="email" className="form-control" name="email" placeholder="Your Email" style={{ padding: "10px 15px" }} required value={this.state.email} onChange={event => { this.setState({ email: event.target.value }); }} />
                    </div>
                    <div className="col-md-6">
                      {/* <input type="number" className="form-control" name="Phone" placeholder="Enter Your Number" required value={this.state.number} onChange={event => { this.setState({ number: event.target.value }); }} /> */}
                      <PhoneInput
                        containerStyle={{ width: "190px" }}
                        inputStyle={{ width: "280px", height: "44px" }}


                        country={"in"}
                        enableSearch={true}
                        value={this.state.number}
                        onChange={event => { this.setState({ number: event }) }}
                      />
                    </div>
                    <div className="col-md-12">
                      {/* <input type="text" className="form-control" name="subject" style={{ padding: "10px 15px" }} placeholder="Subject" required value={this.state.subject} onChange={event => { this.setState({ subject: event.target.value }); }} /> */}
                      <Form.Group controlId="subject" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        {/* <Form.Label>Subject</Form.Label> */}
                        <Form.Control defaultValue={this.state.partnerStatus == true ? "" : "Subject"} as="select" required name="subject" onChange={this.handleInputChange} style={{ borderRadius: "0", fontSize: "14px" }}>
                          <option value="">Subject</option>
                          <option value="">Oppurtunity for partnership</option>
                          <option value="General Inquiry">General Inquiry</option>
                          <option value="Sales Inquiry">Sales Inquiry</option>
                          <option value="Sales Inquiry">Technical Support</option>

                        </Form.Control>
                        {/* <i className="fa fa-caret-down" aria-hidden="true"></i> */}


                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <textarea className="form-control" name="message" rows="6" placeholder="Message" required value={this.state.message} onChange={event => { this.setState({ message: event.target.value }); }}></textarea>

                    </div>
                    <div className="col-md-12 text-center">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                      <button type="submit" onClick={(e) => { this.handleSubmitAction(e) }}>Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
    storeData: (token, requestBody) => dispatch(fetchContact(token, requestBody)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
