import { createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import rootReducer from './indexReducer';
import Cookies from 'universal-cookie';
import { FETCH_TOKEN } from '../views/users/actionTypes';

const cookies = new Cookies();

// import {AUTHORIZATION} from './action/actionTypes';
// import {getUserDataFromStorage} from '../library/utils';

const persistedState = cookies.get('token')
const middleware = applyMiddleware(thunk);
const store = createStore(rootReducer, middleware);
store.dispatch({ type: FETCH_TOKEN, payload: persistedState })
export default store;
