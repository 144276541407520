import React, { Component } from 'react';
import { Button, Card } from 'react-bootstrap';
import Navbar from "./Navbr";
import html2pdf from 'html2pdf.js';
import Giga from "../../../Images/giga.png";
import Field from "../../../Images/support1.png"
import Share from "../../../Images/formshare.jpg"
import Upload from "../../../Images/Upload-pana.png";
import Intigrate from "../../../Images/intigrate .jpg";
import EmailA from "../../../Images/emailalert.png";
import Responsive from "../../../Images/responsive.png";
import Export from "../../../Images/export.jpg";
import Build from "../../../Images/build.jpg";
import S1 from "../../../Images/s1.png";
import Verify from "../../../Images/verify.png";
import Login from "../../../Images/login.png";
import Plan from "../../../Images/plan.png";
import Payment from "../../../Images/payment.png";
import Sucess from "../../../Images/sucess.png";
import Fail from "../../../Images/fail.png";
import Userconsole from "../../../Images/userdash.png";
import Admin from "../../../Images/admindash.png";
import Video1 from "../../../Images/GigaForms1.mp4";
import Video2 from "../../../Images/jeev.mp4";
import EditFiels from "../../../Images/deletedit.webm";
import Flow1 from "../../../Images/Flow1.webm";
import C1 from "../../../Images/c1.webm";
import C2 from "../../../Images/C2.webm";
import C3 from "../../../Images/cardform.mp4";
import C4 from "../../../Images/Sizeing.mp4";
import C5 from "../../../Images/c4.mp4";
import { Link } from 'react-router-dom';





export default class Userguide extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fullscreen: false,
            Type: ""
        }
    }
    handleExportPDF = () => {
        const element = document.getElementById('jeev');

        if (element) {
            html2pdf().from(element).save('exported.pdf');
        }
    };
    handleDirectPrint = () => {
        const printContents = document.getElementById('jeev').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    render() {
        console.log("value", this.state.Type)
        return (
            <>
                <Navbar />
                <div style={{ height: "100px", width: "100%", backgroundColor: "#fdf4ef" }}>
                    <center><input className='mt-4' placeholder='Search' style={{ width: "50%", height: "40px", borderRadius: "10px", border: "transparent" }}></input></center>
                </div>
                <div className='container'>
                    <div className='row' style={{ backgroundColor: "#f3f5f7" }}>
                        <div className={this.state.fullscreen == true ? "col-md-12" : "col-md-9"} style={{ overFlow: "hidden" }}>
                            <div style={{ height: window.innerHeight - 100, overflow: "scroll" }}>
                                <Card>
                                    <div className='container sticky-top' style={{ borderBottom: "2px solid red" }}>
                                        <div className='p-3' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "white" }}>
                                            <div>
                                                <h5 >Welcome to Giga Forms!</h5>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <span> <i class="fa fa-file-pdf-o" aria-hidden="true" title='Download As PDF' style={{ fontSize: "25px", color: "red" }} onClick={() => { this.handleExportPDF() }}></i></span>
                                                <span style={{ marginLeft: "8px" }}><i class="fa fa-print" aria-hidden="true" style={{ fontSize: "28px", color: "tomato" }} title="Print" onClick={() => { this.handleDirectPrint() }}></i></span>
                                                <span style={{ marginLeft: "8px" }}><i class="fa fa-file-text" aria-hidden="true" style={{ fontSize: "25px", color: "green" }} title="view" onClick={() => { this.setState({ fullscreen: !this.state.fullscreen }) }}></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.Type == "getstarted" ?
                                        <div className='container' id='jeev'>
                                            <p style={{ fontSize: "25px" }}>Getting Started - build your first form!</p>
                                            <center><img src={Build} className="p-4" style={{ height: "400px", width: "500px" }}></img></center>
                                            <p className='p-4' style={{ textAlign: "justify" }}>This walkthrough is to help you get started with creating a form using Giga Forms. Follow the simple step-by-step instructions given below to build forms  that perfectly match your requirements.</p>
                                            <h5 className='p-2'>Creating a new account</h5>

                                            <p className='p-4' style={{ textAlign: "justify" }}>To build a form using Giga Forms, you must first create an account in Giga Forms. To create an account in Giga Forms,</p>
                                            <h5 className='p-2'>Step 1</h5>
                                            <ol type='1' style={{ marginLeft: "30px" }}>
                                                <li >
                                                    Go To<span><a href='https://gigaforms.epsumlabs.com/register'> https://gigaforms.epsumlabs.com/register</a></span>
                                                </li>
                                                <li>Enter your details, and click SIGN UP </li>
                                            </ol>
                                            <center><img src={S1} ></img></center>
                                            <h5 className='p-2'>Step 2</h5>
                                            <ol type='1' style={{ marginLeft: "30px" }}>
                                                <li >
                                                    Check your email inbox for a verification message from GigaForms.
                                                </li>
                                                <li>Open the email and click on the verification link provided. This will confirm your email address.</li>
                                                <li>If you do not receive the verification email, check your spam or junk folder.</li>
                                                <li>Once you've clicked on the verification link, you will be redirected to the Login Page</li>
                                            </ol>
                                            <center><img src={Verify} ></img></center>
                                            <h5 className='p-2'>Step 3</h5>
                                            <ol type='1' style={{ marginLeft: "30px" }}>
                                                <li >
                                                    Enter your registered email address and password.
                                                </li>
                                                <li>Click on the "Login" or "Sign In" button to access your GigaForms account.</li>
                                            </ol>
                                            <center><img src={Login} ></img></center>
                                            <h5 className='p-2'>Step 4</h5>
                                            <ol type='1' style={{ marginLeft: "30px" }}>
                                                <li style={{ color: "red" }}>
                                                    Free Plan:
                                                </li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Cost: No cost</li>
                                                    <li style={{ listStyleType: "circle" }}>Features:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "square" }}>Limited form templates and customization options</li>
                                                        <li style={{ listStyleType: "square" }}>Basic form analytics</li>
                                                        <li style={{ listStyleType: "square" }}>Limited form submissions</li>

                                                    </ul>
                                                </ul>
                                                <li style={{ color: "red" }}>Deluxe Plan:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Cost:Affordable monthly or yearly subscription fee</li>
                                                    <li style={{ listStyleType: "circle" }}>Features:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "square" }}>Expanded selection of form templates</li>
                                                        <li style={{ listStyleType: "square" }}>Advanced form customization options</li>
                                                        <li style={{ listStyleType: "square" }}>Increased form submissions</li>

                                                    </ul>
                                                </ul>
                                                <li style={{ color: "red" }}>Ultimate Plan:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Cost: Premium monthly or yearly subscription fee</li>
                                                    <li style={{ listStyleType: "circle" }}>Features:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "square" }}>All features included in the Deluxe Plan</li>
                                                        <li style={{ listStyleType: "square" }}>Priority customer support</li>
                                                        <li style={{ listStyleType: "square" }}>Priority access to new features and updates</li>

                                                    </ul>
                                                </ul>
                                                <li style={{ color: "red" }}>Custom Plans:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Cost: Tailored pricing based on specific requirements</li>
                                                    <li style={{ listStyleType: "circle" }}>Features:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "square" }}>Customized package designed to meet unique needs</li>
                                                        <li style={{ listStyleType: "square" }}>Advanced integrations and customizations</li>
                                                        <li style={{ listStyleType: "square" }}>Enterprise-level support and service</li>

                                                    </ul>
                                                </ul>
                                            </ol>
                                            <p className='p-4' style={{ textAlign: "justify" }}>Review the features and benefits of each plan.Choose the plan that best suits your requirements and budget.Follow the on-screen instructions to complete the plan selection and payment process.</p>
                                            <center><img src={Plan} ></img></center>
                                            <h5 className='p-2'>Step 5</h5>
                                            <p className='p-1'>After selecting the plan, you will be directed to the payment page to complete the subscription process. The payment page will require you to provide the necessary details and choose a payment method. Here's how to proceed</p>
                                            <center><img src={Payment} ></img></center>
                                            <ol type='1' style={{ marginLeft: "30px" }}>
                                                <li style={{ color: "red" }}>
                                                    Payment Information:
                                                </li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Fill in the required payment information, such as your name, billing address, and payment card details (credit/debit card).</li>
                                                    <li style={{ listStyleType: "circle" }}>Ensure that the information provided is accurate and up to date.</li>

                                                </ul>
                                                <li style={{ color: "red" }}>Choose a Payment Method:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>GigaForms typically offers various payment methods, such as credit cards, debit cards, or online payment gateways.</li>
                                                    <li style={{ listStyleType: "circle" }}>Select your preferred payment method from the available options.</li>

                                                </ul>
                                                <li style={{ color: "red" }}>Review and Confirm:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Double-check the details you entered, including the selected plan, payment amount, and billing information.</li>
                                                    <li style={{ listStyleType: "circle" }}>Take a moment to review the terms and conditions, refund policy, or any additional information provided on the payment page.</li>

                                                </ul>
                                                <li style={{ color: "red" }}>Complete the Payment:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Click on the "Pay," "Proceed to Payment," or similar button to initiate the payment process.</li>
                                                    <li style={{ listStyleType: "circle" }}>If using a credit/debit card, you may need to enter the CVV code for verification.</li>

                                                </ul>
                                                <li style={{ color: "red" }}>Payment Confirmation:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Once the payment is successfully processed, you will receive a confirmation message on the payment page or via email.</li>
                                                    <li style={{ listStyleType: "circle" }}>The confirmation will include details such as payment receipt, subscription start date, and any relevant instructions.</li>

                                                </ul>
                                                <center><img src={Sucess} ></img></center>
                                                <li style={{ color: "red" }}>Failure Notification:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>OWhen the payment fails, you will be automatically redirected to a failure screen.</li>
                                                    <li style={{ listStyleType: "circle" }}>The screen will display a clear message indicating that the payment was unsuccessful.</li>

                                                </ul>
                                                <center><img src={Fail} ></img></center>
                                                <li style={{ color: "red" }}>Access Granted:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>After payment confirmation, you will typically be redirected back to your GigaForms account dashboard.</li>
                                                    <li style={{ listStyleType: "circle" }}>Your chosen plan's features and benefits will now be available for you to use.</li>

                                                </ul>
                                                <p>After completing the payment, you will be redirected to your user console in GigaForms. From there, you can access various features and manage your forms. Here's how to proceed:</p>
                                                <li style={{ color: "red" }}>User Console:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Upon successful payment, you will land on your user console, which serves as your main control panel.</li>
                                                    <li style={{ listStyleType: "circle" }}>The user console provides an overview of your forms, submissions, and other relevant information.</li>

                                                </ul>
                                                <br />
                                                <center><img src={Userconsole} ></img></center>
                                                <li style={{ color: "red" }}>Creating Forms:</li>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Navigate to the admin console or click on the "Create Forms" or "Forms" section in the user console.</li>
                                                    <li style={{ listStyleType: "circle" }}>This will direct you to the form creation screen.</li>

                                                </ul>
                                                <br />
                                                <center><img src={Admin} ></img></center>
                                                <li style={{ color: "red" }}>Form Creation:</li>
                                                <p>On the form creation screen, enter the following details:</p>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Form Name: Give your form a descriptive name.</li>
                                                    <li style={{ listStyleType: "circle" }}>Description: Provide a brief description or instructions for the form.</li>
                                                    <li style={{ listStyleType: "circle" }}>Header Image (Optional): Upload an image to be displayed at the top of the form.</li>
                                                    <li style={{ listStyleType: "circle" }}>Footer Image (Optional): Upload an image to be displayed at the bottom of the form.</li>
                                                    <li style={{ listStyleType: "circle" }}>Enable Notifications (Optional): Toggle this option to receive email notifications when form data is submitted.</li>
                                                    <li style={{ listStyleType: "circle" }}><b>Timer Option:</b>

                                                        If you want to set a time limit for form submissions, locate the timer option.
                                                        Enter the desired time limit or duration for form completion.
                                                        Once the time limit is reached, the form will automatically be submitted.</li>



                                                </ul>
                                                <center>
                                                    <video width="100%" height="640" controls autoPlay>
                                                        <source src={Video1} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </center>
                                                <p>After creating the form, you will need to set up the fields on the field page. GigaForms offers various types of fields that you can add to your form to collect different types of information. Here's how you can proceed:</p>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>In the admin console or form creation screen, navigate to the field page or click on the "Fields" section.</li>
                                                    <li style={{ listStyleType: "circle" }}>This will take you to the page where you can add and configure different fields for your form.</li>



                                                </ul>
                                                <li style={{ color: "red" }}>Adding Fields:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>On the field page, you will find a list of available field types.</li>
                                                    <li style={{ listStyleType: "circle" }}>Click on the desired field type to add it to your form.</li>

                                                </ul>
                                                <li style={{ color: "red" }}>Configuring Field Properties:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Once you add a field to your form, you can configure its properties by clicking on it.</li>
                                                    <p><b>Common properties you can set for each field include:</b></p>
                                                    <li style={{ listStyleType: "circle" }}>Label: Enter the label or question for the field.</li>
                                                    <li>Field Type Specific Properties: Depending on the field type, you may have additional properties to configure. For example, for a "Multiple Choice" field, you can add options and select the default choice.</li>
                                                    <li style={{ listStyleType: "circle" }}>Validation: Specify any validation rules or constraints for the field (e.g., required field, minimum/maximum length, specific format).</li>
                                                    <li style={{ listStyleType: "circle" }}>Validation: Specify any validation rules or constraints for the field (e.g., required field, minimum/maximum length, specific format).</li>
                                                    <li style={{ listStyleType: "circle" }}>Conditional Logic (Optional): If you want to show or hide a field based on certain conditions, you can set up conditional logic for that field.</li>

                                                </ul>
                                                <center>
                                                    <video width="100%" height="640" controls autoPlay>
                                                        <source src={Video2} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </center>
                                                <li style={{ color: "red" }}>Reordering Fields:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>To change the order of fields, simply click and drag the field to the desired position on the field page.</li>

                                                </ul>
                                                <li style={{ color: "red" }}>Deleting Fields:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>If you want to remove a field from the form, locate the delete or remove option associated with that field and click on it.
                                                    </li>

                                                </ul>
                                                <li style={{ color: "red" }}>Saving and Previewing the Form:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>After configuring the fields, make sure to save your form.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>Preview the form to ensure that the fields are displayed correctly and the properties are set as intended.
                                                    </li>
                                                    <center>
                                                        <video width="100%" height="640" controls autoPlay>
                                                            <source src={EditFiels} type="video/webm" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </center>

                                                </ul>
                                                <p>After setting up the fields in the form, you can proceed to the form page to view and interact with the form. On the form page, you will have options to add comments, preview the form, and share it with users. Here's how you can proceed:</p>
                                                <li style={{ color: "red" }}>Accessing the Form Page:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>In the admin console or form management section, navigate to the form page or click on the specific form you want to view.
                                                    </li>
                                                </ul>
                                                <li style={{ color: "red" }}>Adding Comments:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>On the form page, you may find a comment section or a dedicated area to provide comments or notes related to the form or its data.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>You can add comments by typing in the comment box and saving it.
                                                    </li>
                                                </ul>
                                                <li style={{ color: "red" }}>Previewing the Form:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>To preview how the form will appear to users, click on the "View" or similar button.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>This will display a preview of the form with the fields and layout as they will be seen by the users.
                                                    </li>
                                                </ul>
                                                <li style={{ color: "red" }}>Sharing the Form:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>To share the form with users, locate the "Share" or "Share Form" option on the form page.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>Click on the option to proceed with sharing.
                                                    </li>
                                                </ul>
                                                <li style={{ color: "red" }}>Setting Start and End Dates:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>When prompted to share the form, you may be asked to set the start and end dates for form availability.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>Select the desired start date and end date for the form's availability period.
                                                    </li>
                                                </ul>
                                                <li style={{ color: "red" }}>Setting Start and End Dates:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>When prompted to share the form, you may be asked to set the start and end dates for form availability.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>Select the desired start date and end date for the form's availability period.
                                                    </li>
                                                </ul>
                                                <li style={{ color: "red" }}>Generating the Form Link:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>After selecting the dates, a unique form link will be generated.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>Copy the form link, as it will be used to share the form with users.
                                                    </li>
                                                </ul>
                                                <li style={{ color: "red" }}>Sharing Options:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Depending on the sharing options available, you may have the choice between "Anyone with the link" or "Restricted" access.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>"Anyone with the link": This option allows anyone who has the form link to access and fill out the form.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>"Restricted": This option restricts form access only to assigned individuals or users.</li>
                                                </ul>
                                                <li style={{ color: "red" }}>Sharing the Form:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>Share the generated form link with the intended users or audience.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>You can share the link via email, messaging apps, or by embedding it on a website or portal.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>"Restricted": This option restricts form access only to assigned individuals or users.</li>
                                                </ul>
                                                <li style={{ color: "red" }}>Form Submissions:</li>

                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>As users fill out the form, their submissions will be collected and stored within GigaForms.
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>You can access the submitted data through the form management section or by exporting the data to other formats, such as CSV or Excel.
                                                    </li>
                                                </ul>
                                                <center>
                                                    <video width="100%" height="640" controls autoPlay>
                                                        <source src={Flow1} type="video/webm" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </center>



                                            </ol>



                                        </div>
                                        : this.state.Type == "custom" ?
                                            <div className='container' id='jeev'>
                                                <p>To access the custom form functionality and explore advanced features like dynamic table integration, digital signatures, and drag-and-drop field creation, follow these steps:</p>
                                                <ol type='1'>
                                                    <li style={{ color: "red" }}>Locate the "Go to Custom Form" Option:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>On the top of the screen or within the form management section, look for the "Go to Custom Form" button or link.</li>
                                                        <li style={{ listStyleType: "circle" }}>Click on it to access the custom form editor.</li>
                                                    </ul>
                                                    <li style={{ color: "red" }}>Custom Form Editor:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>After clicking the "Go to Custom Form" option, you will be directed to the custom form editor interface.</li>
                                                        <li style={{ listStyleType: "circle" }}>The custom form editor provides advanced features and customization options beyond the standard form creation.</li>
                                                    </ul>
                                                    <center>
                                                        <video width="100%" height="640" controls autoPlay>
                                                            <source src={C1} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </center>
                                                    <li style={{ color: "red" }}>Drag and Drop Field Creation:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>In the custom form editor, you can create fields by using a drag-and-drop interface.</li>
                                                        <li style={{ listStyleType: "circle" }}>Locate the field options or toolbox on the side of the editor.</li>
                                                        <li style={{ listStyleType: "circle" }}>Drag the desired field type onto the form canvas to add it to your form.</li>
                                                    </ul>
                                                    <center>
                                                        <video width="100%" height="640" controls autoPlay>
                                                            <source src={C2} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </center>
                                                    <li style={{ color: "red" }}>Card-Based Organization:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>In a card form, the form fields are grouped into separate cards or sections.</li>
                                                        <li style={{ listStyleType: "circle" }}>Each card focuses on a specific topic or category of information.</li>
                                                        {/* <li  style={{ listStyleType: "circle" }}>Drag the desired field type onto the form canvas to add it to your form.</li> */}
                                                    </ul>
                                                    <center>
                                                        <video width="100%" height="640" controls autoPlay>
                                                            <source src={C3} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </center>
                                                    <li style={{ color: "red" }}>Field Customization and Sizing:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>Once a field is added to the form canvas, you can customize its properties by selecting the field and modifying the settings.</li>
                                                        <li style={{ listStyleType: "circle" }}>Additionally, you can dynamically adjust the size and positioning of fields within the form by dragging their edges or corners.</li>
                                                        {/* <li  style={{ listStyleType: "circle" }}>Drag the desired field type onto the form canvas to add it to your form.</li> */}
                                                    </ul>
                                                    <center>
                                                        <video width="100%" height="640" controls autoPlay>
                                                            <source src={C4} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </center>
                                                    <li style={{ color: "red" }}>Dynamic Table Integration:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>If the custom form editor supports dynamic table integration, you will find a specific field type or option for adding tables to your form.</li>
                                                        <li style={{ listStyleType: "circle" }}>Use this feature to include tables that allow users to input or manipulate data in a structured tabular format.</li>
                                                        {/* <li  style={{ listStyleType: "circle" }}>Drag the desired field type onto the form canvas to add it to your form.</li> */}
                                                    </ul>
                                                    <li style={{ color: "red" }}>Digital Signature Support:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>Look for a dedicated digital signature field or option within the custom form editor.</li>
                                                        <li style={{ listStyleType: "circle" }}>Add this field to your form to enable users to provide digital signatures.</li>
                                                        {/* <li  style={{ listStyleType: "circle" }}>Drag the desired field type onto the form canvas to add it to your form.</li> */}
                                                    </ul>
                                                    <li style={{ color: "red" }}>Save and Preview:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>As you make changes and customize your custom form, remember to save your progress regularly to ensure that your modifications are retained.</li>
                                                        <li style={{ listStyleType: "circle" }}>Use the preview function within the custom form editor to review how the form will appear and function for users.</li>
                                                        {/* <li  style={{ listStyleType: "circle" }}>Drag the desired field type onto the form canvas to add it to your form.</li> */}
                                                    </ul>
                                                    <li style={{ color: "red" }}>Switching Between Normal and Custom Forms:</li>
                                                    <ul>
                                                        <li style={{ listStyleType: "circle" }}>You can switch between the normal form editor and the custom form editor as needed.</li>
                                                        <li style={{ listStyleType: "circle" }}>Look for options or buttons that allow you to switch back to the standard form creation interface.</li>
                                                        {/* <li  style={{ listStyleType: "circle" }}>Drag the desired field type onto the form canvas to add it to your form.</li> */}
                                                    </ul>
                                                    <center>
                                                        <video width="100%" height="640" controls autoPlay>
                                                            <source src={C5} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        </center>
                                                </ol>
                                            </div>
                                            : <div className='container' id='jeev'>
                                                <p>Welcome to Giga Forms, where your form-building experience reaches new heights!</p>
                                                <img src={Giga} className="p-4"></img>
                                                <p className='p-4' style={{ textAlign: "justify" }}>Giga Forms is a comprehensive platform that empowers businesses and individuals to effortlessly create, customize, and deploy highly responsive and intuitive forms. Whether you need simple contact forms, surveys, registration forms, or complex application forms, Giga Forms offers a user-friendly interface and powerful features to meet all your needs. Bid farewell to tedious paper-based processes and modernize your data management practices with our feature rich platform. Experience the freedom of accessible and controllable data like never before!</p>
                                                <h5 className='p-2'>Form Builder:-</h5>
                                                <center><img src={Field} style={{ height: "400px", width: "400px" }}></img></center>
                                                <p className='p-4' style={{ textAlign: "justify" }}>Effortlessly design unique and customizable forms using our field properties.To enhance the process further, we have also implemented a user-friendly drag-and-drop interface that allows you to build forms by simply selecting and arranging various form elements such as text fields, checkboxes, radio buttons, and dropdown menus.</p>
                                                <h5 className='p-2'>Form Sharing:-</h5>
                                                <center><img src={Share} style={{ height: "400px", width: "400px" }}></img></center>
                                                <p className='p-4' style={{ textAlign: "justify" }}>With Giga Forms, you get the flexibility to integrate forms into your web pages, share form links across your websites and social media platforms, deliver forms through email, or securely share them among your team members. Our platform guarantees uninterrupted form accessibility and submission, irrespective of the web browser or device your audience uses. Expand your reach by sharing forms across diverse platforms and enjoy streamlined data collection.</p>
                                                <h5 className='p-2'>Save now and Resume later:-</h5>
                                                <center><img src={Upload} style={{ height: "400px", width: "400px" }}></img></center>
                                                <p className='p-4' style={{ textAlign: "justify" }}>No need to worry about accidental loss of data due to interruptions or technical issues,with Gigaforms allow your users to save partially filled forms, assuring no data is lost and enabling convenient completion at their own pace. This feature not only makes form filling effortless but also gives a worry-free experience.</p>
                                                <h5 className='p-2'>Integrate and share:-</h5>
                                                <center><img src={Intigrate} style={{ height: "400px", width: "400px" }}></img></center>
                                                <p className='p-4' style={{ textAlign: "justify" }}>Streamline and simplify your workflows with our integration capabilities. Seamlessly connect your online forms with various apps to initiate actions based on the responses you receive. By integrating Giga Forms with an online app, you can automatically transfer form data to that app and automate all your workflows easily. Explore our integration feature to discover how these integrations can significantly enhance your productivity.</p>
                                                <h5 className='p-2'>Email Alerts:-</h5>
                                                <center><img src={EmailA} style={{ height: "400px", width: "400px" }}></img></center>
                                                <p className='p-4' style={{ textAlign: "justify" }}>Receive prompt email alerts whenever a form is submitted, ensuring you're always up to date. Keep the communication flow smooth by automatically sending confirmation emails for every action performed on GigaForms . With these modern and robust functionalities, you can maintain a seamless correspondence with your clients and team members.</p>
                                                <h5 className='p-2'>Responsive Forms:-</h5>
                                                <center><img src={Responsive} style={{ height: "400px", width: "400px" }}></img></center>
                                                <p className='p-4' style={{ textAlign: "justify" }}>When it comes to form filling, it's essential to provide users with an intuitive and convenient interface, regardless of the device or browser.To conquer every screen size with responsive forms that automatically adjust their layout based on the screen size and resolution of the device being used. This ensures that the forms are readable, accessible, and easy to interact with in all kind of devices.</p>
                                                <h5 className='p-2'>Export Reports:-</h5>
                                                <center><img src={Export} style={{ height: "400px", width: "400px" }}></img></center>
                                                <p className='p-4' style={{ textAlign: "justify" }}>GigaForms empowers users to effortlessly create customized forms to their specific needs, generate and export reports in multiple formats. Experiene report exporting  in Excel, CSV, and PDF formats with just a few clicks. With this feature you can utilize your form data in the most effective and efficient way possible. </p>

                                            </div>}
                                </Card>
                            </div>
                        </div>
                        {this.state.fullscreen !== true && <div className='col-md-3'>
                            <div style={{ height: window.innerHeight - 100, overflowY: "scroll", }}>
                                <Card style={{ height: window.innerHeight, backgroundColor: "transparent" }}>
                                    <div style={{ display: "inline" }}>
                                        <h5 className='m-3'>Overview</h5>
                                        <a href=''> <p className='m-2'><i class="fa fa-link" aria-hidden="true"></i> Welcome to GigaForms !</p></a>
                                        <a className='firstform' onClick={() => { this.setState({ Type: "getstarted" }) }}> <p className='m-2'><i class="fa fa-link" aria-hidden="true"></i> Getting Started - build your first form!</p></a>

                                    </div>
                                    <div className='m-3' style={{ border: "2px solid gray", width: "200px", borderRadius: "10px" }}>
                                        <p className='m-2'>New In Giga Forms ?</p>
                                      <Link to='/login'>  <Button className='m-2' variant='danger' style={{ width: "150px" }}>Sign in</Button></Link>
                                        <div className='m-3' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div id='facebook' style={{ border: "0.5px solid grey", height: "30px", width: "30px" }}>
                                                <i class="fa fa-facebook" aria-hidden="true" style={{ alignItems: "center", justifyContent: "center", display: "flex", lineHeight: "27px" }}></i>
                                            </div>
                                            <div className='twitter' style={{ border: "0.5px solid grey", height: "30px", width: "30px" }}><i class="fa fa-twitter" aria-hidden="true" style={{ alignItems: "center", justifyContent: "center", display: "flex", lineHeight: "27px" }}></i></div>
                                            <div className='linkedin' style={{ border: "0.5px solid grey", height: "30px", width: "30px" }}><i class="fa fa-linkedin" aria-hidden="true" style={{ alignItems: "center", justifyContent: "center", display: "flex", lineHeight: "27px" }}></i></div>
                                            <div className='insta' style={{ border: "0.5px solid grey", height: "30px", width: "30px" }}><i class="fa fa-instagram" aria-hidden="true" style={{ alignItems: "center", justifyContent: "center", display: "flex", lineHeight: "27px" }}></i></div>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className='m-3'>Related Articles</h5>
                                        <a className='firstform' onClick={() => { this.setState({ Type: "custom" }) }}> <p className='m-2'><i class="fa fa-link" aria-hidden="true"></i> Custom Forms</p></a>

                                        <a href=''> <p className='m-2'><i class="fa fa-link" aria-hidden="true"></i>Why choose Giga Forms?</p></a>
                                    </div>

                                </Card>
                            </div>
                        </div>}

                    </div>
                </div>
            </>
        )
    }
}
