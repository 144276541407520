import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import Background from "../../../Images/green.webp";
import { createUser } from '../action';
import { checkLogin } from '../../../lib/utils';
import Toaster from '../../common/components/Toaster';
import TooltipPositioned from '../../common/components/TooltipPositioned';
import { setNotification } from '../../common/action';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/bootstrap.css";



class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            password: "",
            confirmPasword: "",
            Company_Name: "",
            passwordValidation: false,
            passwordMatchValidation: false
        }
    }

    componentDidMount() {
        checkLogin("register");
    }

    validatePassword(password) {
        let idealPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/;
        return idealPassword.test(password);
    }

    matchPassword(password, confirmedPassword) {
        return password === confirmedPassword
    }
    handleSignupAction(event) {
        event.preventDefault()
        let requestBody = {
            "name": this.state.name,
            "phone": this.state.phone,
            "email": this.state.email,
            "password": this.state.password,
            "company_name": this.state.Company_Name,
        }
        if (this.state.passwordValidation === true && this.state.passwordMatchValidation === true) {
            this.props.addUser(requestBody, this.props.navigate)
            this.setState({
                name: "",
                email: "",
                phone: "",
                password: "",
                confirmPasword: "",
                Company_Name: "",
            })
        }
        else {
            this.props.setNotification(true, 'Please enter valid credentials.', "danger")
        }
    }

    render() {
        return (
            <div className='full-height d-flex align-items-center' style={{ backgroundImage: `url(${Background})` }}>
                <Row style={{ position: "absolute", right: 0, top: 0 }} >
                    <Col>
                        <Toaster />
                    </Col>
                </Row>
                <div className="main-content-container container-fluid " >
                    <h3 className='text-center fw-bold text-uppercase'>WelCome To Giga-Forms</h3>
                    <Row className='d-flex justify-content-center'>
                        <Col md='5'>
                            <Card className='shadow p-1 mb-5 bg-white rounded'  >
                                <Card.Body className='m-2'>
                                    <h3 className='text-center fw-bold'>Registration</h3>
                                    <Form onSubmit={(e) => { this.handleSignupAction(e) }}>
                                        <Form.Group className="mb-2 mt-2 font-medium">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Full Name" required value={this.state.name}
                                                onChange={event => { this.setState({ name: event.target.value }); }} />
                                        </Form.Group>
                                        <Form.Group className="mb-2 mt-2 font-medium" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" required value={this.state.email}
                                                onChange={event => { this.setState({ email: event.target.value }); }} />
                                        </Form.Group>
                                        <Form.Group className="mb-2 mt-2 font-medium" controlId="formBasicEmail">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Your Company Name" value={this.state.Company_Name}
                                                onChange={event => { this.setState({ Company_Name: event.target.value }); }} />
                                        </Form.Group>
                                        <Form.Group className="mb-2 font-medium" controlId="formBasicPassword">
                                            <Form.Label>Password </Form.Label>
                                            <Form.Control
                                                onBlur={(event) => {
                                                    if (this.validatePassword(event.target.value)) { this.setState({ passwordValidation: true }) }
                                                    else {
                                                        this.setState({ passwordValidation: false });
                                                    }
                                                }
                                                }
                                                type="password" placeholder="Password" required value={this.state.password}
                                                onChange={event => {
                                                    if (this.validatePassword(event.target.value)) { this.setState({ passwordValidation: true }) } else {
                                                        this.setState({ passwordValidation: false });
                                                    }; this.setState({ password: event.target.value });
                                                }} />
                                            {
                                                this.state.passwordValidation === false && this.state.password.length > 0 &&
                                                <span>
                                                    <span style={{
                                                        fontWeight: '500',
                                                        color: 'red',
                                                    }}> Password is not Strong Enough  </span>
                                                    <TooltipPositioned tooltipText={"Password must contain-"} tooltipTextArray={["At least 8 Characters", "At most 16 Characters", "At least one Uppercase character [A-Z]", "At least one Lowercase character [a-z]", "At least one Number [0-9]", "At least one Special character [!@#$%^&*]"]} />
                                                </span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3 font-medium" controlId="formBasicPassword">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" onBlur={(event) => { if (this.state.passwordMatchValidation === true) { this.setState({ passwordMatchValidation: true }) } }} placeholder="Re-Enter Password" required value={this.state.confirmPasword}
                                                onChange={event => {
                                                    if (this.matchPassword(this.state.password, event.target.value) === true) { this.setState({ passwordMatchValidation: true }) }
                                                    else {
                                                        this.setState({ passwordMatchValidation: false })
                                                    }; this.setState({ confirmPasword: event.target.value });
                                                }} />
                                            {
                                                this.state.passwordMatchValidation === false && this.state.confirmPasword.length > 0 &&
                                                <span>
                                                    <span style={{
                                                        fontWeight: '500',
                                                        color: 'red',
                                                    }}>  Password confirmation doesn't match password  </span>
                                                    <TooltipPositioned tooltipText={"Passwords should match."} />
                                                </span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="mb-3 mt-3 font-medium phninput" controlId="formBasicEmail">
                                            <Form.Label >Phone</Form.Label>
                                            <PhoneInput
                                                inputStyle={{
                                                    width: "100%", height: 40
                                                }}


                                                country={"in"}
                                                enableSearch={true}
                                                value={this.state.phone}
                                                onChange={event => { this.setState({ phone: event }); }}
                                            />
                                        </Form.Group>
                                        <Button variant="success" type="submit" className='full-width mt-3 mb-4'>
                                            SIGNUP
                                        </Button>
                                        <p className='footer text-center'>Already have an account?<a className="text-decoration-none text-successs  font-weight-bold" href='/login' > Sign In</a></p>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: (requestBody, navigate) => dispatch(createUser(requestBody, navigate)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
