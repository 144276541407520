import React, { Component } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { REQUEST_METHOD } from '../../../lib/constants';
import { fetchApi } from '../../../lib/utils';
import { updateLoader } from '../../common/action';

class Radio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            required: false,
            borderShow: false,
            options: []
        }
    }

    componentDidMount() {

        if (this.props.data.master_data_code !== 'string') {
            this.props.setLoader(true);
            fetchApi(`/forms/template/${this.props.data.master_data_code}/data/`,
                this.props.token.access,
                REQUEST_METHOD.GET,
                (successResponse) => {
                    let data = []
                    successResponse.data.map((row) => {
                        return row.data.map((obj) => {
                            return data.push(obj.value)
                        })
                    })
                    this.props.setLoader(false);
                    this.setState({ options: data })
                })
        }
        else if (this.props.data.values) {
            this.props.setLoader(true);
            if (this.props.data.values.includes(',')) {
                this.setState({
                    options: this.props.data.values.split(',').map((item) => {
                        return item.trim()
                    })
                })
                this.props.setLoader(false);
            }
            else {
                this.setState({ options: [this.props.data.values] })
                this.props.setLoader(false);
            }
        }

    }


    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                {this.state.options.length > 0 && this.state.options.map((row, index) => {
                    return (<Form.Check key={index} style={{ marginRight: 20 }}
                        defaultChecked={this.props.value == row}
                        disabled={!this.props.editable}
                        type={`radio`} name="group1" label={row}
                        onClick={() => this.props.onChange(row)} />)
                })}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLoader: (status) => dispatch(updateLoader(status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Radio);
