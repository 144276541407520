import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { updateSharedData } from '../action';

class SharedUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: "",
            start_time: "",
            end_time: "",
            permission: "",
            scope: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState(
                {
                    label: this.props.selectedData.label,
                    start_time: this.props.selectedData.start_time,
                    end_time: this.props.selectedData.end_time,
                    permission: this.props.selectedData.permission,
                    scope: this.props.selectedData.scope,
                }
            )
        }
    }

    handleUpdateAction(event) {
        event.preventDefault();
        let requestBody = {
            "label": this.state.label,
            "start_time": this.state.start_time,
            "end_time": this.state.end_time,
            "permission": this.state.permission,
            "scope": this.state.scope,
        }
        this.props.handleClose();
        this.props.updateSharedData(this.props.token.access, requestBody, this.props.selectedData.id, this.props.templateId)
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form
                        onSubmit={(e) => {
                            this.handleUpdateAction(e);
                        }}
                        className='font-medium'>
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Label<span className='text-danger'>*</span></Form.Label>
                                <Form.Control size='sm' type="text" placeholder="Enter Template Name" required
                                    onChange={event => { this.setState({ label: event.target.value }); }}
                                    value={this.state.label} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start Time <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type='datetime-local' defaultValue={this.state.start_time !== '' ? this.state.start_time.replace('Z', '') : ""}
                                        onChange={event => { this.setState({ start_time: event.target.value }); }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>End Time<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="datetime-local" defaultValue={this.state.end_time !== '' ? this.state.end_time.replace('Z', '') : ""}
                                        onChange={event => { this.setState({ end_time: event.target.value }) }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Permission<span className='text-danger'>*</span></Form.Label>
                                    <Form.Select size="sm" placeholder='Select Permission'
                                        value={this.state.permission} onChange={event => { this.setState({ permission: event.target.value }); }}>
                                        <option defaultValue hidden>Select Permission</option>
                                        <option value={"ANYONE_WITH_LINK"}>Anyone With Link</option>
                                        <option value={"RESTRICTED"}>Restricted</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Scope<span className='text-danger'>*</span></Form.Label>
                                    <Form.Select size="sm" placeholder='Select Scope'
                                        value={this.state.scope} onChange={event => { this.setState({ scope: event.target.value }); }}>
                                        <option defaultValue hidden>Select Scope</option>
                                        <option value={"ADD"}>Add</option>
                                        <option value={"VIEW"}>View</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm'>UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        formList: state.formsReducer.formList,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSharedData: (accessToken, requestBody, Id, templateId) => dispatch(updateSharedData(accessToken, requestBody, Id, templateId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedUpdateModal);
