import React, { useState, useEffect } from "react";
import "./StyleSheets/Sidebar.css";
import { connect } from 'react-redux';
import { validateProjectFormList } from "../lib/utils";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button } from "react-bootstrap";
import { fetchconfurigation } from "../views/users/action";
import { fetchAllFormsList } from "../views/forms/action";

function Sidebar(props) {
    const [active, setActive] = useState("");
    const [subMenus, setSubMenus] = useState(false);
    const [formListLength, setFormListLength] = useState(0);
    const [persistedState, setpersistedState] = useState(localStorage.getItem('key'));
    const [projectFormList, setProjectFormList] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [configuration, setconfiguration] = useState({});
    const [toolTipPosition, setToolTipPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        props.configuration((data) => {
            setconfiguration(data.results[0] ? data.results[0] : {})
        });
        const updateToolTipPosition = (event) => {
            // Calculate the new position of the tool tip based on mouse position or other factors
            const newX = event.clientX;
            const newY = event.clientY;
            setToolTipPosition({ x: newX, y: newY });
        };
        props.fetchAllFormsList(props.token.access)

        window.addEventListener('mousemove', updateToolTipPosition);
        return () => window.removeEventListener('mousemove', updateToolTipPosition);
       
    }, [])

    if (persistedState === 'admin') {
        return (

            <div className="sidebar-container border-right main-sidebar" id="sticky-sidebar">
                <nav id="sidebar" className={props.toggleClass}>
                    <ul className="list-unstyled components" >
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Dashboard
                                    </Tooltip> :

                                    <span></span>

                            }
                        >
                            <li className={active === "/forms" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/dashboard" ? "#63afab" : null }}>
                                <Link to="/dashboard">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`} >
                                        <i className="fa fa-home nav_icon large " aria-hidden="true" style={{ color: window.location.pathname === "/dashboard" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/dashboard" ? "white" : null }}>Dashboard</span>}
                                </Link>
                            </li>
                        </OverlayTrigger>
                        {configuration.sign_up === 0 ? <></> :
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    props.toggleClass == "active" ?
                                        <Tooltip id={`tooltip-right`} style={{
                                            position: 'fixed',
                                            left: toolTipPosition.x,
                                            top: toolTipPosition.y,
                                        }}>
                                            Users
                                        </Tooltip> :
                                        <span></span>
                                }
                            >
                                <li className={active === "/createUser" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/createUser" ? "#63afab" : null }}>
                                    <Link to="/createUser">
                                        <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                            <i className="fa fa-user-plus" aria-hidden="true" style={{ color: window.location.pathname === "/createUser" ? "white" : null }}></i>
                                        </div>
                                        {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/createUser" ? "white" : null }}>Users</span>}
                                    </Link>

                                </li>
                            </OverlayTrigger>
                        }

                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Forms
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/forms" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/forms" ? "#63afab" : null }}>
                                <Link to="/forms">

                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-book nav_icon large" aria-hidden="true" style={{ color: window.location.pathname === "/forms" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/forms" ? "white" : null }}>Forms</span>}
                                </Link>

                            </li>
                        </OverlayTrigger>

                        {/* <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Custom Forms
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/customforms" ? "active" : null} >
                                <Link to="/customforms">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: 17 }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title">Custom Forms</span>}
                                </Link>
                            </li>
                        </OverlayTrigger> */}

                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Projects
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/projects" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/projects" ? "#63afab" : null }} >
                                <Link to="/projects">


                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-rocket nav_icon large" aria-hidden="true" style={{ color: window.location.pathname === "/projects" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/projects" ? "white" : null }}>Projects</span>}
                                </Link>

                            </li>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Workflow
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/workflow" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/workflow" ? "#63afab" : null }}>
                                <Link to="/workflow">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-sitemap nav_icon large" aria-hidden="true" style={{ color: window.location.pathname === "/workflow" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/workflow" ? "white" : null }}>Workflow</span>}
                                </Link>
                            </li>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        File Explorer
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/fileexplorer" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/fileexplorer" ? "#63afab" : null }}>
                                <Link to="/fileexplorer">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-folder-open nav_icon large" aria-hidden="true" style={{ color: window.location.pathname === "/fileexplorer" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/fileexplorer" ? "white" : null }}>File Explorer</span>}
                                </Link>
                            </li>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Team Management
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/teams" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/teams" ? "#63afab" : null }}>
                                <Link to="/teams">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-group nav_icon large" aria-hidden="true" style={{ color: window.location.pathname === "/teams" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/teams" ? "white" : null }}>Team Management</span>}
                                </Link>
                            </li>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Logs
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/logs" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/logs" ? "#63afab" : null }} >
                                <Link to="/logs">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-exchange nav_icon large" aria-hidden="true" style={{ color: window.location.pathname === "/logs" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/logs" ? "white" : null }}>Logs</span>}
                                </Link>
                            </li>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Notification
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/notification" ? "active" : null} style={{ backgroundColor: window.location.pathname === "/notification" ? "#63afab" : null }} >
                                <Link to="/notification">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-bell nav_icon large" aria-hidden="true" style={{ color: window.location.pathname === "/notification" ? "white" : null }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title" style={{ color: window.location.pathname === "/notification" ? "white" : null }}>Notification</span>}
                                </Link>
                            </li>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="right"
                            overlay={props.toggleClass == "active" ?
                                <Tooltip id={`tooltip-right`} style={{
                                    position: 'fixed',
                                    left: toolTipPosition.x,
                                    top: toolTipPosition.y,
                                }}>
                                    Reports
                                </Tooltip> :
                                <span></span>
                            }
                        >
                            <li style={{ cursor: 'pointer', backgroundColor: window.location.pathname === "/reports" ? "#63afab" : null }} className={active === "/reports" ? "active" : null} onClick={() => {
                                setSubMenus(!subMenus)
                            }}>
                                <a>
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-id-card" aria-hidden="true"></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title">Reports</span>}
                                </a>
                            </li>
                        </OverlayTrigger>{
                            subMenus == true &&
                            <div>
                                <li className={active === "/reports" ? "active" : null} >
                                    <a style={{ marginLeft: '15px' }} href="/allReports">
                                        <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                            <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                                        </div>
                                        {props.toggleClass ? null : <span className="menu-title">All Reports</span>}
                                    </a>
                                </li>
                                <li className={active === "/userReport" ? "active" : null} >
                                    <a style={{ marginLeft: '15px' }} href="/userReport">
                                        <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                            <i className="fa fa-address-book-o" aria-hidden="true"></i>
                                        </div>
                                        {props.toggleClass ? null : <span className="menu-title">User Report</span>}
                                    </a>
                                </li>
                            </div>
                        }
                    </ul>
                </nav>
            </div >
        );
    }
    else {
        return (
            <>
                <div className="sidebar-container border-right main-sidebar" id="sticky-sidebar">
                    <nav id="sidebar" className={props.toggleClass}>
                        <ul className="list-unstyled components" >
                            <OverlayTrigger
                                placement="right"
                                overlay={props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Dashboard
                                    </Tooltip> :
                                    <span></span>
                                }
                            >
                                <li className={active === "/forms" ? "active" : null}>
                                    <Link to="/dashboard" onClick={() => { props.onClick('/dashboard') }}>
                                        <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                            <i className="fa fa-home nav_icon large " aria-hidden="true"></i>
                                        </div>
                                        {props.toggleClass ? null : <span className="menu-title">Dashboard</span>}
                                    </Link>
                                </li>
                            </OverlayTrigger>
                            <OverlayTrigger
                            placement="right"
                            overlay={props.toggleClass == "active" ?
                                <Tooltip id={`tooltip-right`} style={{
                                    position: 'fixed',
                                    left: toolTipPosition.x,
                                    top: toolTipPosition.y,
                                }}> 
                                    Reports
                                </Tooltip> :
                                <span></span>
                            }
                        >
                            <li style={{ cursor: 'pointer', backgroundColor: window.location.pathname === "/reports" ? "#63afab" : null }} className={active === "/reports" ? "active" : null} onClick={() => {
                                setSubMenus(!subMenus)
                            }}>
                                <a>
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-book nav_icon large "  aria-hidden="true"></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title">Form List <i className={subMenus==true?"fa fa-angle-up":"fa fa-angle-down"}  aria-hidden="true" style={{marginLeft:"20px"}}></i></span>}
                                </a>
                            </li>
                        </OverlayTrigger>{
                            subMenus == true &&
                            <div>
                                {props.formList && props.formList.data.map((item,index)=>{
                                    return(
                                        <li className={active === "/reports" ? "active" : null} >
                                        <a style={{ marginLeft: '15px' }} href={`/forms/${item.id}/view_data`}>
                                            <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                                <i className="fa fa-book" aria-hidden="true"></i>
                                            </div>
                                            {props.toggleClass ? null : <span className="menu-title">{item.label}</span>}
                                        </a>
                                    </li>
                                    )

                                })}
                               
                            </div>
                        }
                            <OverlayTrigger
                                placement="right"
                                overlay={props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Team Reports
                                    </Tooltip> :
                                    <span></span>
                                }
                            >
                                <li className={active === "/teamReports" ? "active" : null} >
                                    <Link to="/teamReports">
                                        <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                            <i className="fa fa-group nav_icon large" aria-hidden="true"></i>
                                        </div>
                                        {props.toggleClass ? null : <span className="menu-title">Team Reports</span>}
                                    </Link>
                                </li>
                            </OverlayTrigger>
                        </ul>
                    </nav>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        formList: state.formsReducer.allFormList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        configuration: (getData) => dispatch(fetchconfurigation(getData)),
        fetchAllFormsList: (accessToken) => dispatch(fetchAllFormsList(accessToken)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
