import { REQUEST_METHOD,RESPONSE_TYPE} from "../../lib/constants"
import { fetchApi } from "../../lib/utils"
import { setNotification } from "../common/action"
import { LOADING_END, LOADING_START } from "../common/actionTypes"
import { FETCH_ALL_TEAMS, FETCH_SELECTED_USERS } from "./actionType"

export function fetchAllTeamlist(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/team/user/lead/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_ALL_TEAMS, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}
export function fetchSelectTeamUser(accessToken,Id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/team/${Id}/user`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_SELECTED_USERS, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}
