import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";
import { FETCH_ALL_TEAMS, FETCH_TEAM, FETCH_TEAM_MEMBERS } from "./actionTypes";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";

export function fetchAllTeams(accessToken, pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/common/teams/user`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_ALL_TEAMS, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function createTeam(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/teams/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Team Created successfully", "success"));
                dispatch(fetchAllTeams(accessToken));
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function deleteTeam(accessToken, teamId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/teams/${teamId}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Team deleted Successfully', 'danger'))
                dispatch(fetchAllTeams(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}

export function updateTeam(accessToken, id, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/teams/${id}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Team Updated Successfully', 'success'))
                dispatch(fetchAllTeams(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}

export function fetchTeam(accessToken, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/teams/${id}/`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_TEAM, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function addTeamMember(accessToken, requestBody, teamId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/teampermissions/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Team Member Added successfully", "success"));
                dispatch(fetchTeamMembers(accessToken, teamId));
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function fetchTeamMembers(accessToken, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/${id}/member/`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_TEAM_MEMBERS, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, "No Team Member Found", 'success'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function removeTeamMember(accessToken, memberId, teamId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/teampermissions/${memberId}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Member deleted Successfully', 'danger'))
                dispatch(fetchTeamMembers(accessToken, teamId))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}