import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Stack } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchTeam, fetchTeamMembers, removeTeamMember } from '../action';
import AddTeamMemberModal from './AddTeamMemberModal';
import userImage from '../../../Images/profile2.jpg';
import { fetchProjectDetails } from '../../project/action';
import DeleteModal from '../../common/components/DeleteModal';
import NameInitialPic from '../../users/components/NameInitialPic';

class TeamDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddTeamMemberModal: false,
            showDeleteModal: false,
            teamId: this.props.params.id,
            formId: null,
            label: '',
            description: '',
            show: false,
            selectedData:{}
        }
    }

    componentDidMount() {
        this.props.getTeam(this.props.token.access, this.props.params.id);
        this.props.getTeamMembers(this.props.token.access, this.props.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.teamData !== this.props.teamData && this.props.teamData.template !== null) {
            this.setState({ formId: this.props.teamData.template })
        }
    }

    deleteAction(event) {
        this.props.deleteMember(this.props.token.access, event.id, this.props.params.id)
    }

    render() {
        console.log("value",this.state.selectedData)
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Row className='d-flex justify-content-end'>
                                    <Col>
                                        <h5>Team Members</h5>
                                        <h3 style={{ fontSize: 11 }}>Team: {this.props.teamData.name} </h3>
                                    </Col>
                                    <Col md='2' >
                                        <Button onClick={() => this.setState({ showAddTeamMemberModal: true })} className='full-width' size='sm'>Add Member
                                            <i className="fa fa-user-plus ms-4" aria-hidden="true"></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className='font-medium'>
                                <Row>
                                    <Col md='10'>
                                        <Form.Control size="sm" type="text" placeholder="Seach Members" required value={this.state.search}
                                            onChange={(event) => { this.setState({ search: event.target.value }); }} />
                                    </Col>
                                    <Col md='2' >
                                        <Button className='full-width' variant="success" onClick={() => this.handleSearchAction()} size='sm'>Search</Button>
                                    </Col>
                                </Row>
                                {
                                    this.props.teamMembers &&  this.props.teamMembers.data &&
                                    <div className='mt-4 font-medium'>
                                        <DataTable
                                            // totalItemsCount={this.props.teamMembers.results.length}
                                            headers={[`Member Name`, `Email`,`Type`, `Action`]}
                                            // onPageChange={(pageNumber) => this.props.getTeamMembers(this.props.token.access, this.props.params.id, pageNumber)}
                                            body={this.props.teamMembers.data.map((row, index) => {
                                                return [
                                                    
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <div style={{ height: 40, width: 40 }}><NameInitialPic name={row.name} fontSize={22} />
                                                        </div>
                                                        <span className='ms-3'>{row.name}</span>
                                                    </div>,
                                                    row.email,
                                                    <div>{row.type === "1" ? 'Lead' : 'Member'}</div>,
                                                    <Button onClick={() => { this.setState({ showDeleteModal: true, selectedData: row }) }} variant="outline-danger" size='sm' className="m-1">REMOVE MEMBER</Button>
                                                ]
                                            })} />
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                        <DeleteModal modalText={"Remove Member ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                            deleteAction={(status) => { if (status !== undefined && status === true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                        />
                        <AddTeamMemberModal showModal={this.state.showAddTeamMemberModal} handleClose={() => this.setState({ showAddTeamMemberModal: false })} teamId={this.state.teamId} formId={this.state.formId} />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        teamData: state.teamReducer.teamData,
        token: state.usersReducer.token,
        teamMembers: state.teamReducer.teamMembers,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTeam: (accessToken, teamId) => dispatch(fetchTeam(accessToken, teamId)),
        getTeamMembers: (accessToken, teamId) => dispatch(fetchTeamMembers(accessToken, teamId)),
        deleteMember: (accessToken, memberId, teamId) => dispatch(removeTeamMember(accessToken, memberId, teamId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDataView);