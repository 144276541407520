import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, RESPONSE_TYPE } from "../../lib/constants";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";
import { FETCH_ALL_REPORTS, FETCH_USER_REPORT, FETCH_FORM_USER } from "./actionTypes";


export function fetchAllReports(accessToken, id, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/template/${id}/reports/${requestBody}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_ALL_REPORTS, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function fetchUserReports(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/template/user/reports/?${requestBody}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_USER_REPORT, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function fetchTemplateUser(accessToken, templateId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/${templateId}/users/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_FORM_USER, payload: successResponse })
                dispatch({ type: LOADING_END })
            }, (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}