import React, { Component } from 'react'
import Footer from "./lfooter";
import Navbar from "./Navbr";
import Invoice from "../../../Images/invoicecard.png";
import challanges from "../../../Images/challanges.webp";
import LT from "../../../Images/LT.png";
import benifits from "../../../Images/benefits.png";
import banner from "../../../Images/banner.jpg";

export default class casestudy extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <section>
        <div className="sec-title text-center">
                            <span className="title">Streamlining Quality Control Processes <br/>in the Steel Industry with GigaForms</span>
                        </div>
                        <div className="container" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >
                            <p style={{fontWeight:"600"}}>GigaForms revolutionized the quality control practices in a steel manufacturing company. </p>
                            <p className='text-right' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>This part to show on the landing page when someone hovers the cursor over the case study. Once someone clicks the below case study should be showing.</p>
                        </div>
                    </div>
                    <div className='col-md-6' data-aos="zoom-in-left">
                        <img src={banner}style={{height:"250px",width:"800px"}}></img>
                        
                    </div>
                    </div>
                
                    
                    </div>

                    <div className="container" style={{marginTop:"20px"}}>
                      <p style={{textAlign:'justify'}}>The steel industry relies heavily on quality control processes to ensure the production of high-quality and reliable steel products. Implementing efficient and accurate data collection methods is crucial for maintaining quality standards. This case study explores how GigaForms, a user-friendly online form creation tool, revolutionized the quality control practices in a steel manufacturing company, eliminating the need for coding expertise and streamlining the data collection process.</p>
                    </div>
                    <div className="container" style={{display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"white"}}>
                <div className='row'>
                    <div className='col-md-5' data-aos="zoom-in-right">
                    <img src={Invoice} style={{height:"400px",width:"500px"}}></img>
                    
                    </div>
                    <div className='col-md-7' data-aos="zoom-in-left">
                        
                        <div style={{marginTop:70}}>
                            <p style={{fontWeight:"600"}}>Background:</p>
                            <p className='text-right' style={{display:"flex",justifyContent:"center",alignItems:"center",marginRight:"100px",textAlign:"justify"}}>A leading steel manufacturer, faced challenges in their quality control laboratories. The existing data collection methods involved manual entry, paper-based forms, and complex spreadsheets, leading to inefficiencies, data inconsistencies, and increased chances of errors. Recognizing the need for a robust and simplified solution, they implemented GigaForms to enhance their quality control processes.</p>
                        </div>
                        
                    </div>
                    </div>
                
                    
                    </div>
                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Challenges Faced:</p>
                    <p style={{fontWeight:"600",textAlign:"center"}}>Prior to implementing GigaForms, they encountered several challenges in their quality control laboratories:</p>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row' >
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >

                            <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Time-consuming data collection:</b> Manual data entry and paper-based forms were time-consuming, leading to delays in data processing and analysis.</li>
                                <li style={{textAlign:'justify'}}><b>Error-prone data recording:</b> Manual entry introduced the risk of human errors, resulting in inaccurate data and compromised quality control.</li>
                                <li style={{textAlign:'justify'}}><b>Lack of standardization:</b> With multiple technicians involved in data collection, inconsistencies in form design and data interpretation were common, making it challenging to establish standardized quality control practices.</li>
                                <li style={{textAlign:'justify'}}><b>Limited accessibility:</b> Paper-based forms restricted real-time access to data, hindering prompt decision-making and collaboration among teams.</li>
                            </ol>
                            

                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center' data-aos="zoom-in-left" >
                        <img src={challanges}></img>
                        
                    </div>
                    </div>
                
                    
                    </div>
                    </div>

                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Implementation of GigaForms:</p>
                    <p style={{fontWeight:"600",textAlign:"center"}}>Recognizing the potential of GigaForms to address their challenges, they integrated the tool into their quality control processes. The implementation involved the following steps:</p>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                <div className='col-md-4' data-aos="zoom-in-left">
                        <img src={LT}></img>
                        
                    </div>
                    <div className='col-md-8' data-aos="zoom-in-right">
                        <div >
                        <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Form creation:</b> Using GigaForms' intuitive interface, the quality control team created customized online forms tailored to specific quality control parameters. The tool's "no-code" requirement enabled form creation within minutes, eliminating the need for technical expertise.</li>
                                <li style={{textAlign:'justify'}}><b>Streamlined data collection:</b> Technicians in the quality control laboratories transitioned from manual entry to digital data collection using GigaForms. They could easily access and submit forms using tablets or computers, ensuring real-time data entry and reducing the chances of errors.</li>
                                <li style={{textAlign:'justify'}}><b>Automated data validation:</b>GigaForms enabled the implementation of automated data validation rules, minimizing the risk of inaccurate data entry. Validation rules were set to ensure compliance with quality control standards and prompt technicians to rectify any discrepancies.</li>
                                <li style={{textAlign:'justify'}}><b>Centralized data management:</b>  All collected data was stored securely in a centralized database accessible to authorized personnel. This facilitated efficient data retrieval, analysis, and reporting, empowering decision-makers with real-time insights.</li>
                            </ol>
                            
                           
                        </div>
                    </div>
                
                    </div>
                
                    
                    </div>

                    </div>

                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Results and Benefits:</p>
                    <p style={{fontWeight:"600",textAlign:"center"}}>The adoption of GigaForms yielded significant improvements in their quality control processes:</p>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >
                        <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Enhanced accuracy:</b>With automated data validation and digital data entry, the occurrence of errors reduced significantly, ensuring more accurate quality control measurements and analysis.</li>
                                <li style={{textAlign:'justify'}}><b>Time savings:</b> GigaForms eliminated the time-consuming manual data entry process, allowing technicians to focus on core quality control tasks. Real-time data entry and analysis accelerated decision-making and increased overall productivity.</li>
                                <li style={{textAlign:'justify'}}><b>Standardization:</b>GigaForms' consistent form templates and validation rules ensured standardization across quality control practices, minimizing variations in data interpretation and analysis.</li>
                                <li style={{textAlign:'justify'}}><b>Collaboration and accessibility:</b>  Real-time data access and sharing facilitated effective collaboration among quality control teams, enabling prompt responses to quality issues and fostering continuous improvement.</li>
                                <li style={{textAlign:'justify'}}><b>Scalability:</b>  GigaForms' flexible design enabled ABC Steel Ltd. to adapt and expand their quality control processes seamlessly. The tool accommodated new quality control parameters and evolving industry requirements without the need for extensive reprogramming.</li>
                            </ol>
                            
                            
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center' data-aos="zoom-in-left">
                        <img src={benifits}></img>
                        
                    </div>
                    </div>
                
                    
                    </div>
                    </div>
                    <div className="container" style={{marginTop:"10px"}}>
                      <p><b>Conclusion:</b></p>
                      <p style={{textAlign:"justify"}}>By implementing GigaForms, the steel manufacturer successfully transformed their quality control laboratories, replacing manual and error-prone processes with efficient, digital workflows. The streamlined data collection and management capabilities of GigaForms improved accuracy, standardization, and collaboration among quality control teams. The tool's user-friendly interface and "no-code" requirement empowered technicians, eliminating the dependency on coding expertise. They continue to leverage GigaForms to drive quality control excellence and maintain their position as a leader in the steel industry.</p>
                    </div>
        </section>
        <Footer />
      </div>
    )
  }
}
