import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import routes from "./routes";
import { BaseLayout } from "../layouts";

export default function App(props) {
    const navigation = useNavigate();
    const location = useLocation();
    return (
        <Routes>
            {routes.map((route, index) => {
                return (
                    <Route
                        index={true}
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        element={route.layoutType === null ?
                            <route.component navigate={navigation} location={location} /> :
                            <BaseLayout {...props}>
                                <route.component navigate={navigation} location={location} />
                            </BaseLayout>
                        }
                    />
                );
            })}
            <Route path="*" element={<p>Not Found</p>} />
        </Routes>
    );
}
