import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD } from "../../lib/constants";
import { FETCH_FILE_LIST } from "./actionTypes";
import { setNotification } from "../common/action"; 
import { LOADING_START, LOADING_END } from "../common/actionTypes";

export function fetchFilelist(accessToken, searchQuery = '', pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/fileupload/files/?page=${pageNumber}${searchQuery}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_FILE_LIST, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
      )
  }
}
