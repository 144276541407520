import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { UUID } from '../../../lib/utils';

export default class AddCustom_Card_Modal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            card_name: "",
            card_desc: "",
            position: 0,
        }
        this.id = this.getId();
    }

    getId = UUID.generate;

    render() {
        return (
            <Modal show={this.props.addCardStatus} onHide={() => this.props.setAddCardStatus(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: "100%" }}>
                        <Form.Group className="mb-2" controlId="formGridAddress1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control placeholder="Enter Card Name"
                                onChange={(data) => this.setState({ card_name: data.target.value })} />
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="formGridAddress2">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type='text' as={'textarea'} placeholder="Enter Card Description" onChange={(data) => this.setState({ card_desc: data.target.value })} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Position</Form.Label>
                            <Form.Control type="number" placeholder="Enter Position" defaultValue={this.state.position} onChange={(data) => this.setState({ position: parseInt(data.target.value) })}
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        this.props.setAddCardStatus(false);
                        this.setState({
                            card_name: "",
                            card_desc: "",
                            position: 0,
                        })
                    }}>
                        Close
                    </Button>
                    <Button variant="success" onClick={() => {
                        if (this.state.card_name !== "") {
                            this.props.addCard({ ...this.state, id: this.getId() });
                            this.setState({
                                card_name: "",
                                card_desc: "",
                                position: 0,
                            })
                        }
                        else {
                            alert("Please enter a card name")
                        }
                    }}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
