import React, { Component } from 'react'
import Navbar from "./Navbr";
// import Footer from "./lfooter";
import { Button, Card } from 'react-bootstrap';
import Privacy from '../../../Images/privacy.png'

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <Card className='p-4 m-3' style={{ width: "70%" }}>
                        
                        <div style={{ overflowY: "scroll", height: "700px" }} id="jeev">

                            <center><h5>PrivacyPolicy</h5>
                                <hr style={{ width: "50%" }}></hr></center>
                            <center><img src={Privacy} style={{ height: "400px" }}></img></center>
                            <p className='text-justify'><b>Part I - Information Gigaforms collects and controls:</b><br/>
We only collect the information that we actually need. Some of that is information that you actively give us when you sign up for an account. We store your name and contact information, but we don't store credit card information.</p>
                            <p><b>Part II-Information that We processes on your behalf:</b>
                                <br />
                                
If you handle your Custumer's data using Gigaforms, such as information about your customers or employees, you are entrusting that data to us for processing.</p>
                            <p>
                               

                            We hold the data in your account as long as you choose to use our Services. After you terminate your account, your data will be automatically deleted from our active database within 6 months and from our backups within 3 months after that.</p>
                           
                            <center><p>For questions, please e-mail<span style={{ paddingLeft: "10px", color: "red" }}><a href="mailto:upport@gigaforms.com">support@gigaforms.com
                            </a></span> </p></center>
                        </div>

                    </Card>
                </div>

        {/* <Footer/> */}
      </div>
    )
  }
}
