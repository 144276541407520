import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_ALL_REPORTS, FETCH_USER_REPORT, FETCH_FORM_USER } from './actionTypes';

const initialState = {
    allreports: {},
    userreport: {},
    templateUsers: {}
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_REPORTS:
            return {
                ...state,
                allreports: action.payload
            }
        case FETCH_USER_REPORT:
            return {
                ...state,
                userreport: action.payload
            }
        case FETCH_FORM_USER:
            return {
                ...state,
                templateUsers: action.payload
            }
        default:
            return state;
    }
}

export default reportsReducer;
