import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleDeleteAction() {
        this.props.deleteAction(true);
        this.props.handleClose();
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >{this.props.modalText}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <p>Are you sure you want to {this.props.modalText}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleClose()} variant="warning" size='sm' >CANCEL</Button>
                    <Button onClick={() => this.handleDeleteAction()} type='submit' variant="danger" size='sm' >CONFIRM</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


export default DeleteModal;
