import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { fetchData, fetchFields, fetchComments, deleteComment, updateFormData, deleteFormData, checkUserLevel, getWorkflowAction, getWorkflowActionLog } from '../action';
import { formateDate, getFieldValue } from '../../../lib/utils';
import FormCommentModal from './FormCommentModal';
import DataTable from '../../common/components/DataTable';
import DeleteModal from '../../common/components/DeleteModal';
import WorkflowActionCommentModal from './WorkflowActionCommentModal'
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../common/components/Breadcrumbs';

class DataDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCommentModal: false,
            showCommentDeleteModal: false,
            showFormDataDeleteModal: false,
            disabledButton: false,
            showWorkflowActionCommentModal: false,
            transitionId: null
        }
    }

    componentDidMount() {
        this.props.getComments(this.props.token.access, this.props.params.id);
        this.props.getFields(this.props.token.access, this.props.params.template_id);
        this.props.getData(this.props.token.access, this.props.params.id);
        this.props.getWorkflowAction(this.props.token.access, this.props.params.id);
        this.props.getWorkflowActionLog(this.props.token.access, this.props.params.id);


    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.data.template !== prevProps.data.template) {
            this.props.checkUserLevel(this.props.token.access, this.props.data.template)
        }
        if (prevState.disabledButton !== this.state.disabledButton) {
            this.props.checkUserLevel(this.props.token.access, this.props.data.template, (resp) => { resp[0].level === 'INDIVIDUAL' && this.props.data.lock_status === 'Y' ? this.setState({ disabledButton: true }) : this.setState({ disabledButton: false }) })
        }
    }

    deleteComment(event) {
        this.props.deleteComment(this.props.token.access, event.id);
    }
    updateLockStatus() {
        let data = this.props.data;
        let requestBody = {
            "data": data.data.map((ele, index) => {
                return {
                    "value": ele.value,
                    "field": ele.field
                }
            }),
            "is_delete": false,
            "lock_status": (this.props.data.lock_status === "N" ? "Y" : "N"),
            "template": this.props.data.template
        }
        this.props.updateFormData(this.props.token.access, this.props.data.template, requestBody, this.props.data.id, this.props.navigate)
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid pt-2">

                {this.props.data.data &&
                    <Row>
                        <Col >
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                <span>
                                <Breadcrumbs name={this.props} disabledStrings={[this.props.params.id, this.props.params.template_id]} />
                                </span>
                                <span>
                                    {
                                        this.props.userLevel.length > 0 && this.props.userLevel[0].level === 'INDIVIDUAL' && this.props.data.lock_status === 'Y' ? null : <Link to={`/forms/${this.props.params.template_id}/EditDataDetails/${this.props.params.id}`}>
                                            <Button className='m-1 mb-3' variant="warning">Edit</Button>
                                        </Link>
                                    }
                                    <Button onClick={() => { this.setState({ showCommentModal: true }) }} className='m-1 mb-3' variant='outline-primary' >
                                        COMMENT
                                    </Button>
                                    {
                                        this.props.userLevel.length > 0 && this.props.userLevel[0].level === 'INDIVIDUAL' ? null : <Button className='m-1 mb-3' onClick={() => this.updateLockStatus()} variant={this.props.data.lock_status === "N" ? 'outline-danger' : 'outline-success'} >{this.props.data.lock_status === "N" ? 'LOCK' : 'UNLOCK'}
                                        </Button>
                                    }
                                </span>
                            </div>
                            <Row>
                                <Col md="6">
                                    <Card>
                                        <Card.Header>
                                            <h5>{this.props.form.label}</h5>
                                            <h6 style={{ color: "#6d6e92", fontFamily: "-moz-initial", fontSize: 16 }}>{this.props.form.description}</h6>
                                        </Card.Header>
                                        <Card.Body className='font-medium'>
                                            <div style={{ height: window.innerHeight / 1.65, overflowY: "scroll" }}>
                                                {this.props.fields.map((field, index) => {
                                                    if (field.type !== "OCR_VIEW") {
                                                        return (
                                                            <div key={index} className="m-3" >
                                                                <h6 style={{ color: "#6d6e92", fontFamily: "-moz-initial", fontSize: 16 }}>{field.label}</h6>
                                                                <div style={{ fontSize: 16, marginTop: -5 }}>
                                                                    {getFieldValue(field, this.props.data.data)}
                                                                </div>
                                                                <hr />
                                                            </div>

                                                        )
                                                    }

                                                })}
                                            </div>
                                            <Row className='pt-2'>
                                                <Col md="6">
                                                    <strong>Last Modified</strong> : {formateDate(this.props.data.date_updated)}
                                                </Col>
                                                <Col md="6">
                                                    <strong>Added By</strong> : {this.props.data.user === null ? 'Anonymous' : this.props.data.user.name}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card>
                                        <Card.Body className='font-medium'>
                                            <Row><h4>Workflow</h4></Row>
                                            <Row>
                                                <Col md='12'>
                                                    {this.props.workflowaction.results && this.props.workflowaction.results.length > 0 && this.props.workflowaction.results.map((item, i) => {
                                                        return (
                                                            <Button onClick={() => { this.setState({ showWorkflowActionCommentModal: true, transitionId: item.id }) }} className={item.properties} size='sm' style={{ margin: 2 }} >{item.label}</Button>
                                                        )

                                                    })}
                                                </Col>
                                            </Row>
                                            <br />
                                            <DataTable
                                                totalItemsCount={this.props.workflowActionLog.count}
                                                headers={[`Transition`, `Comment`, `User`, `Date Added`]}
                                                onPageChange={(pageNumber) => this.props.getWorkflowActionLog(this.props.token.access, this.props.params.id, pageNumber)}
                                                body={this.props.workflowActionLog.results.map((row, index) => {
                                                    return [
                                                        row.transition.label,
                                                        row.comment,
                                                        row.user.name,
                                                        formateDate(row.date_added),
                                                    ]
                                                })} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                    </Row>}
                <DeleteModal modalText={"Delete Data ?"} showModal={this.state.showFormDataDeleteModal} handleClose={() => this.setState({ showFormDataDeleteModal: false })}
                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteFormData(this.props.data.id) } }} selectedData={this.state.selectedData}
                />
                <FormCommentModal id={this.props.params.id} showModal={this.state.showCommentModal} handleClose={() => this.setState({ showCommentModal: false })} selectedData={this.state.selectedData} />
                <WorkflowActionCommentModal id={this.props.params.id} transitionId={this.state.transitionId} showModal={this.state.showWorkflowActionCommentModal} handleClose={() => this.setState({ showWorkflowActionCommentModal: false })} selectedData={this.state.selectedData} />
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        userLevel: state.formsReducer.userLevel,
        fields: state.formsReducer.fields,
        token: state.usersReducer.token,
        data: state.formsReducer.data,
        comments: state.formsReducer.comments,
        workflowaction: state.formsReducer.workflowAction,
        workflowActionLog: state.formsReducer.workflowActionLog,
        form: state.formsReducer.form,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkUserLevel: (accessToken, templateId) => dispatch(checkUserLevel(accessToken, templateId)),
        updateFormData: (accessToken, templateId, requestBody, id, navigate) => dispatch(updateFormData(accessToken, templateId, requestBody, id, navigate)),
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getData: (accessToken, templateId) => dispatch(fetchData(accessToken, templateId)),
        getComments: (accessToken, dataId) => dispatch(fetchComments(accessToken, dataId)),
        getWorkflowAction: (accessToken, formId) => dispatch(getWorkflowAction(accessToken, formId)),
        getWorkflowActionLog: (accessToken, formId, pageNumber) => dispatch(getWorkflowActionLog(accessToken, formId, pageNumber))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataDetails);
