import React, { Component } from 'react'
import { Modal, Button, Form, Row } from 'react-bootstrap';

export default class videoModal extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  render() {
    return (
      <div>
         <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}  size="lg"
       >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body >
                <iframe width="760" height="415" src="https://www.youtube.com/embed/9jo51nJrO0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
      </div>
    )
  }
}
