import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { createComment, deleteComment, fetchComments } from '../action';
import DataTable from '../../common/components/DataTable';
import { formateDate } from '../../../lib/utils';
import DeleteModal from '../../common/components/DeleteModal';

class FormCommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: ''
        }
    }

    handleCreateCommentAction(e) {
        e.preventDefault();
        let requestBody = {
            "comment": this.state.comment,
            "formdata": this.props.id
        }
        if (requestBody["comment"].replace(/\s/g, '').length) {
            this.props.createComment(this.props.token.access, requestBody);
        }
        this.setState({ comment: '' })
    }
    deleteComment(event) {
        this.props.deleteComment(this.props.token.access, event.id,this.props.id);
    }

    render() {
        return (
            <>
                <DeleteModal showModal={this.state.showCommentDeleteModal} handleClose={() => this.setState({ showCommentDeleteModal: false })}
                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteComment(this.state.selectedData) } }} selectedData={this.state.selectedData}
                />
                <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title >Comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Form onSubmit={(e) => this.handleCreateCommentAction(e)} className='font-medium'>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Add Comment</label>
                                <textarea className="form-control" onChange={event => { this.setState({ comment: event.target.value }); }}
                                    value={this.state.comment} id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <Form.Group className="mt-3 d-flex justify-content-end" >
                                <Button type='submit' variant="warning" size='sm' >ADD COMMENT</Button>
                            </Form.Group>
                        </Form>
                        <br />
                        <div style={{ height: "350px", overflowY: 'scroll', overflowX: "hidden" }}>
                            <DataTable
                                totalItemsCount={this.props.comments.count}
                                headers={[`User Name`, `E-mail`, `Date Added`, `Comment`, `Action`]}
                                onPageChange={(pageNumber) => this.props.getComments(this.props.token.access,this.props.id, pageNumber)}
                                body={this.props.comments.results.map((row, index) => {
                                    return [
                                        row.user.name,
                                        row.user.email,
                                        formateDate(row.date_added),
                                        row.comment,
                                        <Row>
                                            <Col sm='12'>
                                                <Button onClick={() => { this.setState({ showCommentDeleteModal: true, selectedData: row }) }} variant="outline-danger" size='sm' className="m-1">DELETE</Button>
                                            </Col>
                                        </Row>
                                    ]
                                })} />
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        formList: state.formsReducer.formList,
        token: state.usersReducer.token,
        comments: state.formsReducer.comments
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createComment: (accessToken, requestBody) => dispatch(createComment(accessToken, requestBody)),
        getComments: (accessToken, dataId,pageNumber) => dispatch(fetchComments(accessToken, dataId,pageNumber)),
        deleteComment: (accessToken, commentId,dataId) => dispatch(deleteComment(accessToken, commentId, dataId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCommentModal);
