import React, { Component } from 'react'
import Navbar from "./Navbr";
import Footer from "./lfooter";

export default class Feature extends Component {
    render() {
        return (
            <div>
                <Navbar />

                
                <section id="feature" className="services sections-bg" style={{ backgroundColor: "white" }}>
                    <div className='container p-6'>
                        <div className="sec-title text-center">
                            <h2>Features</h2></div>
                        <div className="row gy-4" data-aos-delay="100">

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src="https://static.thenounproject.com/png/4309686-200.png" />
                                    </div>
                                    <h3 className="text" >CMS</h3>
                                    <p className="text">Provides you easy to use tools to create your cms software within minutes and without any coding.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://cdn.mapme.com/wp-content/uploads/2020/03/form-map.png" />
                                    </div>
                                    <h3 className="text">Form Builder</h3>
                                    <p className="text"> Build any form by simply Drag and Drop without any coding. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://static.thenounproject.com/png/2017447-200.png" />

                                    </div>
                                    <h3 className="text">Data Management</h3>
                                    <p>It provides an easy-to-use interface for managing the collected data. </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://static.thenounproject.com/png/1899280-200.png" style={{ height: "50px", width: "80px" }} />
                                    </div>
                                    <h3 className="text">Workflow Builder</h3>
                                    <p className="text"> Can be used to build organisational workflow to suite business needs through approval and rejection system.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/66/66720.png" />
                                    </div>
                                    <h3 className="text">Document signing </h3>
                                    <p className="text text-justify">Post creation of Document from form stage, documents can be signed using GPG keys to verify its authenticity with ease.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/3470/3470440.png" />
                                    </div>
                                    <h3 className="text">Cloud Hosted</h3>
                                    <p className="text text-justify">It is available as a cloud-hosted solution as well. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row gy-4" data-aos-delay="100">

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src="https://static.thenounproject.com/png/1677715-200.png" />
                                    </div>
                                    <h3 className="text">Reports</h3>
                                    <p className="text">It provides a powerful reporting engine for generating reports from the collected data.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/3234/3234207.png" />
                                    </div>
                                    <h3 className="text">REST APIs</h3>
                                    <p className="text">It can be easily integrated with other business applications.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://static.thenounproject.com/png/727718-200.png" />
                                    </div>
                                    <h3 className="text">Form Sharing </h3>
                                    <p className="text">Users can share forms through a self-expiring, sharable link for quick, secure and easy access.</p>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://cdn3.iconfinder.com/data/icons/auto-racing/423/Stopwatch_Timer-512.png" />
                                    </div>
                                    <h3 className="text">Timer</h3>
                                    <p>Implementing a timer in your Giga form offers several benefits. It can create a sense of urgency and encourage users to complete the form promptly, ensuring timely submission of important data. This feature can be particularly useful in scenarios where time-sensitive information needs to be collected or when you want to enforce strict deadlines for form completion..</p>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <img src="https://cdn-icons-png.flaticon.com/512/6332/6332079.png" />
                                    </div>
                                    <h3 className="text">DMS-Integration </h3>
                                    <p className="text text-justify">It is directly integrated to FileGenix (Our DMS) which enables users to directly export forms as a document to run through DMS Workflow.</p>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        {/* <i className="fa fa-linode"></i> */}
                                        <img src="https://www.enolsoft.com/Public/picture/article/2019-09-11/images/ocr-icon.png" />
                                    </div>
                                    <h3 className="text">OCR</h3>
                                    <p className="text text-justify">By employing OCR in your Giga form, you can streamline the data entry process by eliminating the need for manual transcription. Instead of manually typing in the data from the documents, OCR automates the extraction, saving time and reducing errors.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
