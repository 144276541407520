import React, { Component } from 'react'
import { Button, Card, Col, Form, Row, } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchBillingUser, updateCompanyProfile } from '../action';

class CompanyProfile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      name: "",
      email: "",
      phone: "",
      GST: "",
      Id: "",
      designation: "",
      contact_person: "",
      address: "",
      contact_person: "",
      currentTab: "Company",
    }
  }
  editProfile = () => {
    var body = {
      "billing_name": this.state.name,
      "contact_no": this.state.phone,
      "email": this.state.email,
      "gst_no": this.state.GST,
      "designation": this.state.designation,
      "contact_person": this.state.contact_person,
      "address": this.state.address,
      "designation": this.state.designation
    }
    this.props.updateCompany(this.props.token.access, this.state.Id, body)
    this.setState({ edit: false })
  }
  componentDidMount() {
    this.props.billingUser(this.props.token.access)
  }

  render() {
    return (
      <>
        <div>
          <div className='container-fluid' style={{ backgroundColor: "#17a2b7", height: "60px" }}>
          </div>
          <div className='container-fluid' style={{ backgroundColor: "#2d415a", height: "180px" }}>
            <center><p style={{ color: "white", paddingTop: "70px" }}>{this.props.billinguser.billing_name}</p></center>
          </div>

          <div style={{ backgroundColor: "#cccccc", }}>

            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "20%", justifyContent: "space-evenly", marginTop: "10px" }}>
                {["Company", "Setting"].map((item, index) => {
                  return (
                    <div>
                      <p style={{ color: this.state.currentTab == item ? "green" : "black", cursor: "progress" }} onClick={() => { this.setState({ currentTab: item }) }}>{item}</p>
                      {/* {
                        this.state.currentTab == item && <hr style={{color:"red"}}/>
                      } */}
                    </div>
                  )
                })}

              </div>
            </div>
          </div>
          <center> <div style={{ width: "100px", height: "100px", backgroundColor: "#c9c9c9", borderRadius: "100px", marginTop: "-270px" }}></div></center>
        </div>
        <br></br>
        <div style={{ marginTop: "150px" }}>
          {
            this.state.currentTab == "Company" ?
              <div className='container-fluid ' >
                <Row className='p-4'>
                  <Col sm={12}> <div>
                    {this.props.billinguser &&
                      <Row className='ms-4'>
                        <Col md="6">
                          <p style={{ fontSize: "18px", color: "green" }}>Company Profile</p>
                          <hr style={{ width: "20%", paddingBottom: "-25%", color: "green" }} />
                          <Card className='pb-2' style={{ width: "100%" }}>
                            <Card.Body className='p-4'>
                              <div className='ps-3 pe-3 mt-3 card-row' >
                                <span>Name</span>
                                {
                                  this.state.edit ?
                                    <Form.Control size='sm' style={{ width: "50%" }} autoFocus type="text" placeholder="Enter Your Name" required
                                      onChange={event => { this.setState({ name: event.target.value }); }}
                                      value={this.state.name} /> :

                                    <span>{this.props.billinguser.billing_name}</span>

                                }

                              </div>
                              <div className='divider mt-2'></div>
                              <div className='ps-3 pe-3 mt-3 card-row' >
                                <span>Email</span>
                                {
                                  this.state.edit ?
                                    <Form.Control size='sm' style={{ width: "50%" }} type="email" placeholder="Enter Your Email Id" required
                                      onChange={event => { this.setState({ email: event.target.value }); }}
                                      value={this.state.email} /> :
                                    <span>{this.props.billinguser.email}</span>
                                }
                              </div>
                              <div className='divider mt-2'></div>
                              <div className='ps-3 pe-3 mt-3 card-row'>
                                <span>Phone No</span>{
                                  this.state.edit ?
                                    <Form.Control size='sm' style={{ width: "50%" }} type="number" placeholder="Enter Your Phone No" required
                                      onChange={event => { this.setState({ phone: event.target.value }); }}
                                      value={this.state.phone} /> :
                                    <span>{this.props.billinguser.contact_no}</span>
                                }

                              </div>
                              <div className='divider mt-2'></div>
                              <div className='ps-3 pe-3 mt-3 card-row'>
                                <span>Designation</span>{
                                  this.state.edit ?
                                    <Form.Control size='sm' style={{ width: "50%" }} type="text" placeholder="Enter Your Designation" required
                                      onChange={event => { this.setState({ designation: event.target.value }); }}
                                      value={this.state.designation} /> :
                                    <span>{this.props.billinguser.designation}</span>
                                }

                              </div>
                              <div className='divider mt-2'></div>
                              <div className='ps-3 pe-3 mt-3 card-row'>
                                <span>Address</span>{
                                  this.state.edit ?
                                    <Form.Control size='sm' style={{ width: "50%" }} type="text" placeholder="Enter Your Address" required
                                      onChange={event => { this.setState({ address: event.target.value }); }}
                                      value={this.state.address} /> :
                                    <span>{this.props.billinguser.address}</span>
                                }

                              </div>
                              <div className='divider mt-2'></div>
                              <div className='ps-3 pe-3 mt-3 card-row'>
                                <span>Contact Person</span>{
                                  this.state.edit ?
                                    <Form.Control size='sm' style={{ width: "50%" }} type="text" placeholder="Enter Your Contact Person" required
                                      onChange={event => { this.setState({ contact_person: event.target.value }); }}
                                      value={this.state.contact_person} /> :
                                    <span>{this.props.billinguser.contact_person}</span>
                                }

                              </div>
                              <div className='divider mt-2'></div>
                              <div className='ps-3 pe-3 mt-3 card-row' >
                                <span>GST NO</span>
                                {
                                  this.state.edit ?
                                    <Form.Control size='sm' style={{ width: "50%" }} autoFocus type="text" placeholder="Enter Your GST" required
                                      onChange={event => { this.setState({ GST: event.target.value }); }}
                                      value={this.state.GST} /> :
                                    <span>{this.props.billinguser.gst_no}</span>
                                }

                              </div>
                              <div className='divider mt-2'></div>

                              <div className="mt-4 d-flex justify-content-end">
                                {
                                  this.state.edit ?
                                    <Button variant="success" onClick={this.editProfile} size='sm' >UPDATE</Button>
                                    :
                                    <Button variant="warning" onClick={() => this.setState({ edit: true, name: this.props.billinguser.billing_name, email: this.props.billinguser.email, phone: this.props.billinguser.contact_no, GST: this.props.billinguser.gst_no, Id: this.props.billinguser.id, designation: this.props.billinguser.designation, address: this.props.billinguser.address, contact_person: this.props.billinguser.contact_person })} size='sm' >EDIT</Button>
                                }
                              </div>

                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md="5">
                          <p style={{ fontSize: "18px", color: "green" }}>Subscription</p>
                          <hr style={{ width: "20%", paddingBottom: "-25%", color: "green" }} />
                          <Card className='pb-2' style={{ width: "100%", backgroundColor: "rgb(205 122 120 / 42%)", borderRadius: "20px" }}>
                            <Card.Body className='p-4'>
                              <div className='ps-3 pe-3 card-row' >
                                <span style={{ color: "red" }}>Active Subscription</span>
                              </div>
                              <div className='divider mt-2'></div>
                              <div>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginTop: "15px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>Number Of Forms:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.form_count}</span></span></p>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>Number Of Teams:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.team_count}</span></span></p>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>Number Of Workflow:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.workflow_count}</span></span></p>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>Number Of Email:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.email_count}</span></span></p>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>Number Of custome form:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.custome_form_count}</span></span></p>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>Number Of E-sign:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.e_signature_count}</span></span></p>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>monthly submission:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.monthly_submission}</span></span></p>
                                <p><i class="fa fa-check-circle-o" aria-hidden="true" style={{ color: "green", fontSize: "20px", marginLeft: "5px" }}></i><span style={{ fontSize: "15px", marginLeft: "5px", textTransform: "uppercase" }}>space:<span style={{ color: "red", marginLeft: "8px" }}>{this.props.billinguser.space_assign}</span></span></p>
                              </div>
                              <Link to='/companyregister'>
                                <Button size='sm' variant='danger' style={{ width: "20%" }}>Upgrade</Button>
                              </Link>



                            </Card.Body>
                          </Card>
                        </Col>

                      </Row>

                    }

                  </div ></Col>

                </Row>


              </div> :
              <span></span>

          }
        </div>



      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    token: state.usersReducer.token,
    billinguser: state.billingReducer.billinguser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    billingUser: (accessToken) => dispatch(fetchBillingUser(accessToken)),
    updateCompany: (accessToken, Id, requestBody) => dispatch(updateCompanyProfile(accessToken, Id, requestBody))

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
