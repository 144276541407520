import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Col, Dropdown, DropdownButton, Form, Modal, OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Navigate, json } from 'react-router-dom';
import { getHiddenDeleteField, returnActiveCustomForm, returnedJsonList, UUID } from '../../../lib/utils';
import { setNotification } from '../../common/action';
import { createCustomForms, createFormField, createForms, deleteField, fetchActiveCustomForm, fetchAllFormsList, fetchFields, fetchFormData, getCustomForm, updateCustomForms, updateFieldForms } from '../../forms/action';
import { full_field_details, getFields, } from '../FieldTypes';
import CreateFormModal from '../common/CreateFormModal';
import EditFormModal from '../common/EditFormModal';
import image from "../../../Images/nodata.png";
import selecticon from "../../../Images/select_icon.png";



import SimpleForm from '../../../Images/SimpleForm.jpg';
import CardForm from '../../../Images/CardForm.jpg';
import CustomFormSidebar from '../common/CustomFormSidebar';
import CustomFormHeader from '../common/CustomFormHeader';
import SampleCustomForm from '../common/SampleCustomForm';
import CardCustomForm from '../common/CardCustomForm';
import AddCustom_Card_Modal from '../common/AddCustom_Card_Modal';

export const FormTemplateTypes = [
    { name: "Simple Form Template", img: SimpleForm, value: "S" },
    { name: "Card Form Template", img: CardForm, value: "C" }
]

const CustomFormDashboard = (props) => {

    const getId = UUID.generate;

    const id = getId();

    const dragItem = useRef();
    const dragOverItem = useRef();
    const [list, setList] = useState([]);
    const [currentEditField, setCurrentEditField] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const [createFormStatus, setCreateFormStatus] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [path, setPath] = useState('');
    const [formDetails, setFormDetails] = useState({});
    const [allFieldDetails, setAllFieldDetails] = useState([]);
    const [currentDraggingElement, setCurrentDraggingElement] = useState({});
    const [custom_template_type, setCustom_template_type] = useState("");
    const [addCardStatus, setAddCardStatus] = useState(false);


    useEffect(() => {
        if (props.id) {
            props.fetchActiveCustomForm(props.token.access, props.id);
            props.getCustomForm(props.token.access)
        }
        else {
            props.getCustomForm(props.token.access, true);
        }
        props.getAllForms(props.token.access);
    }, [])



    useEffect(() => {
        setAllFieldDetails(props.fields);
    }, [props.fields])

    useEffect(() => {
        var x = props.currentCustomFormDetails && props.currentCustomFormDetails.custom_form_data && JSON.parse(props.currentCustomFormDetails.custom_form_data)
        if (x) {
            setFormDetails(x)
            props.getFields(props.token.access, x.id);
            props.getFormData(props.token.access, x.id);
            setShowForm(true);
        }
    }, [props.currentCustomFormDetails])


    const dragStart = (e, type, position = list.length) => {
        dragItem.current = position;
        if (type !== '') {
            e.dataTransfer.setData("data", type);
        }
    };

    const setForm_Details = (data) => {
        setFormDetails(data);
        var updated_body = {}
        if (data.custom_template_type == 'C') {
            var my_data = { ...data, cards: [] }
            updated_body = {
                form_id: my_data.id,
                custom_template_type: my_data.custom_template_type,
                active: true,
                custom_form_data: my_data
            }
        }
        else {
            updated_body = {
                form_id: data.id,
                custom_template_type: data.custom_template_type,
                active: true,
                custom_form_data: data
            }
        }

        props.createCustomForms(props.token.access, updated_body)

        // props.getFields(props.token.access, data.id);
        // props.getFormData(props.token.access, data.id);
        setShowForm(true);
    }

    const update_CustomForm = (data) => {
        var updated_body = {
            form_id: data.id,
            custom_template_type: data.custom_template_type,
            active: true,
            custom_form_data: data
        }
        props.updateCustomForms(props.token.access, updated_body)
    }

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };



    const allowDrop = (event) => {
        event.preventDefault();
    }

    const drop = (event, position, card_no = 0) => {

        event.preventDefault();

        const data = event.dataTransfer.getData("data");

        if (data !== '') {
            var requestBody = {
                "validation": {
                    "type": null,
                    "expression": null,
                    "error_message": null
                },
                "label": full_field_details[data].label,
                "type": full_field_details[data].type,
                "mandatory": false,
                "sequence": allFieldDetails.length,
                "template": formDetails.id,
                "display_in_main_view": true,
                "placeholder": full_field_details[data].placeholder,
                "tooltip": "",
                "master_data_code": "string",
                "check_unique": false,
                "unique_type": 1,
                "auto_sum": false,
                "deafult_value": full_field_details[data].defaultValue,
                'table_data': '',
                "is_delete": false,
                "card_no": card_no
            }
            if (position >= 5 || data == 'table') {
                requestBody['display_in_main_view'] = false
            }
            props.addFields(props.token.access, formDetails.id, requestBody);
        }


        if (Object.keys(currentDraggingElement).length > 0 && position !== undefined) {
            var requestBody = { ...currentDraggingElement, sequence: position - 1, }
            onUpdate(requestBody);
        }

    };

    const removeElement = (fieldId) => {
        props.deleteField(props.token.access, fieldId, formDetails.id)
    }

    const previewForm = () => {
        if (formDetails.id) {
            setPath(`/customedform/${formDetails.id}`);
        }
        else {
            props.setNotification(true, "First create a Form.", "danger");
        }
    }

    const onCreateForm = (requestBody) => {

        if (requestBody["label"].replace(/\s/g, '').length) {
            setList([])
            requestBody['custom_template_type'] = custom_template_type
            props.addForms(props.token.access, requestBody, (data) => { setForm_Details(data) });
            setShowForm(true);
            setCreateFormStatus(false);
        }
        else {
            props.setNotification(true, "Please Provide Proper Name And Description.", "danger");
        }
    }

    const onUpdate = (updatedData) => {
        var requestBody = {
            "validation": {
                "type": updatedData.validation.type,
                "expression": updatedData.validation.expression,
                "error_message": updatedData.validation.error_message
            },
            "label": updatedData.label,
            "type": updatedData.type,
            "mandatory": updatedData.mandatory,
            "sequence": updatedData.sequence,
            "template": updatedData.template,
            "display_in_main_view": updatedData.display_in_main_view,
            "placeholder": updatedData.placeholder,
            "tooltip": updatedData.tooltip,
            "master_data_code": updatedData.master_data_code,
            "check_unique": updatedData.check_unique,
            "unique_type": updatedData.unique_type,
            "auto_sum": updatedData.auto_sum,
            "deafult_value": updatedData.deafult_value,
            "api": updatedData.api,
            "values": updatedData.values,
            "style": updatedData.currentWidth,
            'table_data': updatedData.table_data,
            'ocr_view': updatedData.ocr_view,
            "is_delete": false,
            "card_no": updatedData.card_no
        }
        if (requestBody["label"].replace(/\s/g, '').length) {
            props.editFieldForms(props.token.access, updatedData.id, requestBody, updatedData.template, () => { if (updatedData.type == 'radio' || updatedData.type == 'checkbox') { window.location.reload() } });
            setEditModal(false);
            setCurrentDraggingElement({});
        }
        else {
            props.setNotification(true, "Please Provide Correct Information", "danger")
        }
    }

    const addCard = (data) => {
        var form_details = { ...formDetails }
        var x = { ...data, "fields": [] }
        var my_cards = [...form_details.cards]
        my_cards.push(x)
        form_details.cards = my_cards
        setAddCardStatus(false)
        update_CustomForm(form_details);
        props.setNotification(true, "Card Added Successfully", 'success')
    }

    return (
        <>
            {
                path &&
                <Navigate to={path} />
            }

            <div className='fontsize-small' style={{ width: window.innerWidth, backgroundColor: "#f3f3fe", height: window.innerHeight - 55 }} >
                <div style={{ flexDirection: "row", display: "flex", width: "100%", height: "100%" }} >
                    <div style={{ width: "20%", }}>
                        <CustomFormSidebar on_drag_start={(event, item) => { dragStart(event, item) }} on_allow_drop={(event) => { allowDrop(event) }} />
                    </div>
                    <div style={{ width: "80%", height: "93%", }}>

                        <CustomFormHeader
                            allFormList={(props.customFromList.data && props.customFromList.data.length > 0) ? returnedJsonList(props.customFromList.data) : []}
                            setCustom_template_type={(data) => { setCustom_template_type(data) }}
                            setCreateFormStatus={(data) => { setCreateFormStatus(data) }}
                            updateForm={(data) => { update_CustomForm(data) }}
                            previewForm={() => { previewForm() }}
                        />

                        {
                            (props.customFromList.data && props.customFromList.data.length == 0) ?
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={image} style={{ width: "25%", height: "25%", paddingTop: "16%" }}></img>
                                        <br />
                                    </div>
                                    <div><center><p style={{ fontSize: "12px", marginLeft: "10px", color: "darkorange" }}>Opps ! You Don't Have Any Forms Yet</p></center>  </div>
                                </div>
                                :
                                Object.keys(formDetails).length == 0 ?
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src={selecticon} style={{ width: "20%", height: "20%", paddingTop: "16%" }}></img>
                                            <br />
                                        </div>
                                        <div><center><p style={{ fontSize: "15px", marginLeft: "10px", color: "rgb(5 123 138)", }}>Please Select Your Form To Continue</p></center>  </div>
                                    </div>
                                    :
                                    formDetails.custom_template_type == 'S' ?
                                        <SampleCustomForm
                                            showForm={showForm}
                                            currentEditField={currentEditField}
                                            setCurrentEditField={(data) => { setCurrentEditField(data) }}
                                            allowDrop={(data) => allowDrop(data)}
                                            allFieldDetails={allFieldDetails}
                                            formDetails={formDetails}
                                            editElement={() => setEditModal(true)}
                                            drop={(data, sequence) => drop(data, sequence)}
                                            removeElement={(data) => removeElement(data)}
                                            dragStart={(item1, item2, item3) => { dragStart(item1, item2, item3) }}
                                            setCurrentDraggingElement={(data) => setCurrentDraggingElement(data)}
                                            dragEnter={(e, index) => dragEnter(e, index)}
                                        />
                                        :
                                        <CardCustomForm
                                            showForm={showForm}
                                            currentEditField={currentEditField}
                                            setCurrentEditField={(data) => { setCurrentEditField(data) }}
                                            allowDrop={(data) => allowDrop(data)}
                                            allFieldDetails={allFieldDetails}
                                            formDetails={formDetails}
                                            editElement={() => setEditModal(true)}
                                            drop={(data, position, card_no) => { drop(data, position, card_no) }}
                                            removeElement={(data) => removeElement(data)}
                                            dragStart={(item1, item2, item3) => { dragStart(item1, item2, item3) }}
                                            setCurrentDraggingElement={(data) => setCurrentDraggingElement(data)}
                                            dragEnter={(e, index) => dragEnter(e, index)}
                                            setAddCardStatus={(status) => setAddCardStatus(status)}
                                            updateFormDetails={(data) => { update_CustomForm(data) }}
                                        />
                        }
                    </div>
                </div>
                {
                    addCardStatus &&
                    <AddCustom_Card_Modal
                        addCardStatus={addCardStatus}
                        currentPosition={formDetails.cards ? formDetails.cards.length : 0}
                        setAddCardStatus={(data) => setAddCardStatus(data)}
                        addCard={(data) => { addCard(data); }} />
                }
                {
                    createFormStatus &&
                    <CreateFormModal
                        createFormStatus={createFormStatus}
                        setCreateFormStatus={(data) => setCreateFormStatus(data)}
                        onCreateForm={(data) => onCreateForm(data)} />
                }

                {
                    editModal &&
                    <Row style={{ height: "100%", width: "100%", position: "absolute", top: 0, right: 0, zIndex: 2, display: "flex", justifyContent: "flex-end" }}>
                        <Col md="3" style={{ backgroundColor: "#3e4652", height: "100%", float: "right", color: "#fff" }}>
                            <EditFormModal
                                list={allFieldDetails[currentEditField]}
                                allFormList={props.allFormList}
                                currentEditField={currentEditField}
                                formData={props.formData}
                                onSubmit={(data) => { onUpdate(data) }}
                                closeModal={() => setEditModal(false)}
                                token={props.token.access}
                                callBack={() => {
                                    props.deleteField(props.token.access, getHiddenDeleteField(allFieldDetails[currentEditField].id, props.fields).id, formDetails.id, false, () => { })

                                }}
                            />
                        </Col>
                    </Row>
                }


            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        fields: state.formsReducer.fields,
        allFormList: state.formsReducer.allFormList,
        formData: state.formsReducer.formData,
        customFromList: state.formsReducer.customFromList,
        currentCustomFormDetails: state.formsReducer.currentCustomFormDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addForms: (accessToken, requestBody, onCreate) => dispatch(createForms(accessToken, requestBody, onCreate)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
        addFields: (accessToken, templateId, requestBody) => dispatch(createFormField(accessToken, templateId, requestBody)),
        editFieldForms: (accessToken, pk, requestBody, formId, problemSolved) => dispatch(updateFieldForms(accessToken, pk, requestBody, formId, problemSolved)),
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        deleteField: (accessToken, fieldId, formId, ocr_view, callBack) => dispatch(deleteField(accessToken, fieldId, formId, ocr_view, callBack)),
        getAllForms: (accessToken) => dispatch(fetchAllFormsList(accessToken)),
        getFormData: (accessToken, templateId, pageNumber) => dispatch(fetchFormData(accessToken, templateId, pageNumber)),
        createCustomForms: (accessToken, body) => dispatch(createCustomForms(accessToken, body)),
        getCustomForm: (accessToken, getActiveStatus) => dispatch(getCustomForm(accessToken, getActiveStatus)),
        updateCustomForms: (accessToken, requestBody) => dispatch(updateCustomForms(accessToken, requestBody)),
        fetchActiveCustomForm: (accessToken, form_id) => dispatch(fetchActiveCustomForm(accessToken, form_id))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFormDashboard);
