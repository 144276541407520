import React, { Component } from 'react'
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { fetchUserList } from '../action';

class SearchableDropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showDropdown: false, searchValue: "", userList: this.props.userList
        }
    }

    render() {
        return (
            <div style={{ width: "99%", height: "100%", cursor: "pointer" }}  >

                <Form.Control
                    type="text"
                    value={this.state.searchValue}
                    placeholder={'Search User'}
                    defaultValue={this.props.defaultValue}
                    onClick={() => {
                        this.setState({ showDropdown: !this.state.showDropdown });
                        this.props.getUserList(this.props.token.access, 1)
                    }} />
                {
                    this.state.showDropdown ?
                        <div style={{ position: "absolute", zIndex: 2, border: "solid", borderColor: "gray", borderWidth: 0.2, backgroundColor: "#fff", height: 250, overflowY: "scroll", paddingInline: 5, marginBlock: 5, paddingBlock: 5 }} >
                            <div >
                                <Form.Control
                                    type="text"
                                    placeholder={'Select User'}
                                    defaultValue={this.props.defaultValue}
                                    onChange={event => { this.props.getUserList(this.props.token.access, 1, event.target.value) }} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                {
                                    this.props.userList && this.props.userList.results.length > 0 && this.props.userList.results.map((item, i) => {
                                        return (
                                            <p key={i} style={{ padding: 2, paddingInline: 15 }} onClick={() => { this.props.onChange(item); this.setState({ showDropdown: !this.state.showDropdown, searchValue: `${item.name}` }) }}>{`${item.name}`} <div style={{fontWeight: 100}}><small>{item.email}</small></div></p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        : <></>
                }

            </div>
        );
    }
}


const mapPropsToState = store => {
    return {
        token: store.usersReducer.token,
        userList: store.commonReducer.userList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserList: (accessToken, pageNo, search) => dispatch((fetchUserList(accessToken, pageNo, search)))

    };
};

export default connect(mapPropsToState, mapDispatchToProps)(SearchableDropdown);

