import React, { Component } from 'react'
import { Form, Row } from 'react-bootstrap';

export default class ImageField extends Component {
    render() {
        return (
            <Row style={{ width: "100%" }} >
                <div className="d-flex flex-row" >
                    {
                        this.props.data.mandatory &&
                        <span style={{ color: "red", fontSize: 17 }}>*</span>
                    }

                    <h6 style={{ fontWeight: "600", fontSize: 17 }}> {this.props.data.label} </h6>
                </div>
                <Form.Control required={this.props.required} type="file" disabled={this.props.editable} />

            </Row>
        )
    }
}
