import React, { Component } from 'react'
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { fetchUserList } from '../action';
import { fetchWorkflowList } from '../../workflows/action';

class WorkflowSearchableDropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDropdown: false, searchValue: "", workflowList: this.props.workflowList
        }
    }

    render() {
        return (
            <div style={{ width: "99%", height: "100%", cursor: "pointer" }}  >

                <Form.Control
                    type="text"
                    placeholder={'Search Workflow'}
                    value={this.props.defaultValue}
                    onClick={() => {
                        this.setState({ showDropdown: !this.state.showDropdown });
                        this.props.onGetList()
                    }} />
                {
                    this.state.showDropdown ?
                        <div style={{ position: "absolute", zIndex: 2, border: "solid", borderColor: "gray", borderWidth: 0.2, backgroundColor: "#fff", height: 250, overflowY: "scroll", paddingInline: 5, marginBlock: 5, paddingBlock: 5 }} >
                            <div >
                                <Form.Control
                                    type="text"
                                    placeholder={'Select Workflow'}
                                    defaultValue={this.props.defaultValue}
                                    autoFocus
                                    onChange={event => { this.props.fetchWorkflowList(this.props.token.access, `&search=${event.target.value}`, 1,) }} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                {
                                    this.props.workflowList && this.props.workflowList.results.length > 0 && this.props.workflowList.results.map((item, i) => {
                                        return (
                                            <p key={i} style={{ padding: 2, paddingInline: 15 }} onClick={() => { this.props.onChange(item); this.setState({ showDropdown: !this.state.showDropdown, searchValue: `${item.label}` }) }}>{`${item.label}`}</p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        : <></>
                }

            </div>
        );
    }
}


const mapPropsToState = store => {
    return {
        token: store.usersReducer.token,
        workflowList: store.workflowReducer.workflowList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchWorkflowList: (accessToken, search, pageNo) => dispatch(fetchWorkflowList(accessToken, search, pageNo)),
    };
};

export default connect(mapPropsToState, mapDispatchToProps)(WorkflowSearchableDropdown);

