import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Row } from 'react-bootstrap';
import SearchableDropdown from '../../common/components/SearchableDropdown';
import { fetchUserList } from '../../common/action';
import { addFormMember } from '../action';
import { fetchFields } from '../action';
import FieldHandler from './fields/FieldHandler';
import { getFieldValue, dynamicFormDataChangeHandler, validateForm } from '../../../lib/utils';


class AddFormMemberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            options: ['OWNER', 'MAINTAINER', 'REPORTER', 'INDIVIDUAL'],
            level: 'OWNER',
            default_value: '',
            hiddenFields: [],
            deafult_value: [],
        }
    }
    componentDidMount = () => {
        this.props.getUserList(this.props.token.access, 1);
        this.props.getFields(this.props.token.access, this.props.formId);
    }

    handleDataChange(fieldId, value) {
        this.setState({ deafult_value: dynamicFormDataChangeHandler(this.state.deafult_value, fieldId, value) });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fields !== this.props.fields) {
            this.props.fields.map((ele, idx) => {
                if (ele.type === "hiddenField") {
                    this.state.hiddenFields.push(ele);
                    let body = {
                        "deafult_value": '',
                        "field": ele.id
                    }
                    this.state.deafult_value.push(body);
                }
            })
            this.state.hiddenFields = this.state.hiddenFields.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
        }
        this.state.deafult_value = this.state.deafult_value.filter((v, i, a) => a.findIndex(v2 => (v2.field === v.field)) === i);
    }

    handleAdd = () => {
        var requestBody = {
            "level": this.state.level,
            "user": this.state.user.id,
            "template": this.props.formId,
            "deafult_value": this.state.deafult_value
        }
        this.props.addFormMember(this.props.token.access, requestBody, this.props.formId)
        this.setState({
            user: {},
            hiddenFields: [],
            deafult_value: [],
        })
        this.props.handleClose();
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Add Member</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <SearchableDropdown
                        userList={this.props.getUserList}
                        defaultValue={this.state.user.name !== undefined ? this.state.user.name : ''}
                        onChange={(data) => this.setState({ user: data })}
                    />
                    <Form.Select className='mt-3' title={'Select Role'} aria-label="Select Role" defaultValue={'OWNER'} onChange={(data) => this.setState({ level: data.target.value })} size={'sm'}
                    >
                        {this.state.options.map((row, index) => {
                            return (
                                <option value={row} key={index}>{row}</option>
                            )
                        })}
                    </Form.Select>
                    <br />
                    {this.state.hiddenFields && this.state.hiddenFields.length > 0 &&
                        this.state.hiddenFields.map((field, index) => {
                            return <div key={index} className='mt-3'> 
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder={"Enter " + field.label}
                                    onChange={event => {
                                        for (const obj of this.state.deafult_value) {
                                            if (obj.field === field.id) {
                                                obj.deafult_value = event.target.value;
                                                break;
                                            }
                                        }
                                    }
                                    }
                                    handleDataChange={(field_id, value) => this.handleDataChange(field_id, value)}
                                />
                            </div>
                        })}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleClose()} variant="warning" size='sm' >CANCEL</Button>
                    <Button onClick={() => { this.handleAdd(); }} type='submit' variant="success" size='sm' >ADD</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.formsReducer.fields,
        token: state.usersReducer.token,
        userList: state.commonReducer.userList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getUserList: (accessToken, pageNo) => dispatch((fetchUserList(accessToken, pageNo))),
        addFormMember: (accessToken, requestBody, formId) => dispatch((addFormMember(accessToken, requestBody, formId)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFormMemberModal);
