import React, { Component } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import { fetchWorkflowDetails } from '../action';

class WorkflowDataView extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    componentDidMount() {
        this.props.getWorkflowData(this.props.token.access, this.props.params.id);

    }
    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Row>
                            <Col md="8">
                                <h3>View</h3>
                                <h6 style={{ fontSize: 21 }}>Project: {this.props.workflowdetails.label}</h6>
                                <p>{this.props.workflowdetails.description}</p>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        workflowdetails: state.workflowReducer.workflowTransitionDetailList,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWorkflowData: (accessToken, Id) => dispatch(fetchWorkflowDetails(accessToken, Id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDataView);
