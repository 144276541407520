import { checkSubscriptionType, fetchApi, redirect } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";
import { FETCH_BILLING_USER, FETCH_PLAN_DETAILS } from "./actionType";
import { Navigate } from "react-router-dom";

export function fetchBillingUser(accessToken, navigate, formLogin = false) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/billings/user/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                if (formLogin) {
                    if (successResponse.active == "") {
                        navigate('/subscription')
                    }
                    else {
                        navigate(checkSubscriptionType(successResponse));
                    };
                };
                dispatch({ type: FETCH_BILLING_USER, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }

}
export function createCompanyProfile(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/billings/billings/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                if (successResponse.data.url !== "") {
                    redirect(successResponse.data.url)
                }
                else {
                    redirect("/dashboard")
                }

                dispatch(setNotification(true, 'Company Created Successfully', 'success'));
                dispatch({ type: LOADING_END })
                dispatch(fetchBillingUser(accessToken));

            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}
export function UpgradePlan(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/billings/create-payment/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                if (successResponse.data.url !== "") {
                    redirect(successResponse.data.url)
                }
                else {
                    redirect("/dashboard")
                }
                dispatch(setNotification(true, 'Plan Updated Successfully', 'success'));
                dispatch({ type: LOADING_END })

            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}
export function updateCompanyProfile(accesstoekn, Id, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/billings/billings/${Id}/`,
            accesstoekn,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Company Updated Successfully', 'success'))
                dispatch({ type: FETCH_BILLING_USER, payload: successResponse })
                dispatch(fetchBillingUser(accesstoekn))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}
export function PlanDetails() {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/billings/get-subscriptions/`,
            null,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_PLAN_DETAILS, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            
        )
    }
}